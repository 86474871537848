import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup,
  Grid2 as Grid,
  Switch,
} from "@mui/material";
import { Button } from "../../../../../../components/Common";
import { getUserOptions } from "../../../../../../Services/userServices";
import { formatDate } from "../../../../../Utility/DatePickerFunctions";
import { MultiSelectDrpDownWithAll } from "../../../../../Utility/MultiSelectDropDown/MultiSelectDrpDownWithAll";
import StandardDatePicker from "../../../../../Utility/StandardDatePicker";
import { ResultsContext } from "../../../ResultsContext";
import {
  questionRiskOption,
  riskOption,
  riskOptionForRedGreenQuestionnaire,
} from "../../../ResultsUtils/filterOptions";

const LeftSideFilters = (props) => {
  const { t } = useTranslation();
  const { resultsState, resultsDispatch, projectDetail } =
    useContext(ResultsContext);
  const {
    resetFilter,
    startDate,
    endDate,
    applyFilter,
    selectCreatedBy,
    flagsOptionsData,
    hideReviewedOnly,
  } = resultsState;

  const [dateErrorMsg, setDateErrorMsg] = useState(false);
  const [createdOptions, setCreatedOptions] = useState([]);

  const {
    projectLanguagesData,
    questionaire_type,
    projectId,
    review_unreview_configuration,
  } = projectDetail;

  useEffect(() => {
    getUserOptions(projectId)
      .then((res) => {
        let option =
          res.data &&
          res.data.map((option) => {
            return {
              value: option.userid,
              label: option.name,
            };
          });
        setCreatedOptions(option);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    setStartDatepickerValue(startDate, endDate);
  }, [startDate, endDate]);

  // const updateLanguage = [
  //   // { label: t("All"), value: "*" },
  //   ...projectLanguagesData,
  // ];
  /**
   * This function work as a setter function for state based
   * @param {string} type
   * @param {anyType} payload
   */
  const dispatch = (type, payload) => {
    resultsDispatch({ type: type, payload: payload });
  };

  const handleDefaultRiskLevelFilter = (type) => {
    switch (type) {
      case "EXPRESS_QUESTIONNAIRE":
      case "PRECISION_RED_GREEN_QUESTIONNAIRE":
        return dispatch("set_risk_levels", ["R", "G", "NC", "NP", "DC"]);
      default:
        return dispatch("set_risk_levels", [
          "HR",
          "LR",
          "AR",
          "PR",
          "NC",
          "DC",
          "NP",
        ]);
    }
  };

  const handleResetFilter = () => {
    let languageIds = [];
    projectLanguagesData.map((languages) => {
      languageIds.push(languages.value);
    });
    dispatch("set_current_page", 0);
    dispatch("set_questions_risk", []);
    handleDefaultRiskLevelFilter(questionaire_type);
    dispatch("start_date", new Date("2020-01-01"));
    dispatch("end_date", new Date());
    dispatch("reset_filter", !resetFilter);
    dispatch("set_filter_language", languageIds);
    dispatch("select_created_by", null);
  };
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 2);

  const setStartDatepickerValue = (startDate, endDate) => {
    if (startDate.setHours(0, 0, 0, 0) > endDate.setHours(0, 0, 0, 0)) {
      setDateErrorMsg(true);
    } else {
      setDateErrorMsg(false);
    }
  };

  const handleCreatedBy = (option) => {
    dispatch("select_created_by", option);
  };

  const questionOption = (type) => {
    switch (type) {
      case "PRECISION_RED_GREEN_QUESTIONNAIRE":
      case "EXPRESS_QUESTIONNAIRE":
        return riskOptionForRedGreenQuestionnaire(flagsOptionsData);
      default:
        return riskOption(flagsOptionsData);
    }
  };

  const handleReviewed = (event) => {
    dispatch("hide_reviewed_only", event.target.checked);
  };

  return (
    <Card className="card">
      <CardHeader title={t("Filters")}></CardHeader>
      <CardContent>
        <div className="result_filter_section_new">
          <div className="d-flex">
            <MultiSelectDrpDownWithAll
              options={questionOption(questionaire_type)}
              resetFilter={resetFilter}
              defaultSelectAll={true}
              placeholderButtonLabel={t("Overall Risk")}
              setSelectedOption={(selectedOption) => {
                dispatch("set_risk_levels", selectedOption);
              }}
            />

            <MultiSelectDrpDownWithAll
              options={questionRiskOption(flagsOptionsData)}
              resetFilter={resetFilter}
              defaultSelectAll={false}
              placeholderButtonLabel={t("Question")}
              setSelectedOption={(selectedOption) => {
                dispatch("set_questions_risk", selectedOption);
              }}
            />

            <MultiSelectDrpDownWithAll
              options={projectLanguagesData}
              resetFilter={resetFilter}
              defaultSelectAll={true}
              placeholderButtonLabel={t("Language")}
              setSelectedOption={(selectedOption) => {
                dispatch("set_filter_language", selectedOption);
              }}
            />

            <Grid className="select-style flex12">
              <Select
                name="created_select"
                classNamePrefix="select"
                className="created_by_class"
                value={selectCreatedBy}
                options={createdOptions}
                isClearable={true}
                placeholder={t("Created By")}
                onChange={handleCreatedBy}
              />
            </Grid>

            <Grid className="flex20">
              <StandardDatePicker
                dateFormat={formatDate()}
                placeholderText="Start Date"
                autoComplete="off"
                className="form-control w-100 "
                name="reportStartDate"
                onChange={(selectedDate) => {
                  dispatch("start_date", selectedDate);
                }}
                label={t("From")}
                selected={startDate}
                maxDate={futureDate}
                isCustomInput={true}
                direction={"prev"}
              />
            </Grid>

            <Grid className="flex20">
              <StandardDatePicker
                dateFormat={formatDate()}
                placeholderText="Start Date"
                autoComplete="off"
                className="form-control w-100 "
                name="reportStartDate"
                onChange={(selectedDate) => {
                  dispatch("end_date", selectedDate);
                }}
                selected={endDate}
                label={t("To")}
                maxDate={futureDate}
                isCustomInput={true}
                direction={"prev"}
              />
            </Grid>
            <div className="flex9 result-apply-button">
              <Button
                variant="primary"
                onClick={() => {
                  dispatch("set_current_page", 0);
                  dispatch("set_apply_filter", !applyFilter);
                }}
                disabled={dateErrorMsg}
                className="btn-sm-text result_applybtn "
              >
                {t("Apply")}
              </Button>

              <div
                onClick={handleResetFilter}
                className="result_resetFilter-btn"
              >
                {t("Reset filters")}
              </div>
            </div>
          </div>
          {review_unreview_configuration && (
            <div className="d-flex">
              <FormGroup>
                <FormControlLabel
                  label={t("HIDE_REVIEWED_IRN")}
                  labelPlacement="start"
                  className="hide_reviewed_irn_label"
                  control={
                    <Switch
                      checked={hideReviewedOnly}
                      onChange={handleReviewed}
                    />
                  }
                />
              </FormGroup>
            </div>
          )}
        </div>

        {dateErrorMsg ? (
          <span className="input-feedback">
            End Date must be greater than Start Date
          </span>
        ) : (
          ""
        )}
      </CardContent>
    </Card>
  );
};

export default LeftSideFilters;
