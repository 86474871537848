export const isTenantReadEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.read";
  });

  return permission.length > 0;
};
export const isUserAuditlogReadEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.users.activity.read";
  });

  return permission.length > 0;
};

export const isTenantCreateEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.create";
  });
  return permission.length > 0;
};

export const isTenantEditEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.update";
  });
  return permission.length > 0;
};

export const isUserReadEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.users.read";
  });
  return permission.length > 0;
};

export const isUserCreateEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.users.create";
  });
  return permission.length > 0;
};

export const isUserDeleteEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.users.delete";
  });
  return permission.length > 0;
};

export const isUserUpdateEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.users.update";
  });
  return permission.length > 0;
};

export const isRoleReadEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.roles.permissions.read";
  });
  return permission.length > 0;
};

export const isRoleCreateEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.roles.permissions.create";
  });
  return permission.length > 0;
};

export const isPermissionReadEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.roles.permissions.read";
  });
  return permission.length > 0;
};

export const isPermissionUpdateEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.roles.permissions.update";
  });
  return permission.length > 0;
};

export const isLookupReadEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.project.lookupvalues.read";
  });
  return permission.length > 0;
};

export const isLookupCreateEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.project.lookupvalues.create";
  });
  return permission.length > 0;
};

export const isLookupUpdateEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.project.lookupvalues.update";
  });
  return permission.length > 0;
};

export const isACGRPermissionsTabEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.roles.permissions.internal.read";
  });
  return permission.length > 0;
};

export const isCallCenterReadEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return !!(
      permissionObj.permission == "callcenter.read" ||
      permissionObj.permission == "tenant.callexec.detail.read"
    );
  });
  return permission.length > 0;
};

export const isAddCallCenterReadEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "callcenter.create";
  });
  return permission.length > 0;
};

export const isClientReadEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.client.application.read";
  });
  return permission.length > 0;
};

export const isClientCreateEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.client.application.create";
  });
  return permission.length > 0;
};

// Secrets Enables
export const isSecretsReadEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "secrets.read";
  });
  return permission.length > 0;
};

export const isDocumentReadEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.document.read";
  });
  return permission.length > 0;
};

export const isDocumentCreateEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.document.create";
  });
  return permission.length > 0;
};

export const isDocumentDeleteEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.document.delete";
  });
  return permission.length > 0;
};

export const getAdminRoute = (permission) => {
  let routePath = "/main/admin/tenant";

  if (isTenantReadEnabled(permission)) {
    return routePath;
  } else if (isUserReadEnabled(permission)) {
    routePath = "/main/admin/users";
  } else if (isRoleReadEnabled(permission)) {
    routePath = "/main/admin/roles";
  } else if (isPermissionReadEnabled(permission)) {
    routePath = "/main/admin/permissions";
  } else if (isLookupReadEnabled(permission)) {
    routePath = "/main/admin/lookups";
  } else if (isCallCenterReadEnabled(permission)) {
    routePath = "/main/admin/callcenters";
  } else if (isSecretsReadEnabled(permission)) {
    routePath = "/main/admin/Secrets";
  } else {
    routePath = "";
  }
  return routePath;
};

export const getAdminInitialNavBar = (navbar, permission) => {
  let tempNavBar = "Tenant";

  if (navbar == "Tenant") {
    if (isTenantReadEnabled(permission)) {
      tempNavBar = "Tenants";
    } else if (isUserReadEnabled(permission)) {
      tempNavBar = "Users";
    } else if (isRoleReadEnabled(permission)) {
      tempNavBar = "Roles";
    } else if (isPermissionReadEnabled(permission)) {
      tempNavBar = "Permissions";
    } else if (isLookupReadEnabled(permission)) {
      tempNavBar = "Lookups";
    } else if (isCallCenterReadEnabled(permission)) {
      tempNavBar = "CallCenters";
    } else if (isSecretsReadEnabled(permission)) {
      tempNavBar = "Secrets";
    } else {
      tempNavBar = "";
    }
  }

  return tempNavBar;
};

export const isOrderDetailsEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.users.read";
  });
  return permission.length > 0;
};

export const isOrderCreateEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.order.create";
  });
  return permission.length > 0;
};

export const isOrderReadEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.order.read";
  });
  return permission.length > 0;
};
export const isResultConfigurationReadEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.result.config.read";
  });
  return permission.length > 0;
};

export const isResultConfigurationCreateEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.result.config.create";
  });
  return permission.length > 0;
};
export const isResultConfigurationDeleteEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.result.config.delete";
  });
  return permission.length > 0;
};
export const isResultConfigurationUpdateEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.result.config.update";
  });
  return permission.length > 0;
};

export const isOrderUpdateEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.order.update";
  });
  return permission.length > 0;
};

export const isOrderDeleteEnabled = (permissionList) => {
  const permission = permissionList.filter(function (permissionObj) {
    return permissionObj.permission == "tenant.order.delete";
  });
  return permission.length > 0;
};

export const stringExistsInArray = (array) => {
  return array.includes("tenant.project.communication.update");
};

export const isPermissionExistsInList = (list, permissions) => {
  return list.includes(permissions);
};

export const isAnalyticsReportPermission = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const roleName = userInfo?.rolePermission?.roleName?.toLowerCase();
  const validRoles = ["clearspeed admin"];
  return validRoles.includes(roleName);
};
