import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Button, ModalPopUp } from "../../../components/Common";
import { SimpleModal } from "../../Utility/ModalPopUp";

const BusinessLineOptionalConfirmation = ({
  isOpen = false,
  handleClose = {},
  handleSubmit = {},
}) => {
  const { t } = useTranslation();

  return (
    <ModalPopUp onHide={handleClose} show={isOpen}>
      <div className="d-flex text-bold mb-5 modal-box-text">
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="save-change-warning-icon"
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t("BUSINESS_LINE_CONFIRMATION_MESSAGE"),
          }}
        />
      </div>
      <div className="float-right">
        <Button
          variant="secondary"
          type="button"
          className="rounded-0 cancel-btn text-uppercase mr-4"
          onClick={handleClose}
        >
          {t("BUSINESS_LINE_NEED_DATA")}
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmit}>
          <span className="text-uppercase">
            {t("BUSINESS_LINE_REMOVE_DATA")}
          </span>
        </Button>
      </div>
    </ModalPopUp>
  );
};

export default BusinessLineOptionalConfirmation;
