import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { Alert as AlertDismissible } from "../../components/Common";
import * as userPermission from "../Utility/userPermission.js";
import AuditLog from "./AuditLog";
import BackendData from "./BackendData";
import IncompleteCalls from "./IncompleteCalls";
import ReportsCallrecords from "./ReportsCallrecords";
import ReportsResults from "./ReportsResults";
import UsersLog from "./UsersLog";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMsg: "",
      alertStatus: false,
      variant: "",
      isinternal: [],
      noPermissionmsg: "",
      navBar: "",
    };
    this.handleTabClick = this.handleTabClick.bind(this);
  }

  componentDidMount() {
    if (!this.props.loggedIn) return;
    let userinfo = JSON.parse(localStorage.getItem("userInfo"));
    let internalPermissions = userinfo.rolePermission.permissions;
    this.setState({ isinternal: internalPermissions, noPermissionmsg: "" });
  }
  handleTabClick = (navbar) => {
    console.log("navbarvalue", navbar);
    this.setState(
      {
        navBar: navbar,
      },
      () => {
        console.log("Set state", this.state.navBar);
      }
    );
  };

  handleAlertClose = () => {
    this.setState((prevState) => {
      return {
        alertStatus: !prevState.alertStatus,
      };
    });
  };
  render() {
    const { t } = this.props;
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let isAcgrUser = userInfo && userInfo.tenant?.isSystemTenant;
    const url = this.props.match.url;
    // if (!this.props.loggedIn || !isAcgrUser) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <div className="page-breadcrumb">
          {t("Reports")}{" "}
          <ArrowForwardIosIcon
            sx={{
              fontSize: "15px",
              color: "#3e3f42",
              fontWeight: 600,
              marginRight: "5px",
            }}
          />
          <span
            style={{
              color: "#1665d8",
            }}
          >
            {t(this.state.navBar)}
          </span>
        </div>

        <nav as="ul" className="project-info-tab nav">
          <NavLink
            className=""
            exact
            activeClassName="active"
            to={url}
            onClick={() => {
              this.handleTabClick("CallRecords");
            }}
          >
            {t("Call Records")}
          </NavLink>
          <NavLink
            exact
            activeClassName="active"
            className=""
            to={`${url}/results`}
            onClick={() => {
              this.handleTabClick("Results");
            }}
          >
            {t("Results")}
          </NavLink>
          <NavLink
            exact
            activeClassName="active"
            className=""
            to={`${url}/incompletecalls`}
            onClick={() => {
              this.handleTabClick("Incomplete Calls");
            }}
          >
            {t("Incomplete Calls")}
          </NavLink>
          {userPermission.isUserAuditlogReadEnabled(this.state.isinternal) ? (
            <NavLink
              exact
              activeClassName="active"
              className=""
              to={`${url}/auditlog`}
              onClick={() => {
                this.handleTabClick("Audit Log");
              }}
            >
              {t("Audit Log")}
            </NavLink>
          ) : (
            ""
          )}
          <NavLink
            exact
            activeClassName="active"
            className=""
            to={`${url}/backenddata`}
            onClick={() => {
              this.handleTabClick("Backend Data");
            }}
          >
            {t("Backend Data")}
          </NavLink>
          <NavLink
            exact
            activeClassName="active"
            className=""
            to={`${url}/users`}
            onClick={() => {
              this.handleTabClick("Users");
            }}
          >
            {t("Users")}
          </NavLink>
        </nav>
        <AlertDismissible
          msg={this.state.alertMsg}
          variantType={this.state.variant}
          show={this.state.alertStatus}
          close={this.handleAlertClose}
        ></AlertDismissible>
        <Switch>
          <Route
            exact
            path={this.props.match.path}
            render={(props) => (
              <ReportsCallrecords
                {...props}
                statedata={this.state}
                action={this.handleTabClick}
              />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/results`}
            render={(props) => (
              <ReportsResults
                {...props}
                statedata={this.state}
                action={this.handleTabClick}
              />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/incompletecalls`}
            render={(props) => (
              <IncompleteCalls
                {...props}
                statedata={this.state}
                action={this.handleTabClick}
              />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/auditlog`}
            render={(props) => (
              <AuditLog
                {...props}
                statedata={this.state}
                action={this.handleTabClick}
              />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/backenddata`}
            render={(props) => (
              <BackendData
                {...props}
                statedata={this.state}
                action={this.handleTabClick}
              />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/users`}
            render={(props) => (
              <UsersLog
                {...props}
                statedata={this.state}
                action={this.handleTabClick}
              />
            )}
          />
          <Redirect from="/main" to="/main/dashboard" />
        </Switch>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loggedIn: state.authentication.loggedIn,
  };
};
export default withTranslation()(connect(mapStateToProps)(withRouter(Reports)));
