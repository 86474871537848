import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "formik";
import { Grid2 as Grid } from "@mui/material";
import viewCloseIcon from "../../../assets/icon/view-close.png";
import viewIcon from "../../../assets/icon/view-icon.png";
import { formatDate } from "../../Utility/DatePickerFunctions";
import StandardDatePicker from "../../Utility/StandardDatePicker";
import { SelectField } from "../Helper";
import DropDownOptions from "./DropDownOptions";

const ProjectInitialDetail = (props) => {
  const { t } = useTranslation();

  const { formik, state } = props;

  const { errors, values, handleChange, handleBlur, touched, setFieldValue } =
    formik;

  const {
    languageOption,
    locationOption,
    projectTypeOption,
    riskExternalOption,
    statusOption,
    stageOption,
    questionaireTypeOption,
    templateTypeOption,
    currencyOptions,
  } = DropDownOptions(state, t);

  // console.log("templateTypeOption", templateTypeOption);
  let internalFieldDisabled =
    state.isinternal.indexOf("tenant.project.detail.internal.update") >= 0
      ? true
      : false;

  const [togglePassword, setTogglePassword] = useState(false);

  return (
    <Grid container spacing={3}>
      <Grid size={6}>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid size={12}>
            <div className="form-group">
              <label className="required" htmlFor="name">
                {t("Name")}
              </label>
              <Field
                name="name"
                type="text"
                id="name"
                autoComplete="off"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              {errors.name && touched.name && (
                <div className="input-feedback">{errors.name}</div>
              )}
            </div>
          </Grid>
          <Grid size={6}>
            <div className="form-group">
              <label htmlFor="languageId">{t("language")}</label>
              <Field
                name={"languageId"}
                id="languageId"
                onBlur={handleBlur}
                component={SelectField}
                options={languageOption}
                values={values.languageId}
              />
            </div>
          </Grid>
          <Grid size={6}>
            <div className="form-group">
              <label htmlFor="locationId">{t("Location")}</label>
              <Field
                name={"locationId"}
                onBlur={handleBlur}
                id="locationId"
                component={SelectField}
                options={locationOption}
                values={values.locationId}
              />
            </div>
          </Grid>
          <Grid size={6}>
            <div className="form-group">
              <label htmlFor="typeId">{t("Project Type")}</label>
              <Field
                name={"typeId"}
                id="typeId"
                onBlur={handleBlur}
                component={SelectField}
                options={projectTypeOption}
                values={values.typeId}
              />
            </div>
          </Grid>
          <Grid size={6}>
            <div className="form-group">
              <label htmlFor="externalRiskObjectiveId">
                {t("Risk objective")}
              </label>
              <Field
                name={"externalRiskObjectiveId"}
                id="externalRiskObjectiveId"
                onBlur={handleBlur}
                component={SelectField}
                options={riskExternalOption}
                values={values.externalRiskObjectiveId}
              />
            </div>
          </Grid>
          <Grid size={12}>
            <div className="form-group">
              <label htmlFor="description">{t("Description")}</label>
              <Field
                name="description"
                type="text"
                id="description"
                component="textarea"
                rows="5"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={6}>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid size={6}>
            <div className="form-group">
              <label htmlFor="statusId">{t("Status")}</label>
              <Field
                name={"statusId"}
                className="form-control"
                id="statusId"
                onBlur={handleBlur}
                component={SelectField}
                options={statusOption}
                values={values.statusId}
                isDisabled={!internalFieldDisabled}
              />
            </div>
          </Grid>
          <Grid size={6}>
            <div className="form-group">
              <label htmlFor="stageId">{t("Stage")}</label>
              <Field
                name={"stageId"}
                id="stageId"
                onBlur={handleBlur}
                component={SelectField}
                options={stageOption}
                values={values.stageId}
                isDisabled={!internalFieldDisabled}
              />
            </div>
          </Grid>
          <Grid size={6}>
            <div className="form-group">
              <label htmlFor="estStartDate">{t("Project Start Date")}</label>
              <StandardDatePicker
                dateFormat={formatDate()}
                placeholderText="Start Date"
                id="estStartDate"
                autoComplete="off"
                className="form-control w-100 "
                name="estStartDate"
                onChange={(selectedDate) => {
                  setFieldValue("estStartDate", selectedDate);
                }}
                selected={
                  values.estStartDate
                    ? new Date(values.estStartDate)
                    : values.estStartDate
                }
              />
            </div>
          </Grid>
          <Grid size={6}>
            <div className="form-group">
              <label htmlFor="estEndDate">{t("Project End Date")}</label>
              <StandardDatePicker
                dateFormat={formatDate()}
                placeholderText="Start Date"
                id="estEndDate"
                autoComplete="off"
                className="form-control w-100 "
                name="estEndDate"
                onChange={(selectedDate) => {
                  setFieldValue("estEndDate", selectedDate);
                }}
                selected={
                  values.estEndDate
                    ? new Date(values.estEndDate)
                    : values.estEndDate
                }
              />
            </div>
          </Grid>
          <Grid size={6}>
            <div className="form-group">
              <label htmlFor="estParticipant">{t("Number Of Calls")}</label>
              <Field
                name="estParticipant"
                type="text"
                id="estParticipant"
                autoComplete="off"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.estParticipant}
              />
            </div>
          </Grid>

          <Grid size={6}>
            <div className="form-group">
              <label htmlFor="resourcesPassword">
                {t("File Download Password")}
              </label>
              <Field
                name="resourcesPassword"
                autoComplete="new-password"
                id="resourcesPassword"
                type={togglePassword ? "text" : "password"}
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.resourcesPassword}
              />

              <span
                className="eye-icon"
                onClick={() => {
                  setTogglePassword((togglePassword) => !togglePassword);
                }}
              >
                {togglePassword ? (
                  <img src={viewCloseIcon} />
                ) : (
                  <img src={viewIcon} />
                )}
              </span>
            </div>
          </Grid>

          <Grid size={6}>
            <div className="form-group">
              <label htmlFor="questionnaireType">
                {t("Questionnaire Type")}
              </label>

              <Field
                name={"questionaire_type"}
                id="questionnaireType"
                onBlur={handleBlur}
                component={SelectField}
                options={questionaireTypeOption}
                values={values.questionaire_type}
              />
            </div>
          </Grid>

          <Grid size={6}>
            <div className="form-group">
              <label htmlFor="project_currency_id">{t("Currency")}</label>
              <Field
                name={"currencyId"}
                id="project_currency_id"
                onBlur={handleBlur}
                component={SelectField}
                options={currencyOptions}
                values={values.currencyId}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectInitialDetail;
