import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Card,
  CardContent,
  CardHeader,
  Grid2 as Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Button } from "../../../../../../components/Common";
import { releaseProjectResults } from "../../../../../../Services/executionService";
import { CustomTooltip } from "../../../../../Utility/CustomTooltip";
import { ResultsContext } from "../../../ResultsContext";
import { displayTImeZoneOption } from "../../../ResultsUtils/filterOptions";

const RightSideFilters = (props) => {
  const { fetchProjectResult } = props;

  const { t } = useTranslation();

  const { resultsState, resultsDispatch, permissionList } =
    useContext(ResultsContext);

  const { onHoldCheck, searchingText, selectedResultList, displayTimeZone } =
    resultsState;

  /**
   * This function work as a setter function for state based
   * @param {string} type
   * @param {anyType} payload
   */
  const dispatch = (type, payload) => {
    resultsDispatch({ type: type, payload: payload });
  };

  /**
   * This function will fetch all hold result
   * @param {object} event
   */
  const showHoldResult = (event) => {
    dispatch("set_current_page", 0);
    dispatch("set_on_hold_check", event.target.checked);
  };

  /**
   * This function will fetch result based upon search text
   * @param {object} event
   */
  const searchResultsByText = (event) => {
    dispatch("set_current_page", 0);
    dispatch("set_searching_text", event.target.value);
  };
  /**
   * This function will set our time zone for result
   * @param {*} option
   */
  const onChangeTimeZone = (option) => {
    let filterOffset = displayTImeZoneOption.filter((optionList) => {
      return optionList.value === option.value;
    });
    if (filterOffset.length > 0) {
      dispatch("set_display_time_zone_offset", filterOffset[0].offset);
    }
    dispatch("set_display_timezone", option);
  };

  /**
   * This function will release selected result
   */
  const releaseResult = () => {
    let releaseResultList = selectedResultList.map((result) => {
      result.released = true;
      result.deleted = false;
      return result;
    });
    releaseProjectResults(releaseResultList)
      .then(() => {
        dispatch("is_select_all", false);
        dispatch("selected_result_list", []);
        fetchProjectResult();
      })
      .catch((err) => {
        console.log("err", err);
        let errMsg = err.response?.data.message;
        showError(errMsg, "danger", true);
      });
  };

  /**
   * This function will delete selected result
   */
  const deleteResult = () => {
    let deletedResultList = selectedResultList.map((result) => {
      result.released = false;
      result.deleted = true;
      return result;
    });
    releaseProjectResults(deletedResultList)
      .then(() => {
        dispatch("is_select_all", false);
        dispatch("selected_result_list", []);
        fetchProjectResult();
      })
      .catch((err) => {
        console.log("err", err);
        let errMsg = err.response?.data.message;
        showError(errMsg, "danger", true);
      });
  };

  return (
    <Card>
      <CardHeader
        title={t("Search")}
        action={
          permissionList.includes("tenant.project.resultshold.internal.read") &&
          permissionList.includes(
            "tenant.project.resultshold.internal.update"
          ) ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={onHoldCheck}
                  onChange={(event) => showHoldResult(event)}
                />
              }
              className="result-hold-label"
              label={t("On Hold")}
            />
          ) : null
        }
      />

      <CardContent>
        <Grid container>
          <Grid
            item
            size={
              permissionList.indexOf(
                "tenant.project.resultshold.internal.read"
              ) >= 0 &&
              permissionList.indexOf(
                "tenant.project.resultshold.internal.update"
              ) >= 0
                ? 8
                : 11
            }
            className="position-relative resultsearchindex pr-2"
          >
            <input
              type="text"
              placeholder={t("Search IRN and Name")}
              className="form-control"
              value={searchingText}
              onChange={searchResultsByText}
            />

            <Select
              name="location"
              className="select-style mt-2"
              classNamePrefix="select"
              options={displayTImeZoneOption}
              isClearable={true}
              value={displayTimeZone}
              placeholder={t("Select Timezone")}
              onChange={onChangeTimeZone}
            />
          </Grid>

          {permissionList.indexOf("tenant.project.resultshold.internal.read") >=
            0 &&
          permissionList.indexOf(
            "tenant.project.resultshold.internal.update"
          ) >= 0 ? (
            <Grid size={4} className="mt-2">
              <CustomTooltip title="Release" placement="left">
                <Button
                  onClick={releaseResult}
                  className="mui-button success-button"
                  disabled={!selectedResultList.length > 0}
                >
                  {t("R")}
                </Button>
              </CustomTooltip>
              <CustomTooltip title="Delete" placement="left">
                <Button
                  onClick={deleteResult}
                  className="mui-button delete-button"
                  disabled={!selectedResultList.length > 0}
                >
                  {t("D")}
                </Button>
              </CustomTooltip>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RightSideFilters;
