import { Grid2 as Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Field } from "formik";
import { SelectField } from "../Helper";
import ISWOptions from "./ISWOptions";

const ISW = (props) => {
  const { t } = useTranslation();

  const { formik, state } = props;

  const { handleBlur, values } = formik;

  const { irnVerificationMethodsOption } = ISWOptions(state);

  return (
    <Grid size={12}>
      <div className="mb-2 mt-2 additional_configuration_accordion">
        <Accordion allowZeroExpanded="true" allowMultipleExpanded="true">
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="row-header">
                  <div>
                    <label className="row-header-title">
                      {t("ISW Options")}
                    </label>
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Grid container className="internal_view_old pt-4 pb-4">
                <Grid size={3}>
                  <div className="form-group">
                    <label htmlFor="irnVerificationMethodId">
                      {t("IRN verification method")}
                    </label>
                    <Field
                      name={"irnVerificationMethodId"}
                      id="irnVerificationMethodId"
                      onBlur={handleBlur}
                      component={SelectField}
                      options={irnVerificationMethodsOption}
                      values={values.irnVerificationMethodId}
                    />
                  </div>
                </Grid>
              </Grid>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </Grid>
  );
};

export default ISW;
