import React from "react";
import { useTranslation } from "react-i18next";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Field, FieldArray, ErrorMessage } from "formik";
import { Grid2 as Grid } from "@mui/material";
import { TextError } from "../Helper";
import ConfigurationOptions from "./ConfigurationOptions";

const AddAdditionConfiguration = (props) => {
  const { t } = useTranslation();
  const { formik, state } = props;
  const { values, setFieldValue } = formik;
  const { languageTypeOption } = ConfigurationOptions(state);

  const getMainFieldOptions = (rows, index) => {
    const selectedOptions = rows.filter((row, rowIndex) => rowIndex !== index);
    const filteredOptions = state.keys.filter(
      (mainFieldOption) =>
        !selectedOptions.find(
          (selectedOption) => mainFieldOption.value === selectedOption.key.value
        )
    );

    return filteredOptions;
  };

  const handleConfigurationKey = (slectedKey, index) => {
    const defaultLanguage = languageTypeOption.filter(
      (option) => option.label === "English"
    );

    if (slectedKey.__isNew__) {
      delete slectedKey.__isNew__;
    }

    if (slectedKey.value === "PROJECT_LANGUAGES") {
      setFieldValue(
        `clientAPIUrl1.additional_fields.${index}.value`,
        defaultLanguage
      );
    }
    setFieldValue(`clientAPIUrl1.additional_fields.${index}.key`, slectedKey);
  };

  const handleIsDisabled = (row) => {
    return !!(
      row.key.value === "PROJECT_LANGUAGES" ||
      row.key.value === "SMS_COUNTRY_CODE" ||
      row.key.value === "SMS_TEST_PHONE_NUMBER"
    );
  };

  return (
    <Grid size={12}>
      <div className="mb-2 mt-2 additional_configuration_accordion">
        <Accordion allowZeroExpanded="true" allowMultipleExpanded="true">
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="row-header">
                  <div>
                    <label className="row-header-title">
                      {t("Add Additional Configuration Fields")}
                    </label>
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Grid container>
                <Grid size={12}>
                  <FieldArray
                    name="clientAPIUrl1.additional_fields"
                    render={({ push, remove }) => {
                      return (
                        <>
                          <div className="key_value_repeater">
                            {values.clientAPIUrl1.additional_fields.length >
                              0 &&
                              values.clientAPIUrl1.additional_fields.map(
                                (row, index) => {
                                  const mainFieldOptions = getMainFieldOptions(
                                    values.clientAPIUrl1.additional_fields,
                                    index
                                  );

                                  return (
                                    <>
                                      <div className="row" key={index}>
                                        <div className="col form-group inline_label_fields">
                                          <label
                                            className="required"
                                            htmlFor={`clientAPIUrl1.additional_fields.${index}.key`}
                                          >
                                            {t("Key")}
                                          </label>
                                          <CreatableSelect
                                            id={`clientAPIUrl1.additional_fields.${index}.key`}
                                            className="select-style"
                                            classNamePrefix="select"
                                            closeMenuOnSelect
                                            isDisabled={handleIsDisabled(row)}
                                            options={mainFieldOptions}
                                            value={row.key}
                                            onChange={(value) =>
                                              handleConfigurationKey(
                                                value,
                                                index
                                              )
                                            }
                                          />
                                          <ErrorMessage
                                            name={`clientAPIUrl1.additional_fields.${index}.key`}
                                            component={TextError}
                                          />
                                        </div>
                                        <div className="col form-group inline_label_fields">
                                          <label
                                            htmlFor={`clientAPIUrl1.additional_fields.${index}.value`}
                                          >
                                            {t("Value")}
                                          </label>

                                          {row.key.value !=
                                          "PROJECT_LANGUAGES" ? (
                                            <Field
                                              name={`clientAPIUrl1.additional_fields.${index}.value`}
                                              id={`clientAPIUrl1.additional_fields.${index}.value`}
                                              className={"form-control"}
                                              type="text"
                                              component="textarea"
                                              value={row.value}
                                              onChange={(event) => {
                                                let str = event.target.value;
                                                let newStr = str.replace(
                                                  /\s+/g,
                                                  ""
                                                );
                                                setFieldValue(
                                                  `clientAPIUrl1.additional_fields.${index}.value`,
                                                  newStr
                                                );
                                              }}
                                            />
                                          ) : (
                                            <Select
                                              isMulti
                                              name={`clientAPIUrl1.additional_fields.${index}.value`}
                                              id={`clientAPIUrl1.additional_fields.${index}.value`}
                                              options={languageTypeOption}
                                              classNamePrefix="select"
                                              value={row.value}
                                              onChange={(value) => {
                                                setFieldValue(
                                                  `clientAPIUrl1.additional_fields.${index}.value`,
                                                  value
                                                );
                                              }}
                                            />
                                          )}
                                        </div>

                                        <div className="delete_icon_key_value_repeater">
                                          <button
                                            type="button"
                                            className="btn btn-secondary btn-sm remove_repeater"
                                            onClick={() => remove(index)}
                                          >
                                            <FontAwesomeIcon
                                              icon={faTrashAlt}
                                              className="trashicon"
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            <button
                              type="button"
                              className="btn btn-primary add_additional_fields_btn"
                              onClick={() =>
                                push({
                                  key: "",
                                  value: "",
                                })
                              }
                            >
                              {t("Add Additional Fields")}
                            </button>
                          </div>
                        </>
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </Grid>
  );
};

export default AddAdditionConfiguration;
