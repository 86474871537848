import React, { Component } from "react";
import i18next from "i18next";
import { useTranslation, withTranslation } from "react-i18next";
import Select from "react-select";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { Card, Grid2 as Grid, CardContent } from "@mui/material";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../../components/Common";
import { getlanguageIdFromcode } from "../../../Helpers/index";
import i18n from "../../../locale/i18n";
import { isEmailTemplateConfigured } from "../../../Services/participantsService";
import {
  getAttribute,
  getProjectDetail,
} from "../../../Services/projectServices";
import CustomPrompt from "../../Utility/CustomPrompt/CustomPrompt";
import "./ProjectParticipant.css";

class AddEditParticipant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectDetail: [],
      language: i18n.language,
      countryCodeForParticipant: [],
      phone: "",
      multiLingualOption: [],
      isTestParticipant: false,
      disabledTestParticipant: false,
      stages: [],
      projectLoader: true,
      showAlert: false,
      alertMsg: "",
      alertVariant: "success",
      configuredMsg: "",
      validationIRN: null,
    };
  }

  componentDidMount() {
    const self = this;
    this.loadAttribute();
    this.checkEmailTemplateConfiguration();
    i18next.on("languageChanged", function (lng) {
      self.setState(
        {
          language: lng,
        },
        () => {
          self.loadAttribute();
        }
      );
    });
  }

  checkEmailTemplateConfiguration = () => {
    const projectId = parseInt(this.props.id);
    console.log("checkEmailTemplateConfiguration", projectId);
    isEmailTemplateConfigured(projectId)
      .then((response) => {
        console.log("response1", response);
        this.setState({
          configuredMsg: response.data,
          validationIRN:
            response.data.accessCodeBehaviourIsSameAsIRN === "true"
              ? true
              : false,
        });
      })
      .catch((error) => {
        console.log("error1", error);
        this.setState({ configuredMsg: error.data });
      });
  };

  loadAttribute = () => {
    let languagecode = i18n.language;
    let languageId = getlanguageIdFromcode(languagecode);
    getAttribute(languageId)
      .then((res) => {
        this.filterAttribute(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * This function will set select box option for department and location field in local state
   * @param {object} res
   */
  filterAttribute = (res) => {
    let stages = res.data.attributes.filter((item) => {
      return item.attributeType === "STAGE";
    });

    this.setState(
      {
        stages: stages,
      },
      () => this.getProjectDeatil()
    );
  };

  getProjectDeatil = () => {
    const id = parseInt(this.props.id);
    getProjectDetail(id)
      .then((res) => {
        let values = this.props.participant;
        let phoneNumberValue = "";
        if (values !== undefined && values.phoneNumber) {
          phoneNumberValue = values.phoneNumber.replace(/\+/g, "");
        }
        const allStage = this.state.stages.filter(
          (stage) => stage.attributeId === res.data.stageId
        );
        const stage = allStage.length > 0 ? allStage[0].description : "";
        this.setState({
          projectDetail: res.data,
          phone: phoneNumberValue,
          multiLingualOption: res.data.projectLanguagesData,
          countryCodeForParticipant:
            res.data.countryCodeForParticipant !== null
              ? res.data.countryCodeForParticipant
              : [],
          projectLoader: false,
          isTestParticipant: stage === "Testing" ? true : false,
          disabledTestParticipant: stage === "Testing" ? true : false,
        });
      })
      .catch((error) => {
        this.setState({
          alertMsg: error.response?.data?.message,
          alertVariant: "danger",
          showAlert: true,
        });
      });
  };

  /**
   * This function will handle phone number
   * @param {number} phone
   * @param {function} setFieldValue
   */

  handleChangePhoneNumber = (phone, setFieldValue) => {
    this.setState({
      phone: phone,
    });
    const conct = "+" + phone;
    setFieldValue("phoneNumber", conct);
  };

  handleFirstName = (configjson, t) => {
    if (configjson != "") {
      if (configjson.data.participant_area.first_name != "") {
        return configjson.data.participant_area.first_name;
      } else {
        return t("First Name");
      }
    } else {
      return t("First Name");
    }
  };
  handleLastName = (configjson, t) => {
    if (configjson != "") {
      if (configjson.data.participant_area.last_name != "") {
        return configjson.data.participant_area.last_name;
      } else {
        return t("Last Name");
      }
    } else {
      return t("Last Name");
    }
  };
  render() {
    const noWhiteSpaceRegExp = /^\S*$/;
    let values = this.props.participant;
    let initialValues = {
      first_name: values !== undefined ? values.first_name : "",
      last_name: values !== undefined ? values.last_name : "",
      email: values !== undefined ? values.email : "",
      phoneNumber: values !== undefined ? values.phoneNumber : "",
      timezone_id: values !== undefined ? values.timezone_id : "",
      participant_language_id:
        values != undefined
          ? values.participant_language_id
          : this.state.multiLingualOption.length == 1
          ? this.state.multiLingualOption[0].value
          : "",
      interviewRefNum: values !== undefined ? values.interviewRefNum : "",
      status: values !== undefined ? values.status : "",
      is_test_participant:
        values !== undefined
          ? values.is_test_participant
          : this.state.isTestParticipant,
      additional_description: values ? values.additional_description : "",
    };

    let conJson = this.state.projectDetail.configurationJSON;
    let configjson = "";
    if (conJson) {
      let finaljsonData = JSON.parse(conJson);
      configjson = finaljsonData.find((row) => row.language === i18n.language);
    }

    let { t } = this.props;
    let editableField =
      values !== undefined ? values.status !== "New Participant" : false;

    let canCreateTestParticipant =
      this.props.statedata.isinternal.indexOf(
        "tenant.project.participant.test.create"
      ) >= 0
        ? true
        : false;

    let canReadTestParticipant =
      this.props.statedata.isinternal.indexOf(
        "tenant.project.participant.test.read"
      ) >= 0
        ? true
        : false;

    let canUpdateTestParticipant =
      this.props.statedata.isinternal.indexOf(
        "tenant.project.participant.test.update"
      ) >= 0
        ? true
        : false;

    let canUpdateParticipant =
      this.props.statedata.isinternal.indexOf(
        "tenant.project.participants.update"
      ) >= 0
        ? true
        : false;

    let readOnlyField =
      this.props.title === "Edit" ? !canUpdateParticipant : false;

    const nonEditableStatus = [
      "Non-Responsive Participant",
      "Attempted - Partial",
      "Attempted - Incomplete",
      "Interviewed",
      "Result Published",
    ];

    const isDisabledField =
      this.props.title === "Edit" && nonEditableStatus.includes(values?.status);

    const checkIsButtonDisable = () => {
      if (this.props.isTestParticipant) {
        return !(canUpdateParticipant && canUpdateTestParticipant);
      } else {
        return !canUpdateParticipant;
      }
    };

    const { validationIRN } = this.state;
    return (
      <React.Fragment>
        <div className="w-1200">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(fields, submitProps) => {
              const copyFields = { ...fields };
              if (copyFields.phoneNumber && copyFields.phoneNumber.length < 5) {
                copyFields.phoneNumber = "";
              }
              this.props.handleSubmit(copyFields, submitProps);
            }}
            validationSchema={Yup.object().shape({
              participant_language_id: Yup.string().required(
                t("Please select language")
              ),
              interviewRefNum: validationIRN
                ? Yup.string()
                    .required(t("IRN is required"))
                    .matches(
                      /^[0-9-]+$/,
                      t(
                        "IRN must be numeric only (based on current project configuration)"
                      )
                    )
                    .test(
                      "min-length",
                      t("IRN must be at least 6 characters long"),
                      (value) => {
                        // Include hyphens in the character count for the minimum length requirement
                        return value && value.replace(/-/g, "").length >= 6;
                      }
                    )
                    .test(
                      "max-length",
                      t("IRN must not exceed 15 characters long"),
                      (value) => {
                        // Exclude hyphens from the character count for the maximum length requirement
                        const lengthWithoutHyphens =
                          value && value.replace(/-/g, "").length;
                        return lengthWithoutHyphens <= 15;
                      }
                    )
                : Yup.string()
                    .matches(
                      noWhiteSpaceRegExp,
                      t("White Space is not allowed")
                    )
                    .notRequired(),
              email: Yup.string().email("Invalid email address"),
              phoneNumber: Yup.string()
                .nullable()
                .test(
                  "is-valid-phone",
                  "Phone number is not valid",
                  (value) => {
                    if (!value) return true;
                    const phoneNumber = parsePhoneNumberFromString(value);
                    return phoneNumber && phoneNumber !== ""
                      ? phoneNumber.isValid()
                      : true;
                  }
                ),
            })}
          >
            {(prop) => {
              const {
                dirty,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldValue,
                setFieldTouched,
                isValid,
              } = prop;
              return (
                <form
                  onSubmit={(event, field) => {
                    event.preventDefault();
                    handleSubmit(field);
                  }}
                >
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid size={12}>
                          <h5 className="page-title">
                            {t(this.props.title)} {t("Participant")}
                          </h5>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <CustomPrompt dirty={dirty} />
                  {this.state.configuredMsg?.nonConfigureEmailLanguages &&
                  !this.state.projectLoader ? (
                    <div className="col-12 alert alert-danger">
                      {this.state.configuredMsg?.nonConfigureEmailLanguages}
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.configuredMsg?.nonConfigureSMSLanguages &&
                  !this.state.projectLoader ? (
                    <div className="col-12 alert alert-danger">
                      {this.state.configuredMsg?.nonConfigureSMSLanguages}
                    </div>
                  ) : (
                    ""
                  )}
                  <Field
                    name="timezone_id"
                    type="hidden"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.timezone_id}
                  />

                  <AlertDismissible
                    className="w-100"
                    msg={this.state.alertMsg}
                    variantType={this.state.alertVariant}
                    show={this.state.showAlert}
                    close={() => this.setState({ showAlert: false })}
                    autoClose={true}
                  ></AlertDismissible>
                  <Card>
                    <CardContent>
                      {this.state.projectLoader ? (
                        <div className="loader">
                          <Spinner className="table-loader" />
                        </div>
                      ) : (
                        <>
                          <Grid container>
                            <Grid size={8}>
                              <Grid
                                container
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                              >
                                <Grid size={6}>
                                  <div className="form-group">
                                    <label
                                      htmlFor="interviewRefNum"
                                      className={
                                        validationIRN ? "required" : ""
                                      }
                                    >
                                      {t("Individual Reference Number (IRN)")} -{" "}
                                      <span className="text-lowercase-irn">
                                        {t("e.g.")}
                                      </span>{" "}
                                      <span className="text-uppercase-irn">
                                        {t("Claim Number")}
                                      </span>
                                    </label>
                                    <Field
                                      name="interviewRefNum"
                                      autoComplete="off"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.interviewRefNum &&
                                        touched.interviewRefNum
                                          ? " is-invalid"
                                          : "")
                                      }
                                      disabled={
                                        this.props.title == "Edit" ||
                                        editableField
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.interviewRefNum}
                                    />
                                    {errors.interviewRefNum &&
                                      touched.interviewRefNum && (
                                        <div className="input-feedback">
                                          {errors.interviewRefNum}
                                        </div>
                                      )}
                                  </div>
                                </Grid>

                                <Grid size={6}></Grid>
                                <Grid size={6}>
                                  <div className="form-group">
                                    <label htmlFor="first_name">
                                      {this.handleFirstName(configjson, t)}
                                    </label>
                                    <Field
                                      name="first_name"
                                      autoComplete="off"
                                      disabled={
                                        isDisabledField || readOnlyField
                                      }
                                      type="text"
                                      className="form-control"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.first_name}
                                    />
                                  </div>
                                </Grid>
                                <Grid size={6}>
                                  <div className="form-group">
                                    <label htmlFor="last_name">
                                      {this.handleLastName(configjson, t)}
                                    </label>
                                    <Field
                                      name="last_name"
                                      autoComplete="off"
                                      type="text"
                                      disabled={
                                        isDisabledField || readOnlyField
                                      }
                                      className="form-control"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.last_name}
                                    />
                                  </div>
                                </Grid>
                                <Grid size={6}>
                                  <div className="form-group">
                                    <label htmlFor="email">{t("Email")}</label>
                                    <Field
                                      name="email"
                                      type="email"
                                      autoComplete="off"
                                      disabled={
                                        isDisabledField || readOnlyField
                                      }
                                      className="form-control"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.email}
                                    />
                                    {errors.email && touched.email && (
                                      <div className="input-feedback">
                                        {errors.email}
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                                <Grid size={6}>
                                  <div className="form-group">
                                    <label htmlFor="phoneNumber">
                                      {t("Phone")}
                                    </label>
                                    <PhoneInput
                                      country={
                                        this.state.countryCodeForParticipant[0]
                                      }
                                      placeholder=""
                                      onlyCountries={
                                        this.state.countryCodeForParticipant
                                      }
                                      preserveOrder={["onlyCountries"]}
                                      autoComplete="off"
                                      countryCodeEditable={false}
                                      value={this.state.phone}
                                      onChange={(phone) =>
                                        this.handleChangePhoneNumber(
                                          phone,
                                          setFieldValue
                                        )
                                      }
                                      onBlur={() =>
                                        setFieldTouched("phoneNumber", true)
                                      }
                                      disabled={
                                        readOnlyField || isDisabledField
                                      }
                                    />

                                    <Field
                                      name="phoneNumber"
                                      type="hidden"
                                      className="form-control"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.phoneNumber}
                                    />
                                    {errors.phoneNumber &&
                                      touched.phoneNumber && (
                                        <div className="input-feedback">
                                          {errors.phoneNumber}
                                        </div>
                                      )}
                                  </div>
                                </Grid>
                                <Grid size={6}>
                                  <div className="form-group">
                                    <label
                                      htmlFor="participant_language_id"
                                      className="required"
                                    >
                                      {t("Language")}
                                    </label>
                                    <Field
                                      name={"participant_language_id"}
                                      onBlur={handleBlur}
                                      component={SelectField}
                                      options={this.state.multiLingualOption}
                                      values={values.participant_language_id}
                                      isDisabled={
                                        isDisabledField || readOnlyField
                                      }
                                    />
                                    {errors.participant_language_id &&
                                      touched.participant_language_id && (
                                        <div className="input-feedback">
                                          {errors.participant_language_id}
                                        </div>
                                      )}
                                  </div>
                                </Grid>
                                <Grid size={6}>
                                  <div className="form-group">
                                    <label htmlFor="additional_description">
                                      {t("Additional Description")}
                                    </label>

                                    <Field
                                      component="textarea"
                                      id="additional_description"
                                      className="form-control"
                                      name="additional_description"
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>

                            {this.props.title === "Add" &&
                            canCreateTestParticipant ? (
                              <Grid size={4}>
                                <div className="form-group mt-4 ml-4 d-flex">
                                  <Field
                                    name={"is_test_participant"}
                                    type="checkbox"
                                    onBlur={handleBlur}
                                    disabled={
                                      this.state.disabledTestParticipant
                                        ? true
                                        : false
                                    }
                                    onChange={(event) =>
                                      setFieldValue(
                                        "is_test_participant",
                                        event.target.checked
                                      )
                                    }
                                    checked={values.is_test_participant}
                                    values={values.is_test_participant}
                                  />
                                  <label htmlFor="Test" className="ml-2">
                                    {t("Test Participant")}
                                  </label>
                                </div>
                              </Grid>
                            ) : null}
                            {this.props.title === "Edit" &&
                            canReadTestParticipant ? (
                              <Grid size={4}>
                                <div className="form-group mt-4 ml-4 d-flex">
                                  <Field
                                    name={"is_test_participant"}
                                    type="checkbox"
                                    onBlur={handleBlur}
                                    disabled={
                                      !canUpdateTestParticipant
                                        ? true
                                        : !!this.props.isTestParticipant
                                    }
                                    onChange={(event) =>
                                      setFieldValue(
                                        "is_test_participant",
                                        event.target.checked
                                      )
                                    }
                                    checked={values.is_test_participant}
                                    values={values.is_test_participant}
                                  />
                                  <label htmlFor="Test" className="ml-2">
                                    {t("Test Participant")}
                                  </label>
                                </div>
                              </Grid>
                            ) : null}
                          </Grid>
                          <Grid container>
                            <Grid size={12} className="mt-3">
                              <div className="form-group float-right">
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="rounded-0 mr-4 save-btn button_with_loader"
                                  disabled={
                                    this.props.title === "Edit"
                                      ? this.props.disablesavebtn ||
                                        checkIsButtonDisable()
                                      : this.props.disablesavebtn || !isValid
                                  }
                                >
                                  {this.props.disablesavebtn ? (
                                    <>
                                      {t("Save")}...
                                      <div className="custom-loader linesLoader loader_inner_button">
                                        <Spinner
                                          color="#ffffff"
                                          size={"19px"}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <span>{t("Save")}</span>
                                  )}
                                </Button>
                                <Button
                                  variant="secondary"
                                  type="button"
                                  onClick={this.props.onCancelClick}
                                >
                                  {t("Cancel")}
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </form>
              );
            }}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const SelectField = ({ options, field, form, values, isDisabled }) => {
  const { t } = useTranslation();

  return (
    <Select
      defaultValue={options[0]}
      options={options}
      className="select-style"
      classNamePrefix="select"
      name={field.name}
      value={values ? options.find((option) => option.value === values) : ""}
      onChange={(option) => form.setFieldValue(field.name, option.value)}
      onBlur={() => {
        form.setFieldTouched(field.name);
      }}
      placeholder={t("Select")}
      menuPlacement="auto"
      isSearchable={false}
      isDisabled={isDisabled}
    />
  );
};
export default withTranslation()(AddEditParticipant);
