import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  faCheck,
  faFolder,
  faMinus,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Grid2 as Grid, CardContent, CardHeader } from "@mui/material";
import moment from "moment";
import * as FileSaver from "file-saver";
import {
  Alert as AlertDismissible,
  Button,
  ModalPopUp,
} from "../../components/Common";
import { Date_Format } from "../../config";
import {
  addDocument,
  deleteDocument,
  getDocument,
  getDocumentList,
  updateDocumentComment,
} from "../../Services/uploadDocService";
import { getUserInfo } from "../../Services/userServices";
import { filenameFromResponseHeader } from "../../shared/utils";
import { CustomGrid } from "../CustomTable/index.js";
import { IsConfirm } from "../Utility/ModalPopUp";
import * as userPermission from "../Utility/userPermission.js";

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searchText: "",
      descEditFlag: false,
      fileModalShow: false,
      filename: "Select File",
      docList: [],
      alertMsg: "",
      alertStatus: false,
      variant: "",
      currentPage: 0,
      perPageCount: 10,
      totalPages: 0,
      fileUploadingFlag: false,
      userInfo: null,
      sortOrder: {},
      fileSeletedFlag: false,
      fileData: null,
      MAX_FILE_SIZE: 10, // file size in MB
      isConfirm: false,
      deleteDocumentId: null,
    };
    this.fileInput = React.createRef();
  }

  getInitialData = () => {
    return {
      search: this.state.searchText,
      page: this.state.currentPage,
      pageSize: this.state.perPageCount,
      sortBy: this.state.sortOrder,
    };
  };
  loadDocumentList = (data) => {
    getDocumentList(data)
      .then((res) => {
        this.setState({
          docList: res.data.documents,
          totalPages: res.data.totalDocuments,
          loading: false,
        });
      })
      .catch((err) => {
        console.log("err in get document list", err);
      });
  };
  componentDidMount() {
    let data = this.getInitialData();
    getUserInfo()
      .then((res) => {
        this.setState({
          userInfo: res.data,
        });
      })
      .catch((err) => {
        console.log("error in get user info", err);
      });
    this.loadDocumentList(data);
  }

  debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  handleChange = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    this.setState({ [name]: val, loading: true }, () => {
      let data = this.getInitialData();
      this.loadDocumentList(data);
    });
  };

  debouncedOnChange = this.debounce(this.handleChange, 1000);

  deleteDoc = (id) => {
    deleteDocument(id)
      .then((res) => {
        console.log("delete document", res);
        this.setState(
          {
            alertMsg: "Document deleted successfully!",
            alertStatus: true,
            variant: "success",
          },
          () => {
            let data = this.getInitialData();
            this.loadDocumentList(data);
          }
        );
      })
      .catch((err) => {
        console.log("error in delete document", err);
      });
  };

  handleModalClose = () => {
    this.setState({
      fileModalShow: false,
      filename: "Select File",
      fileSeletedFlag: false,
      fileError: "",
      fileUploadingFlag: false,
    });
  };
  handleAlertClose = () => {
    this.setState({ alertStatus: false, alertMsg: "" });
  };
  selectUploadFile = (event) => {
    event.preventDefault();
    const allowedTypes = [
      "application/pdf",
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
      "application/msword",
      "application/json",
      "text/html",
    ];

    let fileName = this.fileInput.current.files[0].name;

    let file = this.fileInput.current.files[0];
    let size = file.size;
    let fileError = "";
    let fileData = null;
    let fileSeletedFlag;
    if (!allowedTypes.includes(this.fileInput.current.files[0].type)) {
      alert("Invalid file type. Please upload a valid file.");
      this.fileInput.current.value = "";
    } else {
      if (size > 1024 * 1024 * this.state.MAX_FILE_SIZE) {
        fileError = "File size should be less than or equal to";
        fileSeletedFlag = false;
      } else {
        let formData = new FormData();
        formData.append("document", file);
        fileData = formData;
        fileSeletedFlag = true;
        this.fileInput.current.value = "";
      }
      this.setState({
        fileData,
        fileError,
        fileSeletedFlag,
        filename: fileName,
      });
    }
  };

  selectFileUpload = () => {
    let self = this;
    this.setState(
      {
        fileUploadingFlag: true,
      },
      () => {
        addDocument(self.state.fileData)
          .then((res) => {
            self.setState(
              {
                alertMsg: "Document uploaded successfully!",
                alertStatus: true,
                variant: "success",
                fileModalShow: false,
                filename: "Select File",
                fileUploadingFlag: false,
                fileSeletedFlag: false,
              },
              () => {
                let data = self.getInitialData();
                self.loadDocumentList(data);
              }
            );
          })
          .catch((err) => {
            console.log("error in add doc", err);
            this.setState({
              fileUploadingFlag: false,
              fileSeletedFlag: false,
            });
          });
      }
    );
  };
  updateDocComment = (id, comment) => {
    comment = comment.trim();
    let data = {
      comment,
    };
    updateDocumentComment(id, data)
      .then((res) => {
        this.setState(
          {
            alertMsg: "comment updated successfully!",
            alertStatus: true,
            variant: "success",
          },
          () => {
            let data = this.getInitialData();
            this.loadDocumentList(data);
          }
        );
      })
      .catch((err) => {
        console.log("get error in update comment", err);
        this.setState({
          alertMsg: err?.response?.data?.message,
          variant: "danger",
          alertStatus: true,
        });
      });
  };
  onChangePage = (number) => {
    this.setState(
      {
        currentPage: number,
        loading: true,
      },
      () => {
        let data = this.getInitialData();
        this.loadDocumentList(data);
      }
    );
  };

  onChangeRecords = (page) => {
    this.setState(
      {
        perPageCount: page.pageSize,
        currentPage: page.page,
        loading: true,
      },
      () => {
        let data = this.getInitialData();
        this.loadDocumentList(data);
      }
    );
  };

  downloadDocument = (row) => {
    let self = this;
    let flag = row.documentId + "flag";
    this.setState(
      {
        [flag]: true,
      },
      () => {
        getDocument(row.documentId)
          .then((res) => {
            console.log("response", res);
            const blob = new Blob([res.data], {
              type: res.headers["mime-type"],
            });
            FileSaver.saveAs(
              blob,
              filenameFromResponseHeader(res.headers["content-disposition"])
            );
            self.setState({
              [flag]: false,
            });
          })
          .catch((err) => {
            console.log("error in download file", err);
          });
      }
    );
  };

  onColumnSort = (sortModel) => {
    let sortObject = {};
    sortObject[sortModel[0]?.field] = sortModel[0]?.sort;
    this.setState(
      (preState) => {
        return {
          ...preState,
          sortOrder: sortObject,
          loading: true,
        };
      },
      () => {
        let data = this.getInitialData();
        this.loadDocumentList(data);
      }
    );
  };

  onConfirmBox = (id) => {
    this.setState({ isConfirm: true, deleteDocumentId: id });
  };

  handleConfirm = (e) => {
    if (e === true && this.state.deleteDocumentId != null) {
      this.deleteDoc(this.state.deleteDocumentId);
    }
    this.setState({ isConfirm: false, deleteDocumentId: null });
  };
  getRowId = (row) => row.documentId;
  render() {
    let { t } = this.props;
    let userPermissionVal = [];
    if (
      this.state.userInfo != null &&
      this.state.userInfo.rolePermission &&
      this.state.userInfo.rolePermission.permissions
    ) {
      userPermissionVal = this.state.userInfo.rolePermission.permissions;
    }

    const columns = [
      {
        field: "documentName",
        headerName: `${t("File Name")}`,
        cellClassName: "document_file_name",
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          return (
            <span
              onClick={(e) => {
                e.stopPropagation();
                this.downloadDocument(params.row);
              }}
            >
              {this.state[params.row.documentId + "flag"] ? (
                <React.Fragment>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {params.row.documentName}
                </React.Fragment>
              ) : (
                params.row.documentName
              )}
            </span>
          );
        },
      },
      {
        field: "documentUploadTS",
        headerName: `${t("Date")}`,
        flex: 1,
        sortable: true,
        renderCell: (params) => {
          return moment(params.row.documentUploadTS).format(Date_Format);
        },
      },
      {
        field: "documentType",
        headerName: t("File Type"),
        flex: 1,
        sortable: true,
      },
      {
        field: "uploadedBy",
        headerName: `${t("Uploaded By")}`,
        flex: 1,
        sortable: false,
      },
      {
        field: "comment",
        headerName: `${t("Description")}`,
        flex: 1,
        renderCell: (params) => {
          return (
            <EditableTextarea
              {...params.row}
              text={params.row.comment != undefined ? params.row.comment : ""}
              updateDocumentComment={this.updateDocComment}
            />
          );
        },
        sortable: false,
      },
      ...(userPermission.isDocumentDeleteEnabled(userPermissionVal)
        ? [
            {
              field: "action",
              headerName: ``,
              renderCell: (params) => {
                return (
                  <Button
                    key={params.row.id}
                    variant="secondary"
                    size="sm"
                    className="btn-rounded"
                    title="Delete"
                    onClick={() => this.onConfirmBox(params.row.documentId)}
                    iconButton={true}
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="button_icon_only"
                    />
                  </Button>
                );
              },
              sortable: false,
            },
          ]
        : []),
    ];
    return (
      <React.Fragment>
        <div className="w-1200">
          <div className="page-breadcrumb">{t("Documents")}</div>
          <Grid container className="participants-page">
            <Grid size={12}>
              <Card>
                <CardHeader title={t("Search for Document")}></CardHeader>
                <CardContent>
                  <Grid container>
                    <Grid size={6}>
                      <input
                        className="form-control"
                        name="searchText"
                        placeholder={`${t("Search File Name, Type, Etc")}.`}
                        onChange={this.debouncedOnChange}
                      />
                    </Grid>
                    <Grid size={6}>
                      {userPermission.isDocumentCreateEnabled(
                        userPermissionVal
                      ) ? (
                        <a
                          className="add-participant-link float-right"
                          onClick={() => this.setState({ fileModalShow: true })}
                        >
                          <button className="btn btn-success btn-rounded btn-sm">
                            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                          </button>
                          <span className="add-participant-text"></span>
                        </a>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container>
            <Grid size={12}>
              <AlertDismissible
                msg={this.state.alertMsg}
                variantType={this.state.variant}
                show={this.state.alertStatus}
                close={this.handleAlertClose}
              ></AlertDismissible>
            </Grid>
          </Grid>
          <Grid container>
            <Grid size={12}>
              <Card>
                <div className="table_wrapper custom_table_container app-table document-module-table">
                  <CustomGrid
                    getRowId={this.getRowId}
                    data={this.state.docList}
                    columns={columns}
                    loading={this.state.loading}
                    totalPages={this.state.totalPages}
                    currentPage={this.state.currentPage}
                    perPageCount={this.state.perPageCount}
                    onChangeRecords={this.onChangeRecords}
                    isServerPagination={true}
                    isServerSorting={true}
                    onSortModelChange={this.onColumnSort}
                  />
                </div>
              </Card>
            </Grid>
          </Grid>
          <ModalPopUp
            onHide={this.handleModalClose}
            title={t("Add Document")}
            show={this.state.fileModalShow}
          >
            <div className="form-group position-relative">
              <input
                className="form-control"
                value={t(this.state.filename)}
                readOnly
              />
              <FontAwesomeIcon
                icon={faFolder}
                className="folder-icon"
              ></FontAwesomeIcon>
              <input
                type="file"
                name="file"
                className="upload-file-input ac-upload-btn"
                ref={this.fileInput}
                onChange={this.selectUploadFile}
                accept=".pdf,.png,.jpeg,.jpg,.xlsx,.docx,.doc,.json,.htm,.html,application/pdf,image/png,image/jpeg,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/json,text/html"
              />
              <small className="">
                {t("maximum file size")}: {this.state.MAX_FILE_SIZE} MB
              </small>
              {this.state.fileError != undefined && this.state.fileError && (
                <div className="input-feedback">
                  {`${t(this.state.fileError)} ${this.state.MAX_FILE_SIZE} MB`}
                </div>
              )}
            </div>
            <div className="text-center mt-5">
              <Button
                variant="primary"
                className="mx-1"
                onClick={this.selectFileUpload}
                disabled={
                  !this.state.fileSeletedFlag || this.state.fileUploadingFlag
                }
              >
                {this.state.fileUploadingFlag ? (
                  <React.Fragment>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Uploading...
                  </React.Fragment>
                ) : (
                  t("Select")
                )}
              </Button>
              <Button
                variant="secondary"
                className="mx-1"
                onClick={this.handleModalClose}
              >
                {t("Cancel")}
              </Button>
            </div>
          </ModalPopUp>
          <IsConfirm
            show={this.state.isConfirm}
            onOkay={(e) => this.handleConfirm(e)}
            t={this.props.t}
          >
            {"Do you want to delete document?"}
          </IsConfirm>
        </div>
      </React.Fragment>
    );
  }
}
export default withTranslation()(Documents);

class EditableTextarea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editFlag: false,
      text: props.text,
    };
  }

  handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ editFlag: true });
  };

  handleChange = (e) => {
    e.preventDefault();
    let val = e.target.value;
    this.setState({ text: val });
  };

  updateDescription = () => {
    if (this.state.text !== "") {
      this.setState({ editFlag: false }, () => {
        this.props.updateDocumentComment(
          this.props.documentId,
          this.state.text
        );
      });
    } else {
      this.setState({ editFlag: false });
    }
  };

  getCommentSubString = (comment) => {
    if (comment.length > 20) {
      return comment.substring(0, 20) + " ...";
    }
    return comment;
  };

  render() {
    let { editFlag, text } = this.state;
    return (
      <div className="position-relative">
        <div
          className={editFlag ? "form-group d-none mb-0" : "form-group mb-0"}
          onClick={this.handleClick}
        >
          <input
            className="form-control"
            defaultValue={this.getCommentSubString(text.trim())}
          />
        </div>
        <div className={editFlag ? "d-block" : "d-none"}>
          <div className="form-group mb-0 position-relative edit-desc-box">
            <textarea
              rows={1}
              className={
                editFlag
                  ? "form-control edit-textarea hover"
                  : "form-control edit-textarea"
              }
              readOnly={!editFlag}
              value={text}
              onChange={this.handleChange}
            ></textarea>
          </div>
          <span
            className={
              editFlag ? "float-right d-block icon-box" : "float-right d-none"
            }
          >
            <FontAwesomeIcon
              icon={faCheck}
              className="border-1 mr-2"
              onClick={this.updateDescription}
            />
            <FontAwesomeIcon
              icon={faTimes}
              className="border-1 "
              onClick={() => this.setState({ editFlag: false })}
            />
          </span>
        </div>
      </div>
    );
  }
}
