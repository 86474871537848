import React, { useEffect, useState } from "react";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Grid2 as Grid } from "@mui/material";
import moment from "moment";
import { AddCircleIcon } from "../../../../assets/images/icons/customIcons.js";
import {
  Alert as AlertDismissible,
  Button,
} from "../../../../components/Common";
import { Date_Format } from "../../../../config/index.js";
import {
  deleteOrder,
  getOrderList,
} from "../../../../Services/OrderServices.js";
import { CustomGrid } from "../../../CustomTable/index.js";
import { isOrderCreateEnabled } from "../../../Utility/userPermission.js";
import { getTenantId } from "../Helper/index.js";
import DeleteOrder from "./DeleteOrder.js";

const OrderList = (props) => {
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPagination, setShowPagination] = useState(true);
  const [isConfirm, setIsConfirm] = useState(false);
  const [deletedOrderId, setDeletedOrderId] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [deletedOrderMsg, setDeletedOrderMsg] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [variant, setVariant] = useState("");

  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(0);
  const [perPageCount, setPerPageCount] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  useEffect(() => {
    fetchOrderList("showLoder");
  }, [currentPage, perPageCount]);

  /**
   * This function will fetch all order list
   */
  const fetchOrderList = (loaderStatus) => {
    if (loaderStatus === "showLoder") {
      setLoading(true);
    }

    const tanentId = getTenantId();
    getOrderList(tanentId, currentPage, perPageCount)
      .then((res) => {
        setTotalResults(res.data.total_orders);
        setOrderList(res.data.orders);
        setLoading(false);
      })
      .catch(() => {
        setShowPagination(true);
        setOrderList([]);
        setLoading(false);
      });
  };

  /**
   * This function is responsible for showing order detail
   * @param {string} orderId
   */
  const openOrderDetail = (orderId) => {
    props.UpdateChildNav("edit order", orderId);
  };

  /**
   * This funciton will open delete confirmation popup
   * @param {string} e
   * @param {string} row
   */
  const openIsConfirm = (row) => {
    setDeletedOrderMsg("Are you sure you want to delete this order ?");
    setDeletedOrderId(row.id);
    setIsConfirm(true);
  };

  /**
   * This function will delete order or cancle delete confimation depend upon toggle value
   * @param {boolean} toggle
   */
  const handleDeleteOrder = (toggle) => {
    if (toggle) {
      setDisabledBtn(true);
      deleteOrder(deletedOrderId)
        .then(() => {
          setShowPagination(false);
          fetchOrderList("dontShowLoder");
          setDisabledBtn(false);
          setIsConfirm(false);
          setAlertMsg(`Order Deleted Successfully`);
          setVariant("success");
          setAlertStatus(true);
        })
        .catch((error) => {
          setDisabledBtn(false);
          setIsConfirm(false);
          setAlertMsg(error.response?.data?.message);
          setVariant("danger");
          setAlertStatus(true);
        });
    } else {
      setDeletedOrderId("");
      setIsConfirm(false);
    }
  };

  const columns = [
    {
      field: "order_number",
      headerName: t("SalesForce Order #"),
      flex: 1,
      sortable: false,
      cellClassName: "tenant-manage-table-col-1",
    },
    {
      field: "start_date",
      headerName: t("Order Start Date"),
      flex: 1,
      sortable: false,
      renderCell: (params) =>
        params.row.start_date
          ? moment(params.row.start_date).format(Date_Format)
          : "-",
    },
    {
      field: "end_date",
      headerName: t("Order End Date"),
      flex: 1,
      sortable: false,
      renderCell: (params) =>
        params.row.end_date
          ? moment(params.row.end_date).format(Date_Format)
          : "-",
    },
    {
      field: "number_of_calls",
      headerName: t("Total Number of Calls"),
      flex: 1,
      sortable: false,
    },
    {
      field: "order_status",
      headerName: t("Order Status"),
      flex: 1,
      sortable: false,
    },
    {
      field: "action",
      headerName: t("Action"),
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="secondary"
            className="btn-rounded btn-sm btn btn-secondary"
            onClick={(event) => {
              openIsConfirm(params.row), event.stopPropagation();
            }}
            iconButton={true}
          >
            <FontAwesomeIcon icon={faMinus} className="button_icon_only" />
          </Button>
        );
      },
    },
  ];

  const handleAlertClose = () => {
    setAlertStatus(false);
  };

  const onChangeRecords = (perPageCount) => {
    setCurrentPage(perPageCount.page);
    setPerPageCount(perPageCount.pageSize);
  };
  const onRowClick = (params) => {
    openOrderDetail(params.row.id);
  };

  const recordstatus1 = parseInt(currentPage * perPageCount) + 1;

  const recordstatus2 =
    parseInt(currentPage * perPageCount) + parseInt(perPageCount);

  let userPermissionVal =
    props.dataFromParent.statedata?.userInfo?.rolePermission?.permissions;

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-8">
              <h6 className="page-title">{t("Manage Order")}</h6>
            </div>
            {isOrderCreateEnabled(userPermissionVal) && (
              <div className="col-lg-4">
                <div
                  className="tenant-add-button"
                  onClick={() => props.UpdateChildNav("create order")}
                >
                  <AddCircleIcon className={{ color: "#34AA44" }} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <Grid container>
          <Grid size={12}>
            <AlertDismissible
              msg={alertMsg}
              variantType={variant}
              show={alertStatus}
              close={handleAlertClose}
            ></AlertDismissible>
          </Grid>
          {totalPages !== 0 ? (
            <Grid size={12}>
              <div className="col-12 alert alert-success">
                {t("Showing")} {recordstatus1} -
                {recordstatus2 < totalResults ? recordstatus2 : totalResults}{" "}
                {t("of")} {totalResults} {t("Orders")}
              </div>
            </Grid>
          ) : null}
        </Grid>
        <div className="card">
          <div className="table_wrapper custom_table_container app-table admin-orderdetail-module-table">
            <CustomGrid
              data={orderList}
              columns={columns}
              perPageCount={perPageCount}
              currentPage={currentPage}
              totalPages={totalResults}
              isServerPagination={true}
              loading={loading}
              onChangeRecords={onChangeRecords}
              onRowClick={onRowClick}
            />
          </div>
        </div>

        <DeleteOrder
          show={isConfirm}
          t={t}
          onOkay={(e) => handleDeleteOrder(e)}
          disabledBtn={disabledBtn}
        >
          {t(deletedOrderMsg)}
        </DeleteOrder>
      </div>
    </div>
  );
};
export default OrderList;
