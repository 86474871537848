import { Card, CardContent, Grid2 as Grid, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CustomTooltip } from "../../components/Utility/CustomTooltip";
import { useGetReport } from "./context/actions";
import { Button } from "../../components/Common";
import { AddReport } from "./AddReport";

const isSearchEnable = false;
const isEnableTimeButton = 30;

export const ReportHeader = () => {
  const { t } = useTranslation();
  const { getReports } = useGetReport();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [refreshButton, setRefreshButton] = useState(true);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let timer;
    if (seconds > 0) {
      timer = setTimeout(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0 && !refreshButton) {
      setRefreshButton(true);
    }
    return () => clearTimeout(timer);
  }, [seconds]);

  const handleRefresh = async () => {
    setRefreshButton(false);
    setSeconds(isEnableTimeButton);
    try {
      await getReports();
    } catch (error) {
      console.error("Failed to get reports:", error);
    }
  };

  return (
    <>
      <div className="page-breadcrumb">{t("ANALYTIC_REPORTS")}</div>
      <Card>
        <CardContent
          className="align-items-center"
          sx={{ paddingBottom: "16px !important" }}
        >
          <Grid container className="align-items-center">
            <Grid size={4}>
              <div className="d-flex align-items-center">
                <div>
                  <h6 className="page-title">{t("REPORTS_LIST")}</h6>
                </div>
                <div className="d-inline-flex1">
                  <CustomTooltip
                    place="bottom"
                    effect="solid"
                    title={"REFRESH"}
                  >
                    <IconButton
                      aria-label="refresh"
                      onClick={() => handleRefresh()}
                      disabled={!refreshButton}
                    >
                      <RefreshIcon className="refresh_icon_color" />
                    </IconButton>
                  </CustomTooltip>
                </div>
              </div>
            </Grid>
            <Grid size={8}>
              <div className="d-flex justify-content-end align-items-center">
                <div className="col-5">
                  {isSearchEnable && (
                    <input
                      className="form-control"
                      name="searchText"
                      placeholder={`${t("Search")}`}
                    />
                  )}
                </div>
                <div>
                  <Button
                    variant="primary"
                    type="button"
                    className="rounded-4 save-btn button_with_loader"
                    onClick={() => setDialogOpen(true)}
                  >
                    <span>{t("NEW_REPORT")}</span>
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {dialogOpen && (
        <AddReport open={dialogOpen} onClose={() => setDialogOpen(false)} />
      )}
    </>
  );
};
