import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Formik } from "formik";
import * as Yup from "yup";
import { Grid2 as Grid } from "@mui/material";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../../components/Common";
import {
  getRolePermission,
  upRolePermission,
} from "../../../Services/permissionServices";
import { Checkbox } from "../../Utility/Checkbox.js";
import PreventLeaveRoute from "../../Utility/PreventLeaveRoute.js";
import * as userPermission from "../../Utility/userPermission.js";
import { scrollTopWindow } from "../../../Helpers";
import "../../../style/admin/react_accessible_accordion.css";

class ManagePermissions extends Component {
  constructor(props) {
    const rolePermission = require("../../../config/rolePermission.json");
    super(props);
    this.state = {
      childNav: "Permissions",
      role: "",
      roleDescription: "",
      userRoles: [
        { labbel: "test", value: 1 },
        { labbel: "test2", value: 2 },
      ],
      rolePermission: rolePermission,
      AcgrRolePermission: rolePermission.acgr_permission,
      disablesavebtn: false,
      roleId: "",
      isSelectRole: false,
    };
  }

  dirtyFlagChanged = (dirty) => {
    if (dirty != this.state.dirty) {
      this.setState({
        dirty: dirty,
      });
      this.props.dataFromParent.handleChildDirtyGFlag(dirty);
    }
  };

  updatePermission = (permission, rolePermissionList) => {
    return permission.map((rolePermission) => {
      const updatedAccess_type = rolePermission.access_type.map(
        (access_type) => {
          let found = false;
          rolePermissionList.permissions.map((permission) => {
            if (permission.permissionNumber == access_type.permissionId) {
              found = true;
            }
          });
          if (access_type.read != null) {
            access_type.read = found;
          }
          if (access_type.write != null) {
            access_type.write = found;
          }
          if (access_type.edit != null) {
            access_type.edit = found;
          }
          if (access_type.delete != null) {
            access_type.delete = found;
          }
          return access_type;
        }
      );
      rolePermission.access_type = updatedAccess_type;
      rolePermission.rolePermissionList = rolePermissionList;
      return rolePermission;
    });
  };

  handleRolesChange = (value) => {
    Promise.all([this.getRolePermission(value.id)]).then(
      ([rolePermissionList]) => {
        let updatedRolePermission = {
          project_permission: this.updatePermission(
            this.state.rolePermission.project_permission,
            rolePermissionList
          ),
          administration_permission: this.updatePermission(
            this.state.rolePermission.administration_permission,
            rolePermissionList
          ),
          acgr_permission: this.updatePermission(
            this.state.rolePermission.acgr_permission,
            rolePermissionList
          ),
        };

        this.setState({
          roleDescription: value.description,
          rolePermission: updatedRolePermission,
          updatedRolePermission: updatedRolePermission,
          roleId: value.id,
          isSelectRole: true,
        });
      }
    );
  };

  getRolePermission = async (roleID) => {
    return await getRolePermission(roleID).then((res) => {
      return res.data;
    });
  };

  getPermissionRoles = (permission, t) => {
    let projectDetailId =
      this.props.dataFromParent.statedata?.userInfo?.rolePermission.permissions.filter(
        (permission) => permission.permission === "tenant.project.detail.read"
      )[0].id;
    let projectPermissionRolesRow = [];

    permission.map((option, index) => {
      let projectPermissionRoles = [];
      projectPermissionRoles.push(
        <div className="Column-first">
          <label>{t(option.label)}</label>
        </div>
      );

      option.access_type.forEach(function (access_permission, index) {
        if (index == 0) {
          if (access_permission.read != null) {
            projectPermissionRoles.push(
              <div className="Column-middle">
                <Checkbox
                  name="roles"
                  value={access_permission.permissionId}
                  disabled={access_permission.permissionId === projectDetailId}
                />
              </div>
            );
            if (option.access_type.length == 1) {
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
            }
          } else if (access_permission.write != null) {
            projectPermissionRoles.push(<div className="Column-middle"></div>);
            projectPermissionRoles.push(
              <div className="Column-middle">
                <Checkbox name="roles" value={access_permission.permissionId} />
              </div>
            );
            if (option.access_type.length == 1) {
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
            }
          } else if (access_permission.edit != null) {
            projectPermissionRoles.push(<div className="Column-middle"></div>);
            projectPermissionRoles.push(<div className="Column-middle"></div>);
            projectPermissionRoles.push(
              <div className="Column-middle">
                <Checkbox name="roles" value={access_permission.permissionId} />
              </div>
            );
            if (option.access_type.length == 1) {
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
            }
          } else if (access_permission.delete != null) {
            projectPermissionRoles.push(<div className="Column-middle"></div>);
            projectPermissionRoles.push(<div className="Column-middle"></div>);
            projectPermissionRoles.push(<div className="Column-middle"></div>);
            projectPermissionRoles.push(
              <div className="Column-middle">
                <Checkbox name="roles" value={access_permission.permissionId} />
              </div>
            );
          } else {
            if (option.access_type.length == 1) {
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
            } else {
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
            }
          }
        }
        if (index == 1) {
          if (access_permission.write != null) {
            projectPermissionRoles.push(
              <div className="Column-middle">
                <Checkbox name="roles" value={access_permission.permissionId} />
              </div>
            );
            if (option.access_type.length == 2) {
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
            }
          } else if (access_permission.edit != null) {
            projectPermissionRoles.push(<div className="Column-middle"></div>);
            projectPermissionRoles.push(
              <div className="Column-middle">
                <Checkbox name="roles" value={access_permission.permissionId} />
              </div>
            );
            if (option.access_type.length == 2) {
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
            }
          } else if (access_permission.delete != null) {
            projectPermissionRoles.push(<div className="Column-middle"></div>);
            projectPermissionRoles.push(<div className="Column-middle"></div>);
            projectPermissionRoles.push(
              <div className="Column-middle">
                <Checkbox name="roles" value={access_permission.permissionId} />
              </div>
            );
          } else {
            if (option.access_type.length == 2) {
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
            } else {
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
            }
          }
        }
        if (index == 2) {
          if (access_permission.edit != null) {
            projectPermissionRoles.push(
              <div className="Column-middle">
                <Checkbox name="roles" value={access_permission.permissionId} />
              </div>
            );
            if (option.access_type.length == 3) {
              projectPermissionRoles.push(
                <div className="Column-middle"></div>
              );
            }
          } else if (access_permission.delete != null) {
            projectPermissionRoles.push(<div className="Column-middle"></div>);
            projectPermissionRoles.push(
              <div className="Column-middle">
                <Checkbox name="roles" value={access_permission.permissionId} />
              </div>
            );
          } else {
            projectPermissionRoles.push(<div className="Column-middle"></div>);
          }
        }
        if (index == 3) {
          if (access_permission.delete != null) {
            projectPermissionRoles.push(
              <div className="Column-middle">
                <Checkbox name="roles" value={access_permission.permissionId} />
              </div>
            );
          } else {
            projectPermissionRoles.push(<div className="Column-middle"></div>);
          }
        }
      });

      projectPermissionRolesRow.push(
        <div className="Row-body" key={`projectPermissionRolesRow_${index}`}>
          {projectPermissionRoles}
        </div>
      );
    });
    return projectPermissionRolesRow;
  };

  getUpdatedPermissionRoles = (permission, projectPermissionRoles) => {
    permission.map((option) => {
      option.access_type.forEach(function (access_permission) {
        if (
          (access_permission.read != null && access_permission.read) ||
          (access_permission.write != null && access_permission.write) ||
          (access_permission.edit != null && access_permission.edit) ||
          (access_permission.delete != null && access_permission.delete)
        ) {
          projectPermissionRoles.push(access_permission.permissionId);
        }
      });
    });
    return projectPermissionRoles;
  };

  handleUpdateRolePermission = (permissionIdList, tenantId, roleId) => {
    this.setState({
      disablesavebtn: true,
    });

    if (!this.state.rolePermission.project_permission[0].rolePermissionList) {
      return;
    }

    const accessListTrue = permissionIdList.map((permissionId) => {
      return {
        permissionId: permissionId,
        active: true,
      };
    });

    const removedPermissionList =
      this.state.rolePermission.project_permission[0].rolePermissionList.permissions.filter(
        (rolePermission) => {
          const found = permissionIdList.find((permissionId) => {
            return permissionId == rolePermission.permissionNumber;
          });
          return !found;
        }
      );

    const accessListFalse = removedPermissionList.map((permission) => {
      return {
        permissionId: permission.permissionNumber,
        active: false,
      };
    });

    const accessList = accessListTrue.concat(accessListFalse);

    let filterArray = accessList;

    const isSystemTenant =
      this.props.dataFromParent.statedata?.userInfo?.tenant?.isSystemTenant;

    if (!isSystemTenant) {
      // Check if an object with permissionId 80 and active: true exists in arrayOfObjects
      const hasPermissionId80 = accessList.some(
        (obj) => obj.permissionId === 80 && obj.active === true
      );

      // If permissionId 80 doesn't exist, then proceed to remove objects with permissionId present in permissionIdsToRemove
      if (!hasPermissionId80) {
        const allPermissionIds = [];

        // Iterate through each object in the array
        this.state.AcgrRolePermission.forEach((obj) => {
          // Access the access_type array inside each object
          const accessType = obj.access_type;

          // Iterate through each access_type object
          accessType.forEach((access) => {
            // Access the permissionId and add it to the allPermissionIds array
            allPermissionIds.push(access.permissionId);
          });
        });
        filterArray = accessList.filter(
          (obj) => !allPermissionIds.includes(obj.permissionId)
        );
      }
    }

    const param = {
      tenantId: tenantId,
      roleId: roleId,
      accessList: filterArray,
    };

    upRolePermission(param)
      .then((res) => {
        let updatedRolePermission = {
          project_permission: this.updatePermission(
            this.state.rolePermission.project_permission,
            res.data
          ),
          administration_permission: this.updatePermission(
            this.state.rolePermission.administration_permission,
            res.data
          ),
          acgr_permission: this.updatePermission(
            this.state.rolePermission.acgr_permission,
            res.data
          ),
        };

        this.setState({
          alertMsg: `Role permission updated successfully`,
          variant: "success",
          alertStatus: true,
          projectDetail: res.data,
          rolePermission: updatedRolePermission,
          updatedRolePermission: updatedRolePermission,
          dirty: false,
          disablesavebtn: false,
        });
        scrollTopWindow();
      })
      .catch((err) => {
        let errMsg = Object.assign({}, err).response.data.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
          disablesavebtn: false,
        });
        scrollTopWindow();
      });
  };

  getDefaultSelectedRole = (selectRolesDropdown, rowID) => {
    const removedPermissionList = selectRolesDropdown.filter((role) => {
      return role.id == rowID;
    });
    return removedPermissionList[0];
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  render() {
    const { t } = this.props;

    const selectRolesDropdown = this.props.dataFromParent.statedata.roles.map(
      (option) => {
        return {
          value: option.roleId,
          label: option.label,
          description: option.description,
          id: option.roleId,
        };
      }
    );

    let projectPermissionRoles = [];
    projectPermissionRoles = this.getUpdatedPermissionRoles(
      this.state.rolePermission.project_permission,
      projectPermissionRoles
    );
    projectPermissionRoles = this.getUpdatedPermissionRoles(
      this.state.rolePermission.administration_permission,
      projectPermissionRoles
    );
    projectPermissionRoles = this.getUpdatedPermissionRoles(
      this.state.rolePermission.acgr_permission,
      projectPermissionRoles
    );

    const validationSchema = Yup.object({
      roleId: Yup.string().required("Please Select Role"),
    });

    const defaultPermission =
      this.props.dataFromParent.statedata?.userInfo?.rolePermission.permissions;
    const filterPermissionId = defaultPermission.filter(
      (permission) => permission.permission === "tenant.project.detail.read"
    )[0].id;

    const projectPermissions =
      !projectPermissionRoles.includes(filterPermissionId) && this.state.roleId
        ? [...projectPermissionRoles, filterPermissionId]
        : projectPermissionRoles;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          roles: projectPermissions,
          roleId: this.state.roleId,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          if (values.roles.length > 0 && this.state.roleId != null) {
            this.handleUpdateRolePermission(
              values.roles,
              this.props.dataFromParent.statedata.tenantId,
              this.state.roleId
            );
            resetForm();
          }
        }}
      >
        {(formik) => (
          <div className="permission-font-style">
            <div onChange={this.dirtyFlagChanged(formik.dirty)}>
              <PreventLeaveRoute
                reset={formik.handleReset}
                when={formik.dirty}
                // Navigate function
                navigate={(field, historyObj) => {
                  historyObj.push(field);
                }}
                // Use as "message" prop of Prompt of React-Router
                shouldBlockNavigation={(location) => {
                  if (location.pathname === "/") {
                    return false;
                  }
                  return !!(formik.dirty || this.state.dirty);
                }}
              />
              <Grid container>
                <Grid size={12}>
                  <AlertDismissible
                    msg={this.state.alertMsg}
                    variantType={this.state.variant}
                    show={this.state.alertStatus}
                    close={this.handleAlertClose}
                  ></AlertDismissible>
                </Grid>
              </Grid>
              <div>
                <div className="row row-header-role-select-header">
                  <div className="card row-header-role-select">
                    <div className="row-header-role-select-border">
                      <label className="row-header-role-select-style row-header-title">
                        {t("Select Role")}
                      </label>
                    </div>
                    <div className="row-header-role-select-option">
                      <Select
                        autoFocus
                        name="userrole"
                        placeholder={t("Select")}
                        options={selectRolesDropdown}
                        className="basic-multi-select mb-1"
                        classNamePrefix="select"
                        onChange={(value) => {
                          this.handleRolesChange(value, formik);
                          formik.setFieldValue("roleId", value.id);
                        }}
                      />
                      {formik.errors.roleId && formik.touched.roleId && (
                        <div className="input-feedback">
                          {formik.errors.roleId}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="card row-header-select-description">
                    <div className="row-header-role-select-border">
                      <label className="row-header-role-select-style row-header-title">
                        {t("Role Description")}
                      </label>
                    </div>
                    <div>
                      <label className="row-header-role-select-style">
                        {this.state.roleDescription}
                      </label>
                    </div>
                  </div>
                </div>
                <Accordion
                  allowZeroExpanded="true"
                  allowMultipleExpanded="true"
                  className={this.state.isSelectRole ? "" : "disable-accordion"}
                >
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className="row-header">
                          <div>
                            <label className="row-header-title">
                              {t("Project Permissions")}
                            </label>
                          </div>
                          <div>
                            <label>
                              {t(
                                "Allow this employee to access your project features and data"
                              )}
                            </label>
                          </div>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="permission-main-body">
                        <div className="Row-head">
                          <div className="Column-first">
                            <label></label>
                          </div>
                          <div className="Column-middle">
                            <label>{t("read")}</label>
                          </div>
                          <div className="Column-middle">
                            <label>{t("create")}</label>
                          </div>
                          <div className="Column-middle">
                            <label>{t("update")}</label>
                          </div>
                          <div className="Column-middle">
                            <label>{t("delete")}</label>
                          </div>
                        </div>
                        <div className="Row-body">
                          {this.getPermissionRoles(
                            this.state.rolePermission.project_permission,
                            t
                          )}
                        </div>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className="row-header">
                          <div>
                            <label className="row-header-title">
                              {t("Adminstration Permissions")}
                            </label>
                          </div>
                          <div>
                            <label>
                              {t(
                                "Allow this employee to access your system adminstrative features and data"
                              )}
                            </label>
                          </div>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="permission-main-body">
                        <div className="Row-head">
                          <div className="Column-first">
                            <label></label>
                          </div>
                          <div className="Column-middle">
                            <label>{t("read")}</label>
                          </div>
                          <div className="Column-middle">
                            <label>{t("create")}</label>
                          </div>
                          <div className="Column-middle">
                            <label>{t("update")}</label>
                          </div>
                          <div className="Column-middle">
                            <label>{t("delete")}</label>
                          </div>
                        </div>
                        <div className="Row-body">
                          {this.getPermissionRoles(
                            this.state.rolePermission.administration_permission,
                            t
                          )}
                        </div>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  {userPermission.isACGRPermissionsTabEnabled(
                    this.props.dataFromParent.statedata.userInfo.rolePermission
                      .permissions
                  ) ? (
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className="row-header">
                            <div>
                              <label className="row-header-title">
                                {t("ACGR Permissions")}
                              </label>
                            </div>
                            <div>
                              <label>
                                {t("Internal ONLY - visible to ACGR personnel")}
                              </label>
                            </div>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="permission-main-body">
                          <div className="Row-head">
                            <div className="Column-first">
                              <label></label>
                            </div>
                            <div className="Column-middle">
                              <label>{t("read")}</label>
                            </div>
                            <div className="Column-middle">
                              <label>{t("create")}</label>
                            </div>
                            <div className="Column-middle">
                              <label>{t("update")}</label>
                            </div>
                            <div className="Column-middle">
                              <label>{t("delete")}</label>
                            </div>
                          </div>
                          <div className="Row-body">
                            {this.getPermissionRoles(
                              this.state.rolePermission.acgr_permission,
                              t
                            )}
                          </div>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ) : (
                    ""
                  )}
                </Accordion>
              </div>
            </div>

            <Grid container>
              <Grid size={12}>
                <div className="form-group float-right permission-save-btn">
                  <Button
                    variant="primary"
                    type="submit"
                    className="rounded-0 mr-4 save-btn button_with_loader"
                    onClick={formik.submitForm}
                    disabled={
                      this.state.disablesavebtn || !this.state.isSelectRole
                    }
                  >
                    {this.state.disablesavebtn ? (
                      <>
                        {t("Save")}...
                        <div className="custom-loader linesLoader loader_inner_button">
                          <Spinner color="#ffffff" size={"19px"} />
                        </div>
                      </>
                    ) : (
                      <span>{t("Save")}</span>
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    className="rounded-0 cancel-btn"
                    disabled={!this.state.isSelectRole}
                    onClick={() => {
                      formik.resetForm();
                    }}
                  >
                    {t("Reset")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </Formik>
    );
  }
}

export default withTranslation()(ManagePermissions);
