import { withAuthenticationRequired } from "@auth0/auth0-react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Select from "react-select";
import api from "../../../Services/api";
import reportApi from "../../../Services/reportApi";
import { userAuditLoginData } from "../../../Services/authFunctions";
import {
  getTenantLanguage,
  getUserInfo,
  getUserAuthorization,
} from "../../../Services/userServices";
import About from "../../../components/About";
import Admin from "../../../components/Admin";
import { ModalPopUp, Spinner } from "../../../components/Common";
import CreateProject from "../../../components/CreateProject";
import Dashboard from "../../../components/Dashboard/Dashboard";
import Documents from "../../../components/Documents";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ProjectDetail from "../../../components/ProjectDetail";
import ProjectList from "../../../components/ProjectList/index";
import QuickSightDashboard from "../../../components/QuickSightDashboard";
import AnalyticReports from "../../../components/AnalyticReports";
import { ReportProvider } from "../../../components/AnalyticReports/context/ReportContext";
import Reports from "../../../components/Reports";
import Sidebar from "../../../components/Sidebar";
import Userprofile from "../../../components/Userprofile/Userprofile";
import i18n from "../../../locale/i18n";
import { authActions } from "../../../store/actions/authAction";
import { isAnalyticsReportPermission } from "../../../components/Utility/userPermission";
import { AuthorizationError } from "../../../components/Authorization/AuthorizationError";
import "./Main.css";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenantId: null,
      tenantName: "",
      isOpsLogin: false,
      isShowPopup: false,
      accountList: [],
      defaultLanguage: 1,
      userInfo: {},
      permission: [],
      languagelist: [],
      isSameTenant: false,
      serverError: false,
      errorMessage: "",
      noTenant: false,
    };
    this.timer = 1000 * 60 * 180;
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      window.document.addEventListener(this.events[i], this.resetTimeout);
    }
    this.setTimeout();
  }
  clearTimeout() {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    // console.log("set timer", this.timer);
    this.logoutTimeout = setTimeout(this.logout, this.timer);
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  logout() {
    this.props.logOut();
    this.setState({
      isOpsLogin: false,
    });
  }

  destroy() {
    this.clearTimeout();
    for (var i in this.events) {
      window.document.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.tenantId !== nextState.tenantId) {
      return false;
    }
    return true;
  }
  onHandleChange(e) {
    if (this.state.tenantId !== e.value) {
      this.setState({
        tenantId: e.value,
        tenantName: e.label,
      });
      if (parseInt(localStorage.getItem("operatingTenant")) === e.value) {
        this.setState({
          isSameTenant: true,
        });
      } else {
        this.setState({
          isSameTenant: false,
        });
      }
    }
  }
  setTanetInHeader() {
    getUserAuthorization().then((resAuthorization) => {
      const isAuthorization =
        resAuthorization?.data?.authorization?.application;
      if (isAuthorization === undefined) {
        this.props.setAuthorization(false);
        if (this.state.tenantId === null) {
          let defaultSelect = this.state.accountList.find(
            (el) => el.isPrimaryTenant === true
          );
          let tenantId = localStorage.getItem("operatingTenant");
          if (
            tenantId !== null &&
            parseInt(tenantId) !== defaultSelect.tenantId
          ) {
            localStorage.removeItem("projectFilters");
          }
          api.defaults.headers.common["operatingTenant"] =
            defaultSelect.tenantId;
          reportApi.defaults.headers.common["operatingTenant"] =
            defaultSelect.tenantId;
          localStorage.setItem("operatingTenant", defaultSelect.tenantId);
          sessionStorage.setItem("operatingTenant", defaultSelect.tenantId);
          localStorage.setItem("operatingTenantName", defaultSelect.tenantName);
          this.props.history.push("/main/dashboard");
          this.setState({ isOpsLogin: false, isShowPopup: false });
        } else {
          if (this.state.isSameTenant === false) {
            this.props.history.push("/main/dashboard");
            this.setState({ isOpsLogin: false, isShowPopup: false });
            localStorage.removeItem("projectFilters");
          }
          api.defaults.headers.common["operatingTenant"] = this.state.tenantId;
          reportApi.defaults.headers.common["operatingTenant"] =
            this.state.tenantId;
          localStorage.setItem("operatingTenant", this.state.tenantId);
          sessionStorage.setItem("operatingTenant", this.state.tenantId);
          localStorage.setItem("operatingTenantName", this.state.tenantName);
        }

        userAuditLoginData()
          .then((res) => {})
          .catch((err) => {
            // console.log("error", err);
          });

        getUserInfo()
          .then((res) => {
            //console.log("getUserInfo Main ")
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            sessionStorage.setItem("operatingTenant", res.data.operatingTenant);
            var currentUser = res.data;
            let permission = [];
            res.data.rolePermission.permissions.forEach((option) => {
              permission = [...permission, option.permission];
            });
            this.setState({
              userInfo: currentUser,
              permission: permission,
              isOpsLogin: false,
              isShowPopup: false,
            });
            this.props.setHeaderLogo(res.data.tenantLogo);
          })
          .catch((err) => {
            // console.log("catch err", err);
            this.setState({
              serverError: true,
              errorMessage: err?.response?.data?.message,
            });
          });
      } else {
        console.log("Authorization setTanetInHeader true");
        this.props.setAuthorization(true);
      }
    });
  }

  setApiLanguage = (languagelist) => {
    var defaultLanguage = i18n.language;
    var newLanguage;
    languagelist !== undefined &&
      languagelist.map(function (lang) {
        if (lang.shortCode == defaultLanguage) {
          newLanguage = lang.id;
        }
      });
    return newLanguage;
  };

  updateUserInfo = () => {
    getUserAuthorization().then((resAuthorization) => {
      const isAuthorization =
        resAuthorization?.data?.authorization?.application;
      if (isAuthorization === undefined) {
        getUserInfo()
          .then((res) => {
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            var currentUser = res.data;
            let permission = [];
            res.data.rolePermission.permissions.forEach((option) => {
              permission = [...permission, option.permission];
            });
            if (currentUser !== null && currentUser.tenantId !== undefined) {
            }
            if (currentUser !== null && currentUser.tenant !== undefined) {
              api
                .get("user-service/v1/tenant/tenants")
                .then((res) => {
                  this.setState({
                    accountList: res.data,
                    userInfo: currentUser,
                    permission: permission,
                  });
                  if (res.data.length >= 2) {
                    this.setState({
                      isOpsLogin: true,
                      isShowPopup: true,
                    });
                  } else if (res.data.length == 1) {
                    api.defaults.headers.common["operatingTenant"] =
                      res.data[0].tenantId;
                    reportApi.defaults.headers.common["operatingTenant"] =
                      res.data[0].tenantId;
                    localStorage.setItem(
                      "operatingTenant",
                      res.data[0].tenantId
                    );
                    sessionStorage.setItem(
                      "operatingTenant",
                      res.data[0].tenantId
                    );
                    // getUserInfo();
                  } else {
                    this.setState(
                      {
                        noTenant: true,
                      },
                      () => {
                        setTimeout(() => {
                          localStorage.clear();
                          sessionStorage.clear();
                          window.location.href = "/login";
                        }, 1000);
                      }
                    );
                    // console.log("Not assigned to any Tenant");
                  }
                })
                .catch((err) => {
                  console.log("catch err", err);
                  this.setState({
                    permission: permission,
                    userInfo: currentUser,
                  });
                });
            } else {
              this.setState({ userInfo: currentUser, permission: permission });
            }
          })
          .catch((err) => {
            // console.log("catch err", err.response?.data?.message);
          });
      } else {
        this.props.setAuthorization(true);
      }
    });
  };

  componentDidMount() {
    this.props.setAuthorization(false);
    this.updateUserInfo();
    getTenantLanguage()
      .then((res) => {
        const currentlang = this.setApiLanguage(res.data);
        this.setState({
          languagelist: res.data,
        });
        api.defaults.headers.common["langId"] = parseInt(currentlang);
        api.defaults.headers.common["language"] = i18n.language;
        localStorage.setItem("langid", i18n.language);
        localStorage.setItem("languageId", parseInt(currentlang));
      })
      .catch((err) => {
        console.log("getTenantLanguage err", err);
      });
  }

  callcenterRoute = () => {
    const permission = this.state.permission;
    this.timer =
      permission !== undefined &&
      (permission.indexOf("tenant.read") >= 0 ||
        permission.indexOf("callcenter.read")) >= 0
        ? 1000 * 60 * 20
        : 1000 * 60 * 60 * 8;
    let redirectLink =
      permission !== undefined &&
      (permission.indexOf("tenant.read") >= 0 ||
        permission.indexOf("callcenter.read")) >= 0 ? (
        <Redirect from="/main" to="/main/interviews" />
      ) : (
        <Redirect from="/main" to="/main/interviews" />
      );
    return (
      <Switch>
        {/* <Route exact path="/main/interviews" component={InterviewList} /> */}
        <Route exact path="/main/admin/callcenters" component={Admin} />
        <Route exact path="/main/admin/:id/agents" component={Admin} />

        <Route
          exact
          path="/main/userprofile"
          render={(props) => (
            <Userprofile {...props} userprofiledata={this.state} />
          )}
        />
        <Route
          exact
          path="/main/about"
          render={(props) => <About {...props} />}
        />
        <Redirect from="/main" to="/main/interviews" />
        {redirectLink}
      </Switch>
    );
  };

  projectRoute = () => {
    const permission = this.state.permission;

    return (
      <Switch>
        <Route exact path="/main/dashboard" component={Dashboard} />
        <Route
          exact
          path="/main/userprofile"
          render={(props) => (
            <Userprofile {...props} userprofiledata={this.state} />
          )}
        />
        <Route
          exact
          path="/main/about"
          render={(props) => <About {...props} />}
        />
        <Route
          exact
          path="/main/project"
          render={(props) => (
            <ProjectList {...props} mainstatedata={this.state} />
          )}
        />
        <Route exact path="/main/admin/tenant" component={Admin} />
        <Route exact path="/main/admin/users" component={Admin} />
        <Route exact path="/main/admin/roles" component={Admin} />
        <Route exact path="/main/admin/permissions" component={Admin} />
        <Route exact path="/main/admin/orderdetails" component={Admin} />
        <Route exact path="/main/admin/resultconfiguration" component={Admin} />
        <Route exact path="/main/admin/lookups" component={Admin} />
        <Route exact path="/main/admin/callcenters" component={Admin} />
        <Route exact path="/main/admin/Secrets" component={Admin} />
        <Route exact path="/main/admin/:id/agents" component={Admin} />
        <Route exact path="/main/admin/clientapp" component={Admin} />
        <Route path="/main/reports" component={withRouter(Reports)} />
        <Route
          path="/main/analytics-dashboard"
          component={QuickSightDashboard}
        />
        {isAnalyticsReportPermission() && (
          <Route
            path="/main/analytic-reports"
            render={(props) => (
              <ReportProvider>
                <AnalyticReports {...props} mainstatedata={this.state} />
              </ReportProvider>
            )}
          />
        )}

        <Route path="/main/documents" component={Documents} />
        <Route
          exact
          path="/main/project/createproject"
          component={CreateProject}
        />
        <Route path="/main/project/:id" component={withRouter(ProjectDetail)} />
        <Redirect from="/main" to="/main/dashboard" />
      </Switch>
    );
  };
  commonRoute = () => {
    return (
      <Switch>
        <Route exact path="/main/dashboard" component={Dashboard} />
        <Route
          exact
          path="/main/userprofile"
          render={(props) => (
            <Userprofile {...props} userprofiledata={this.state} />
          )}
        />
        <Route
          exact
          path="/main/about"
          render={(props) => <About {...props} />}
        />
        <Route
          exact
          path="/main/project"
          render={(props) => (
            <ProjectList {...props} mainstatedata={this.state} />
          )}
        />
        <Route
          exact
          path="/main/project/createproject"
          component={CreateProject}
        />
        <Route path="/main/project/:id" component={withRouter(ProjectDetail)} />
        <Route exact path="/main/admin/tenant" component={Admin} />
        <Route exact path="/main/admin/users" component={Admin} />
        <Route exact path="/main/admin/roles" component={Admin} />
        <Route exact path="/main/admin/permissions" component={Admin} />
        <Route exact path="/main/admin/orderdetails" component={Admin} />
        <Route exact path="/main/admin/lookups" component={Admin} />
        <Route exact path="/main/admin/callcenters" component={Admin} />
        <Route exact path="/main/admin/Secrets" component={Admin} />
        <Route exact path="/main/admin/:id/agents" component={Admin} />
        <Route exact path="/main/admin/clientapp" component={Admin} />
        <Redirect from="/main" to="/main/dashboard" />
      </Switch>
    );
  };

  getCustomRoute = () => {
    let permission = this.state.permission;
    let case1 =
      permission !== undefined &&
      permission.indexOf("interview.review") >= 0 &&
      permission.indexOf("tenant.project.detail.read") < 0;
    let case2 =
      permission !== undefined &&
      permission.indexOf("tenant.project.detail.read") >= 0 &&
      permission.indexOf("interview.review") < 0;
    let case3 =
      permission !== undefined &&
      permission.indexOf("tenant.project.detail.read") >= 0 &&
      permission.indexOf("interview.review") >= 0;
    if (case1) {
      return this.callcenterRoute();
    }
    if (case2) {
      return this.projectRoute();
    }
    if (case3) {
      return this.commonRoute();
    }
  };

  render() {
    const permission = this.state.permission;
    let defaultSelect = this.state.accountList.find(
      (el) => el.isPrimaryTenant === true
    );
    const accountList = [...this.state.accountList];
    const sortedAccountList = accountList.sort((a, b) =>
      a.tenantName.localeCompare(b.tenantName)
    );
    const PopUp = () => (
      <ModalPopUp
        show={this.state.isShowPopup}
        title={"Client Access Control"}
        onHide={() => this.setTanetInHeader()}
      >
        <div className="row justify-content-md-center">
          <div className="col-md-5 ">
            <Select
              name={`tenantId`}
              options={sortedAccountList.map((option) => {
                return {
                  value: option.tenantId,
                  label: option.tenantName,
                };
              })}
              className="col-md-12"
              classNamePrefix="select"
              menuPlacement="auto"
              onChange={(e) => this.onHandleChange(e)}
              menuPosition="fixed"
              // menuIsOpen={true}
              isSearchable={true}
              defaultValue={{
                label: defaultSelect.tenantName,
                value: defaultSelect.tenantId,
              }}
            />
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="d-flex mt-4">
            <button
              className="btn btn-primary m-2"
              onClick={() => this.setTanetInHeader()}
            >
              SELECT
            </button>
            <button
              className="btn btn-secondary m-2"
              onClick={() => this.setTanetInHeader()}
            >
              CANCEL
            </button>
          </div>
        </div>
      </ModalPopUp>
    );

    return (
      <>
        {this.props && this.props?.isAuthorization ? (
          <AuthorizationError />
        ) : (
          <>
            {this.state.isOpsLogin && this.state.isShowPopup ? (
              <PopUp />
            ) : (
              <div
                className={`clearfix ${
                  this.props.themeColor == "light"
                    ? "light_theme"
                    : "dark_theme"
                }`}
              >
                <div id="topbar" style={{ background: "#fff" }}>
                  <Header
                    userInfo={this.state.userInfo}
                    permission={this.state.permisssion}
                    languageList={this.state.languagelist}
                  />
                </div>
                <div className="content-wrapper">
                  <Sidebar
                    userInfo={this.state.userInfo}
                    permission={this.state.permisssion}
                  />
                  <div className="main-panel">
                    <div className="clearfix container-fluid">
                      <div className="content">{this.getCustomRoute()}</div>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
                <Footer />
              </div>
            )}
            {this.state.noTenant ? (
              <div className="no-tenants">
                <p>No Tenant Assigned for this user </p>
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loggedIn: state.authentication.loggedIn,
    themeColor: state.theme.themeColor,
    isAuthorization: state.authentication.isAuthorization,
  };
};
const mapDispatchToProps = {
  logOut: authActions.logOut,
  setHeaderLogo: authActions.setHeaderLogo,
  setAuthorization: authActions.setAuthorization,
};
const ConnectedMain = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Main)
);

const EnhancedMain = withAuthenticationRequired(ConnectedMain, {
  onRedirecting: () => (
    <div className="main-loader">
      <Spinner />
    </div>
  ),
});

export default EnhancedMain;
