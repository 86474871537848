import React from "react";
import EastIcon from "@mui/icons-material/East";

const ArrowIndicator = () => {
  const arrowColor = "#0169D9";

  return (
    <div className="arrow-icon">
      <EastIcon sx={{ fontSize: "22px", color: arrowColor }} />
    </div>
  );
};

export default ArrowIndicator;
