import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Grid2 as Grid } from "@mui/material";
import { Alert as AlertDismissible, Button } from "../../components/Common";
import displayTImeZoneOption from "../../config/timezone.json";
import FormikControl from "../../FormikControl";
import { updateUserprofile } from "../../Services/userServices";
import { FileUpload } from "../Utility/fileUpload";
import PreventLeaveRoute from "../Utility/PreventLeaveRoute.js";
import "./userProfile.css";

class Userprofile extends React.Component {
  constructor() {
    super();
    this.state = {
      alertMsg: "",
      alertStatus: false,
      variant: "",
      dirty: false,
      currentUserstate: JSON.parse(localStorage.getItem("userInfo")),
    };
  }

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };
  dirtyFlagChanged = (dirty) => {
    if (dirty != this.state.dirty) {
      console.log("dirty");
      this.setState(
        {
          dirty: dirty,
        },
        () => {
          console.log(this.state);
        }
      );
    }
  };

  handleUpdateUserprofile = (fields, submitProps) => {
    console.log("fields", fields);
    const filterField = { ...fields };
    delete filterField.checkEmailStatus;
    console.log("filterField", filterField);
    let form = new FormData();
    let notificationToAppend = {
      resultAlert: filterField.resultAlert,
      time:
        filterField.resultAlert === "OFF" ? "" : filterField.time.toString(),
      displayTimezone:
        filterField.resultAlert === "OFF"
          ? ""
          : filterField.displayTimezone.label,
      usedTimezone:
        filterField.resultAlert === "OFF"
          ? ""
          : filterField.displayTimezone.offset,
      pendingResultsNotification:
        filterField.resultAlert === "OFF"
          ? false
          : filterField.pendingResultsNotification === "ON",
    };
    form.append("firstName", filterField.firstName);
    form.append("lastName", filterField.lastName);
    form.append("phoneNumber", filterField.phoneNumber);
    form.append("notificationPreference", JSON.stringify(notificationToAppend));
    if (filterField.image != null && filterField.logoUpdated) {
      form.append("userImage", filterField.image);
    }
    updateUserprofile(form)
      .then((res) => {
        // console.log("res.data", res.data);
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        this.setState({
          alertMsg: `Profile Updated Successfully`,
          variant: "success",
          alertStatus: true,
          currentUserstate: res.data,
        });
        submitProps.setSubmitting(false);
      })
      .catch((err) => {
        let errMsg = Object.assign({}, err).response.data.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
          dirty: false,
        });
        submitProps.setSubmitting(false);
      });
  };

  blobToFile = (theBlob) => {
    const src = "data:image/png;base64," + theBlob;
    // return new File([src], "logo");
    return src;
  };

  getTimeZone = (currentUser) => {
    let displayTimezone = null;
    if (currentUser.notificationPreference) {
      displayTImeZoneOption.filter((timzone) => {
        if (
          timzone.label === currentUser.notificationPreference.displayTimezone
        ) {
          displayTimezone = timzone;
        }
      });
    }
    return displayTimezone;
  };

  render() {
    const { t } = this.props;
    let currentUser = this.state.currentUserstate;
    console.log("Current User", currentUser);
    const phoneRegExp =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

    const checkboxOptions = [
      { label: "8 am", value: "08:00" },
      { label: "12 pm", value: "12:00" },
      { label: "4 pm", value: "16:00" },
    ];

    return (
      <div className="w-1200">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <h6 className="page-title">{t("User Profile")}</h6>
              </div>
            </div>
          </div>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            primaryUserEmail: currentUser.email,
            phoneNumber: currentUser.phoneNumber || "",
            status: currentUser.isActive,
            tenantProfileImage: this.blobToFile(currentUser.image),
            image: currentUser.image,
            logoUpdated: false,
            displayTimezone: this.getTimeZone(currentUser),
            checkEmailStatus:
              currentUser.notificationPreference?.resultAlert === "OFF"
                ? false
                : true,
            pendingResultsNotification: currentUser.notificationPreference
              ?.pendingResultsNotification
              ? "ON"
              : "OFF",
            time:
              currentUser.notificationPreference?.time?.length > 0
                ? currentUser.notificationPreference.time.split(",")
                : [],
            resultAlert: currentUser.notificationPreference
              ? currentUser.notificationPreference.resultAlert
              : "PERIODIC",
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required(t("First Name is required")),
            lastName: Yup.string().required(t("Last Name is required")),
            primaryUserEmail: Yup.string()
              .email(t("Primary User Email is invalid"))
              .required(t("Primary User Email is invalid")),
            phoneNumber: Yup.string().matches(
              phoneRegExp,
              t("Phone number is not valid")
            ),
            checkEmailStatus: Yup.boolean(),
            time: Yup.array().when("checkEmailStatus", {
              is: true,
              then: () =>
                Yup.array()
                  .min(1, "Please checked at least one checked box")
                  .required(t("Please checked at least one checked box")),
            }),
            displayTimezone: Yup.object().when("checkEmailStatus", {
              is: true,
              then: () =>
                Yup.object().required(t("Please select time zone")).nullable(),
              otherwise: () => Yup.object().nullable(),
            }),
          })}
          onSubmit={(fields, submitProps) => {
            this.handleUpdateUserprofile(fields, submitProps);
          }}
          render={(props) => (
            <Form onChange={this.dirtyFlagChanged(props.dirty)}>
              <PreventLeaveRoute
                reset={props.handleReset}
                when={props.dirty}
                // Navigate function
                navigate={(field, historyObj) => {
                  if (field == "/main/userprofile") {
                    // this.cancelTenantClick();
                  } else {
                    historyObj.push(field);
                  }
                }}
                // Use as "message" prop of Prompt of React-Router
                shouldBlockNavigation={(location) => {
                  if (location.pathname === "/") {
                    return false;
                  }
                  if (props.dirty || this.state.dirty) {
                    return true;
                  }
                  return false;
                }}
              />
              <Grid container>
                <Grid size={12}>
                  <AlertDismissible
                    msg={this.state.alertMsg}
                    variantType={this.state.variant}
                    show={this.state.alertStatus}
                    close={this.handleAlertClose}
                  ></AlertDismissible>
                </Grid>
              </Grid>
              <div className="card">
                <div className="card-body">
                  <Grid container className="ad-tenant-row">
                    <Grid className="add-tenant-col" size={5}>
                      <div className="form-group">
                        <label className="required" htmlFor="firstName">
                          {t("FIRST NAME")}
                        </label>
                        <Field
                          name="firstName"
                          type="text"
                          className={
                            "form-control" +
                            (props.errors.firstName && props.touched.firstName
                              ? "is-invalid"
                              : " add-tenant-input")
                          }
                        />
                        {props.errors.firstName && props.touched.firstName && (
                          <div className="input-feedback add-tenant-validation">
                            {props.errors.firstName}
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid className="add-tenant-col" size={5}>
                      <div className="form-group">
                        <label className="required" htmlFor="lastName">
                          {t("LAST NAME")}
                        </label>
                        <Field
                          name="lastName"
                          type="text"
                          className={
                            "form-control" +
                            (props.errors.lastName && props.touched.lastName
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                        {props.errors.lastName && props.touched.lastName && (
                          <div className="input-feedback add-tenant-validation">
                            {props.errors.lastName}
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid className="add-tenant-col" size={5}>
                      <div className="form-group">
                        <label className="required" htmlFor="primaryUserEmail">
                          {t("PRIMARY USER EMAIL")}
                        </label>
                        <Field
                          name="primaryUserEmail"
                          type="text"
                          disabled
                          className={
                            "form-control" +
                            (props.errors.primaryUserEmail &&
                            props.touched.primaryUserEmail
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                        {props.errors.primaryUserEmail &&
                          props.touched.primaryUserEmail && (
                            <div className="input-feedback add-tenant-validation">
                              {props.errors.primaryUserEmail}
                            </div>
                          )}
                      </div>
                    </Grid>
                    <Grid className="add-tenant-col" size={5}>
                      {" "}
                      <div className="form-group">
                        <label htmlFor="phoneNumber">
                          {t("PRIMARY USER PHONE")}
                        </label>
                        <Field
                          name="phoneNumber"
                          type="text"
                          className={
                            "form-control" +
                            (props.errors.phoneNumber &&
                            props.touched.phoneNumber
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                        {props.errors.phoneNumber &&
                          props.touched.phoneNumber && (
                            <div className="input-feedback add-tenant-validation">
                              {t("Invalid phone number")}
                            </div>
                          )}
                      </div>
                    </Grid>
                    <Grid className="add-tenant-col" size={5}>
                      <div className="form-group">
                        <label htmlFor="file">
                          {t("UPLOAD PHOTO")} (
                          <small className="">
                            {t("maximum file size")}: 1 MB - Appx. 200px X
                            200px)
                          </small>
                        </label>
                        <input
                          id="file"
                          name="file"
                          type="file"
                          onChange={(event) => {
                            if (event.currentTarget.files[0]) {
                              props.setFieldValue("logoUpdated", true);
                              props.setFieldValue(
                                "image",
                                event.currentTarget.files[0]
                              );
                            }
                          }}
                          className="form-control"
                        />
                        {props.values.logoUpdated ? (
                          <FileUpload
                            file={props.values.image}
                            imageValue={this.blobToFile(currentUser.image)}
                          />
                        ) : (
                          currentUser.image != null && (
                            <img
                              src={this.blobToFile(currentUser.image)}
                              alt={this.blobToFile(currentUser.image)}
                              className="img-thumbnail mt-2"
                              height={200}
                              width={200}
                            />
                          )
                        )}
                      </div>
                    </Grid>
                    <Grid size={5}></Grid>
                    <Grid className="add-tenant-col" size={7}>
                      <div className="form-group email-notify ">
                        <h5 className="email_notify_text">
                          {t("Email Notifications")}
                        </h5>
                        <div id="my-radio-group">
                          {t(
                            "Send me emails to notify of call logs and results upload"
                          )}
                        </div>
                        <Grid
                          container
                          role="group"
                          aria-labelledby="my-radio-group"
                          className="d-flex"
                        >
                          <Grid size={3}>
                            <label className="form-check-label">
                              <Field
                                type="radio"
                                name="resultAlert"
                                value="PERIODIC"
                                onChange={() => {
                                  props.setFieldValue(
                                    "resultAlert",
                                    "PERIODIC"
                                  );
                                  props.setFieldValue("checkEmailStatus", true);
                                }}
                                checked={props.values.resultAlert == "PERIODIC"}
                              />
                              {t("On")}
                              <span className="radiodescription">
                                {t("Send emails")}
                              </span>
                            </label>
                          </Grid>
                          <Grid size={3}>
                            <label className="form-check-label">
                              <Field
                                type="radio"
                                name="resultAlert"
                                value={props.values.resultAlert}
                                onChange={() => {
                                  props.setFieldValue("resultAlert", "OFF");
                                  props.setFieldValue(
                                    "checkEmailStatus",
                                    false
                                  );
                                }}
                                checked={props.values.resultAlert == "OFF"}
                              />
                              {t("Off")}
                              <span className="radiodescription">
                                {t("Don't send emails")}
                              </span>
                            </label>
                          </Grid>
                        </Grid>
                        {props.values.resultAlert == "PERIODIC" && (
                          <>
                            <Grid container>
                              <Grid size={6}>
                                <div className="form-group">
                                  <FormikControl
                                    control="checkboxWithLabel"
                                    label={t("Select Time")}
                                    name="time"
                                    formik={props}
                                    options={checkboxOptions}
                                    containerStyle="checkbox-container"
                                    labelStyle="checkbox-label"
                                    fieldContainerStyle="checkbox-field-container"
                                    fieldStyle="checkbox-field"
                                  />
                                </div>
                              </Grid>
                              <Grid size={7}>
                                <div className="form-group">
                                  <FormikControl
                                    control="selectWithLabel"
                                    label={t("Select TIme Zone")}
                                    name="displayTimezone"
                                    options={displayTImeZoneOption}
                                    containerStyle="selectbox-container"
                                    labelStyle="selectbox-container-label"
                                    fieldStyle="selectbox-field-container"
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container className="d-flex flex-column">
                              <div className="d-flex flex-row result-notification-container">
                                <label>
                                  {t("Pending Results Notification")}
                                </label>
                              </div>
                              <div className="d-flex align-item-center">
                                <div className="col-lg-3">
                                  <label className="form-check-label">
                                    <Field
                                      type="radio"
                                      name="pendingResultsNotification"
                                      onChange={() => {
                                        props.setFieldValue(
                                          "pendingResultsNotification",
                                          "ON"
                                        );
                                      }}
                                      checked={
                                        props.values
                                          .pendingResultsNotification === "ON"
                                      }
                                    />
                                    {t("On")}
                                    <span className="radiodescription">
                                      {t("Send emails")}
                                    </span>
                                  </label>
                                </div>
                                <div className="col-lg-3">
                                  <label className="form-check-label">
                                    <Field
                                      type="radio"
                                      name="pendingResultsNotification"
                                      onChange={() => {
                                        props.setFieldValue(
                                          "pendingResultsNotification",
                                          "OFF"
                                        );
                                      }}
                                      checked={
                                        props.values
                                          .pendingResultsNotification === "OFF"
                                      }
                                    />
                                    {t("Off")}
                                    <span className="radiodescription">
                                      {t("Don't send emails")}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </Grid>
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid size={12}>
                      <div className="form-group float-right">
                        <Button
                          variant="primary"
                          type="submit"
                          className="rounded-0 mr-4 save-btn"
                          disabled={props.isSubmitting}
                        >
                          {props.isSubmitting ? t("Save...") : t("Save")}
                        </Button>
                        <Link
                          variant="secondary"
                          type="button"
                          className="rounded-0 cancel-btn btn btn-secondary"
                          to="/main/dashboard"
                        >
                          {t("Cancel")}
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Form>
          )}
        />
      </div>
    );
  }
}
export default withTranslation()(Userprofile);
