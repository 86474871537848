import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import momentTz from "moment-timezone";
import { Grid2 as Grid, Popover, IconButton, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Date_Format } from "../../config/index.js";

export const ReportListDetail = ({
  isOpen,
  data,
  anchorEl,
  handleClose,
  anchorPosition,
}) => {
  const { t } = useTranslation();
  const { report_name, attribute_list, end_date, start_date, time_zone } = data;
  const id = isOpen ? "simple-popover" : undefined;
  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      className="custom-popover"
    >
      <div className="popover-container">
        <Grid container className="pp-header-row">
          <Grid size={10}>
            <div className="pp-header">
              <div className="pp-title dark-light-text-color">
                {t("REPORT_INFO")}
              </div>
              <div className="pp-report-name dark-light-text-color">
                {report_name}
              </div>
            </div>
          </Grid>
          <Grid size={2}>
            <div className="pp-close">
              <IconButton
                aria-label="view"
                color="secondry"
                size="medium"
                onClick={() => handleClose()}
                className="view-detail-close-icon dark-light-text-color"
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid size={12}>
            <div className="pp-content">
              <div className="d-flex date-range-section-popover">
                <div className="label ">
                  <strong className="dark-light-text-color">
                    {t("DATA_RANGE")}:&nbsp;
                  </strong>
                </div>
                <div className="dark-light-text-color">
                  {moment(start_date).tz(time_zone).format(`${Date_Format}`)}
                  &nbsp;to&nbsp;
                  {moment(end_date).tz(time_zone).format(`${Date_Format}`)}
                </div>
              </div>
              <div className="d-flex attributes-section dark-light-text-color">
                <div>
                  <strong>{t("ATTRIBUTES")}</strong>
                </div>
              </div>
              <div className="d-flex attributes-section-list">
                {attribute_list &&
                  attribute_list.length > 0 &&
                  attribute_list.map((value, index) => {
                    return (
                      <div
                        className="attribute-list-tag"
                        key={`attribute_list_${index}`}
                      >
                        {value.name}
                      </div>
                    );
                  })}
                {attribute_list && attribute_list.length === 0 && (
                  <span className="not-available">{t("NOT_AVAILABLE")}</span>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Popover>
  );
};
