import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Grid2 as Grid } from "@mui/material";
import { Spinner } from "../components/Common";
import { resetPassword } from "../Services/authFunctions";
import ClearSpeedLogo from "../assets/images/ClearspeedLogo.png";
import ClearSpeedLogoWhite from "../assets/images/ClearspeedLogoWhite.png";

const ResetPassword = (props) => {
  const [successMsg, setSuccessMsg] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  // default input value
  const user = {
    emailAdd: "",
  };

  // set validation rules for login form feilds
  const validationRule = Yup.object().shape({
    emailAdd: Yup.string()
      .email("Email should be valid")
      .required("Email is required"),
  });

  // login form submit callback
  const submit = (values, setSubmitting, props, setSuccessMsg) => {
    setLoadingButton(true);
    setErrorMsg("");
    resetPassword(values)
      .then((res) => {
        if (res.status === 200) {
          setSuccessMsg(res.data);
          setLoadingButton(false);
          setSubmitting(false);
          setTimeout(() => {
            setSuccessMsg("");
            props.history.push("/");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log("error", err.response?.data?.message);
        setErrorMsg(
          err.response?.data?.message ||
            err.message ||
            "Something went wrong.Please contact clearspeed"
        );
        setLoadingButton(false);
        setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      <div className="main-wrapper">
        <div className="logo-img">
          <img
            className="img-fluid"
            src={
              props.themeColor === "light"
                ? ClearSpeedLogo
                : ClearSpeedLogoWhite
            }
          />
        </div>
        <div className="wrapper-content">
          <div className="d-flex position-relative">
            <div className="upper-check-box d-none">
              <img src={"assets/icon/icon_cover_flow_sm.png"} />
            </div>
            <div className="left">
              <div>
                <h4 className="form-title">Reset Password</h4>
                <Formik
                  initialValues={user}
                  onSubmit={(values, { setSubmitting }) => {
                    submit(values, setSubmitting, props, setSuccessMsg);
                  }}
                  validationSchema={validationRule}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    } = props;
                    return (
                      <React.Fragment>
                        <form onSubmit={handleSubmit} autoComplete="off">
                          <div className="form-group">
                            <label htmlFor="email">E-MAIL ADDRESS</label>
                            <input
                              id="email"
                              placeholder="Enter your email"
                              type="text"
                              onChange={(e) =>
                                setFieldValue(
                                  "emailAdd",
                                  e.target.value.toLowerCase()
                                )
                              }
                              onBlur={handleBlur}
                              value={values.emailAdd}
                              className={
                                errors.emailAdd && touched.emailAdd
                                  ? "form-control error"
                                  : "form-control"
                              }
                              autoComplete="off"
                              name="emailAdd"
                            />
                            {errors.emailAdd && touched.emailAdd && (
                              <div className="input-feedback">
                                {errors.emailAdd}
                              </div>
                            )}
                          </div>
                          <div className="form-group mt-2">
                            <button
                              className="btn btn-primary btn-block login-btn"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {loadingButton ? (
                                <>
                                  Send...
                                  <div className="custom-loader linesLoader loader_inner_button">
                                    <Spinner color="#ffffff" size={"19px"} />
                                  </div>
                                </>
                              ) : (
                                <span>Send</span>
                              )}
                            </button>
                          </div>
                          <hr />
                        </form>

                        <div
                          className={
                            successMsg !== ""
                              ? "alert alert-success"
                              : "alert alert-success d-none"
                          }
                          role="alert"
                        >
                          {successMsg}
                        </div>
                        {errorMsg && (
                          <div className={"alert alert-danger"} role="alert">
                            <button
                              type="button"
                              className="close"
                              onClick={() => setErrorMsg("")}
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                            {errorMsg}
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }}
                </Formik>
              </div>
              <div className="clearfix"></div>
              <Grid className=" text-center mt-4">
                <Grid sm={12}>
                  <span className="bottom-link">
                    Back To <Link to="/"> Login </Link>
                  </span>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    themeColor: state.theme.themeColor,
  };
};
const mapDispatchToProps = {};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
);
