import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { Field, Form, Formik } from "formik";
import { Card, CardContent, Grid2 as Grid } from "@mui/material";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as Yup from "yup";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../../../components/Common";
import { scrollTopWindow } from "../../../../Helpers/index";
import { SendEmail } from "../../../../Services/participantsService";
import {
  getCommunicationDetail,
  getProjectDetail,
  updateCommunicationDetail,
} from "../../../../Services/projectServices";
import CustomPrompt from "../../../Utility/CustomPrompt/CustomPrompt";
import { stringExistsInArray } from "../../../Utility/userPermission";

class Email extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: "",
      emailData: "",
      alertMsg: "",
      alertStatus: false,
      variant: "",
      dirty: false,
      value: "",
      loading: false,
      loadingEmailData: true,
      disablesavebtnme: false,
      initialValuesTemplateType: "TEST_INVITATION_EMAIL",
      templateTypeValue: {
        value: "TEST_INVITATION_EMAIL",
        label: "Invitation Template",
      },
      projectLanguagesData: [],
      selectedLanguage: null,
      languageId: "",
      langEditorState: "",
    };
  }

  componentDidMount() {
    const projectId = this.props.match.params.id;
    getProjectDetail(projectId)
      .then((res) => {
        this.setState(
          {
            projectLanguagesData: res.data.projectLanguagesData || [],
            languageId:
              res.data.projectLanguagesData.length > 0
                ? res.data.projectLanguagesData[0].value
                : "",
            projectId: projectId,
          },
          () => {
            const languageId = this.state.languageId;
            this.getCommunicationData(
              projectId,
              "TEST_INVITATION_EMAIL",
              languageId
            );
          }
        );
      })
      .catch((err) => {
        if (err.response.data.status === 403) {
          this.setState({
            alertMsg: `${err.response?.data?.message}`,
            alertStatus: true,
            variant: "danger",
          });
        }
      });
  }

  /**
   * This function will give you a communication detail
   * @param {string} projectId
   * @param {string} templateType
   * @param {stirng} languageId
   */
  getCommunicationData = (projectId, templateType, languageId) => {
    getCommunicationDetail(projectId, templateType, languageId)
      .then((res) => {
        console.log("getCommunicationData", res);
        const contentBlock = htmlToDraft(res.data.template_content || "");
        if (contentBlock) {
          console.log("contentBlock", contentBlock);
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          console.log("contentState", contentState);
          const editorState = EditorState.createWithContent(contentState);
          console.log("editorState", editorState);
          this.setState(
            {
              editorState,
              emailData: res.data,
            },
            () => {
              const emailtext =
                editorState != undefined
                  ? draftToHtml(convertToRaw(editorState.getCurrentContent()))
                  : "";
              const replaceHtml = emailtext.replaceAll(
                "<p></p>",
                "<p>&nbsp;</p>"
              );
              const finalHtml = replaceHtml.replaceAll(
                "text-align:none",
                "text-align:center;"
              );
              this.setState({
                langEditorState: finalHtml,
                loadingEmailData: false,
              });
            }
          );
        } else {
          this.setState({
            emailData: res.data,
            loadingEmailData: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loadingEmailData: false,
          alertMsg: `${err.response?.data?.message}`,
          alertStatus: true,
          variant: "success",
        });
        console.log("error in Template Creation");
      });
  };

  /**
   * This function will send email
   * @param {string} languageId
   */
  handleSubmit = (languageId) => {
    this.setState({ loading: true });
    const projectid = this.props.match.params.id;
    SendEmail(projectid, languageId)
      .then((res) => {
        this.setState({ loading: false });
        scrollTopWindow();
        this.setState({
          alertMsg: `Email Sent Successfully`,
          alertStatus: true,
          variant: "success",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        scrollTopWindow();
        console.log("Error Occured", err);
        this.setState({
          variant: "danger",
          alertMsg: `${err?.response?.data?.message}`,
          alertStatus: true,
        });
      });
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  handleCommunicationSubmit = (fields, resetForm) => {
    this.setState({
      disablesavebtnme: true,
    });
    const projectid = this.props.match.params.id;
    // return;
    updateCommunicationDetail(fields, projectid)
      .then((res) => {
        scrollTopWindow();
        this.setState(
          {
            alertMsg: `Template Updated Successfully`,
            alertStatus: true,
            variant: "success",
            dirty: false,
            disablesavebtnme: false,
          },
          () => {
            this.getCommunicationData(
              projectid,
              fields.template_type,
              fields.language_id
            );
          }
        );
      })
      .catch((err) => {
        scrollTopWindow();
        this.setState({
          variant: "danger",
          alertMsg: `${err?.response?.data?.message}`,
          alertStatus: true,
          disablesavebtnme: false,
        });
      });
  };

  /**
   * This function fired when we select language
   * @param {object} option
   * @param {function} setFieldValue
   */

  selectTemplateType = (option, setFieldValue) => {
    setFieldValue("template_type", option.value);
    this.setState(
      {
        templateTypeValue: option,
      },
      () => {
        const id = this.props.match.params.id;
        this.getCommunicationData(
          id,
          this.state.templateTypeValue.value,
          this.state.languageId
        );
      }
    );
  };

  /**
   * This function fired when we select language
   * @param {object} option
   * @param {function} setFieldValue
   */
  handleLanguageSelection = (option, setFieldValue) => {
    setFieldValue("language_id", option.value);
    this.setState(
      {
        selectedLanguage: option,
        languageId: option.value,
      },
      () => {
        const projectId = this.props.match.params.id;
        this.getCommunicationData(
          projectId,
          this.state.templateTypeValue.value,
          this.state.languageId
        );
      }
    );
  };

  dirtyFlagChanged = (isDirty) => {
    this.props.setIsDirty(isDirty);
  };

  render() {
    let { t } = this.props;
    const { editorState, langEditorState } = this.state;
    const emailtext =
      editorState != undefined
        ? draftToHtml(convertToRaw(editorState.getCurrentContent()))
        : "";
    const replaceHtml = emailtext.replaceAll("<p></p>", "<p>&nbsp;</p>");
    const finalHtml = replaceHtml.replaceAll(
      "text-align:none",
      "text-align:center;"
    );
    const templateOption = [
      {
        id: 1,
        value: "TEST_INVITATION_EMAIL",
        label: "Invitation Template",
        status: true,
      },
    ];

    let languageOption = this.state.projectLanguagesData;

    const canUserUpdateFields = !stringExistsInArray(
      this.props.statedata.isinternal
    );

    return (
      <div className="w-1200">
        <Formik
          enableReinitialize
          initialValues={{
            language_id: this.state.languageId,
            template_name: this.state.emailData.template_name || "",
            template_type: this.state.initialValuesTemplateType,
            description: this.state.emailData.description || "",
            template_content: langEditorState || "",
            email_subject: this.state.emailData.email_subject || "",
          }}
          validationSchema={Yup.object().shape({
            template_name: Yup.string().required("Template Name is required"),
            email_subject: Yup.string().when("template_type", {
              is: "TEST_INVITATION_EMAIL",
              then: () => Yup.string().required("Email Subject is required"),
            }),
          })}
          onSubmit={(fields, { resetForm }) => {
            console.log("fields", fields);
            this.handleCommunicationSubmit(fields, resetForm);
          }}
          render={(props) => {
            console.log("props.values", props);
            return (
              <Form
                className="agent-view"
                onChange={this.dirtyFlagChanged(props.dirty)}
              >
                <Grid container>
                  <Grid item>
                    <AlertDismissible
                      msg={this.state.alertMsg}
                      variantType={this.state.variant}
                      show={this.state.alertStatus}
                      close={this.handleAlertClose}
                    ></AlertDismissible>
                  </Grid>
                </Grid>
                <CustomPrompt dirty={props.dirty} />
                <Card>
                  <CardContent>
                    {this.state.loadingEmailData ? (
                      <div className="custom-tab-loader">
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        <Grid container className="email-temaplate-row">
                          <Grid item size={1} />
                          <Grid item className="email-temaplate-col" size={10}>
                            <div className="form-group row">
                              <label
                                className="col-sm-3 col-form-label"
                                htmlFor="language_id"
                              >
                                {t("language")}
                              </label>
                              <div className="col-sm-9">
                                <Select
                                  name="language_id"
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  options={languageOption}
                                  isDisabled={canUserUpdateFields}
                                  value={
                                    props.values.language_id
                                      ? languageOption.find(
                                          (option) =>
                                            option.value ===
                                            props.values.language_id
                                        )
                                      : null
                                  }
                                  onChange={(option) =>
                                    this.handleLanguageSelection(
                                      option,
                                      props.setFieldValue
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                className="col-sm-3 col-form-label"
                                htmlFor="template_type"
                              >
                                {t("Template Type")}
                              </label>
                              <div className="col-sm-9">
                                <Select
                                  name="template_type"
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  isDisabled={canUserUpdateFields}
                                  options={templateOption}
                                  onChange={(option) =>
                                    this.selectTemplateType(
                                      option,
                                      props.setFieldValue
                                    )
                                  }
                                  value={this.state.templateTypeValue}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                className="col-sm-3 col-form-label required"
                                htmlFor="template_name"
                              >
                                {t("Template Name")}
                              </label>
                              <div className="col-sm-9">
                                <Field
                                  name="template_name"
                                  disabled={canUserUpdateFields}
                                  type="text"
                                  autoComplete="off"
                                  className={
                                    "form-control" +
                                    (props.errors.template_name &&
                                    props.touched.template_name
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {props.errors.template_name &&
                                  props.touched.template_name && (
                                    <div className="input-feedback add-tenant-validation">
                                      {props.errors.template_name}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                className="col-sm-3 col-form-label"
                                htmlFor="description"
                              >
                                {t("Description")}
                              </label>
                              <div className="col-sm-9">
                                <Field
                                  name="description"
                                  disabled={canUserUpdateFields}
                                  autoComplete="off"
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                className="col-sm-3 col-form-label required"
                                htmlFor="email_subject"
                              >
                                {t("Email Subject")}
                              </label>
                              <div className="col-sm-9">
                                <Field
                                  name="email_subject"
                                  disabled={canUserUpdateFields}
                                  autoComplete="off"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (props.errors.email_subject &&
                                    props.touched.email_subject
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {props.errors.email_subject &&
                                  props.touched.email_subject && (
                                    <div className="input-feedback add-tenant-validation">
                                      {props.errors.email_subject}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="helper-box">
                              <p>Edit the contents of the email below.</p>
                              <span>
                                You can use the following placeholders:{" "}
                              </span>
                              <p className="helper-box-content">
                                {this.state.emailData != "" &&
                                this.state.emailData.placeholders !== null
                                  ? this.state.emailData.placeholders.map(
                                      (placeholders) => (
                                        <span>
                                          {placeholders.placeholder},{" "}
                                        </span>
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>
                            <div className="form-group row">
                              <Editor
                                readOnly={canUserUpdateFields}
                                editorState={editorState}
                                wrapperClassName="email-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={(editorState) => {
                                  const finalTemplatestate =
                                    editorState != undefined
                                      ? draftToHtml(
                                          convertToRaw(
                                            editorState.getCurrentContent()
                                          )
                                        )
                                      : "";
                                  this.setState({
                                    editorState,
                                  });
                                  props.setFieldValue(
                                    "template_content",
                                    finalTemplatestate
                                  );
                                }}
                                handlePastedText={() => false}
                              />
                            </div>
                            <div className="form-group row">
                              <Field
                                component="textarea"
                                name="template_content"
                                className="form-control htmltemplate-view"
                                disabled
                                hidden
                              />
                            </div>
                            <div className="template-preview">
                              <h4>{t("Template Preview")}</h4>
                              <div className="template-section">
                                {
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: finalHtml,
                                    }}
                                  />
                                }
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item size={1} />
                          <Grid item size={10}>
                            <div className="form-group float-left">
                              <Button
                                variant="primary"
                                type="button"
                                className="rounded-0 mr-4 save-btn"
                                onClick={() =>
                                  this.handleSubmit(props.values.language_id)
                                }
                              >
                                {this.state.loading
                                  ? `${t("Send Test Email")}...`
                                  : t("Send Test Email")}
                              </Button>
                            </div>

                            <div className="form-group float-right">
                              <Button
                                variant="primary"
                                type="submit"
                                className="rounded-0  save-btn button_with_loader"
                                disabled={
                                  canUserUpdateFields
                                    ? canUserUpdateFields
                                    : this.state.disablesavebtnme
                                }
                              >
                                {this.state.disablesavebtnme ? (
                                  <>
                                    {t("Save")}...
                                    <div className="custom-loader linesLoader loader_inner_button">
                                      <Spinner color="#ffffff" size={"19px"} />
                                    </div>
                                  </>
                                ) : (
                                  <span>{t("Save")}</span>
                                )}
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}
export default withTranslation()(Email);
