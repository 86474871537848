import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import {
  faFilePdf,
  faPaperPlane,
  faPlay,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Grid2 as Grid } from "@mui/material";
import moment from "moment";
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  SwapVert as SwapVertIcon,
} from "@mui/icons-material";
import reviewMark from "../../../../../assets/images/reviewMark.png";
import stickyEmpty from "../../../../../assets/images/sticky-empty.png";
import stickyFull from "../../../../../assets/images/sticky-full.png";
import supportEmpty from "../../../../../assets/images/support-empty.png";
import supportFull from "../../../../../assets/images/support-full.png";
import unReviewMark from "../../../../../assets/images/unReviewMark.png";
import {
  Alert as AlertDismissible,
  ModalPopUp,
} from "../../../../../components/Common";
import { Date_Format } from "../../../../../config";
import { findTemplateRiskLevelFlag } from "../../../../../Helpers";
import {
  getInterviewRecording,
  sendEmail,
} from "../../../../../Services/executionService";
import { EnhancedTable } from "../../../../CustomTable";
import { CustomTooltip } from "../../../../Utility/CustomTooltip";
import { generateNewResultPdf } from "../../../../Utility/NewResultPdf";
import { ResultsContext } from "../../ResultsContext";
import { getInitials } from "../../ResultsUtils";
import DownloadResultPdf from "../../ResultsUtils/DownloadResultPdf";
import EditNoteMsgModal from "./EditNoteMsgModal";
import FilterLabel from "./FilterLabel";
import HandleResultRowSelection from "./HandleResultRowSelection";
import { MarkReviewModal } from "./MarkReviewModal";
import OutcomeTracking from "./OutcomeTracking";
import QuestionsModal from "./QuestionsModal";

const ResultsTable = (props) => {
  const { t } = useTranslation();

  const themeColor = useSelector((state) => state.theme.themeColor);

  const [showQuestionModal, setShowQuestionModal] = useState(false);

  const [resultDetail, setResultDetail] = useState(null);
  const { resultsState, resultsDispatch, permissionList, projectDetail } =
    useContext(ResultsContext);

  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const {
    projectId,
    enable_indivisual_result_email,
    review_unreview_configuration,
  } = projectDetail;
  const {
    showOutComeTrackingModal,
    resultNotesModalShow,
    resultCMRecordingModalShow,
    selectedRecording,
    questionList,
    currentPage,
    perPageCount,
    totalResultsCount,
    totalPagesCount,
    selectedResultList,
    configurationJson,
    customQuestionText,
    isSelectAll,
    loadingResults,
    results,
    displayTimeZoneOffset,
    selectedResultRow,
    allResultsOfProject,
    sortOrder,
    onTablePageChange,
    reRender,
    isSelectAllRow,
    preSelectedRow,
    flagsOptionsData,
    resultReviewModalShow,
  } = resultsState;

  /**
   * This function work as a setter function for state based
   * @param {string} type
   * @param {anyType} payload
   */
  const dispatch = (type, payload) => {
    resultsDispatch({ type: type, payload: payload });
  };

  const handleIrn = (configJson) => {
    if (configJson) {
      if (configJson.data.result_area.irn != "") {
        return configJson.data.result_area.irn;
      } else {
        return "IRN";
      }
    } else {
      return "IRN";
    }
  };

  const handleListName = (configJson) => {
    if (configJson) {
      if (configJson.data.participant_list.name != "") {
        return configJson.data.participant_list.name;
      } else {
        return `${t("Name")}`;
      }
    } else {
      return `${t("Name")}`;
    }
  };

  const handleMitigation = (cell, row) => {
    if (permissionList.indexOf("result.follow.up.note.read") >= 0) {
      return (
        <span
          className="mitigationIcon"
          onClick={() => openMitigationModel(row)}
        >
          {row.followedUp ? (
            <img src={stickyFull} className="matigation-image" />
          ) : (
            <img src={stickyEmpty} className="matigation-image" />
          )}
        </span>
      );
    }
    return null;
  };

  const openMitigationModel = (result) => {
    dispatch("set_result_details", result);
    dispatch("show_out_come_tracking_modal", true);
  };

  //Could use jsx in return of component, this would make following the dom easier.
  // Normally you'd break this out into a function if the state would need to be manipulated
  const handleNotes = (cell, row) => {
    return row.audioQualityControlError ? (
      <FontAwesomeIcon
        className="addmitigation"
        icon={faCommentDots}
        onClick={() => openNotesModel(row)}
      />
    ) : (
      ""
    );
  };

  const openNotesModel = (result) => {
    dispatch("set_result_details", result);
    dispatch("result_notes_modal_show", true);
  };

  const openAudioPlayerModel = async (row) => {
    await callGetRecording(row.interviewResultId);

    dispatch("result_cm_recording_modal_show", true);
  };

  const getMaskedAccessCode = (params) => {
    if (params.row.accessCode) {
      const accessCode = params.row.accessCode.toString();
      let updatedAccessCode = `${accessCode.substring(
        0,
        3
      )}-${accessCode.substring(3, 6)}-${accessCode.substring(
        6,
        accessCode.length
      )}`;
      return updatedAccessCode;
    }
  };

  const getOverallRiskLabel = (overall) => {
    switch (overall) {
      case "HR":
        return t("High_Risk_Des");
      case "R":
        return t("RED_R_DES");
      case "LR":
        return t("Low_Risk_Des");
      case "AR":
        return t("Average_Risk_Des");
      case "G":
        return t("GREEN_G_DES");
      case "PR":
        return t("Potential_Risk_Des");
      case "NC":
        return t("Non_Compliant_Participant_Des");
      case "NP":
        return t("Non_Responsive_Participant_Des");
      case "CM":
        return t("Counter_Measure_Des");
      case "AD":
        return t("Admission_Participant_Des");
      case "DC":
        return t("Disconnected_Participant_Des");
      default:
        return overall;
    }
  };

  const handleOverAllRisk = (params) => {
    const getflagOption =
      flagsOptionsData &&
      findTemplateRiskLevelFlag(
        flagsOptionsData,
        "overall",
        params.row.overallEvaluationTrans
      );
    return (
      <>
        {getflagOption ? (
          <>
            <CustomTooltip
              placement="top"
              title={getOverallRiskLabel(params.row.overallEvaluation)}
            >
              <div
                className="template_risk_level_flag_result_table"
                data-tip
                data-for={`overall_${params.row.interviewResultId}`}
              >
                <img src={getflagOption} alt="Not Found" />
              </div>
            </CustomTooltip>
          </>
        ) : (
          <>
            {params.row.overallEvaluation && (
              <CustomTooltip
                placement="top"
                title={getOverallRiskLabel(params.row.overallEvaluation)}
              >
                <div
                  data-tip
                  data-for={`overall_${params.row.interviewResultId}`}
                >
                  <FilterLabel
                    initial={getInitials(params.row.overallEvaluation)}
                    color={params.row.overallEvaluation}
                  />
                </div>
              </CustomTooltip>
            )}
          </>
        )}
        {/* {params.row.overallEvaluation && (
          <CustomTooltip
            id={`overall_${params.row.interviewResultId}`}
            className="risk_label_tooltip"
            place="top"
            effect="float"
            type={themeColor === "light" ? "dark" : "light"}
          >
            {getOverallRiskLabel(params.row.overallEvaluation)}
          </CustomTooltip>
        )} */}
      </>
    );
  };

  const filteredQuestions = (questions) => {
    const filterQuestion = questions.filter((question) => {
      return (
        question.isPertinant === true &&
        question.preInterview === false &&
        question.expectedAnswer !== "Narr"
      );
    });
    return filterQuestion;
  };

  const questionStructure = (params) => {
    const rowId = params.row.interviewResultId;
    const updatedQuestionList = filteredQuestions(questionList);
    let filteredQuestionArray = updatedQuestionList.map((question, index) => {
      return {
        result: "",
        sequence: question.questionNumber,
      };
    });

    if (params.row.interviewQuestionsResult) {
      const newItemsBySequence = params.row.interviewQuestionsResult.reduce(
        (a, item) => {
          a[item.sequence] = item;
          return a;
        },
        {}
      );
      const filteredOutput = filteredQuestionArray.map((item) => {
        return newItemsBySequence[item.sequence]
          ? newItemsBySequence[item.sequence]
          : item;
      });
      let finalHTML = filteredOutput
        .sort((a, b) => a.sequence - b.sequence)
        .map((item, index) => {
          const getflagOption =
            flagsOptionsData &&
            findTemplateRiskLevelFlag(
              flagsOptionsData,
              "questions",
              item.riskLevelTrans
            );
          if (getflagOption) {
            return (
              <>
                <CustomTooltip
                  className="risk_label_tooltip"
                  id={`question_0_${`${rowId}_${index}_${item.result}`}`}
                  placement="top"
                  title={getOverallRiskLabel(item.result)}
                  type={themeColor === "light" ? "dark" : "light"}
                >
                  <div
                    className="template_risk_level_que_flag_result_table float-left"
                    data-tip
                    data-for={`question_0_${`${rowId}_${index}_${item.result}`}`}
                  >
                    <img src={getflagOption} alt="Not Found" />
                  </div>
                </CustomTooltip>
              </>
            );
          } else {
            return (
              <div>
                <CustomTooltip
                  className="risk_label_tooltip"
                  id={`question_1_${`${rowId}_${index}_${item.result}`}`}
                  placement="top"
                  title={getOverallRiskLabel(item.result)}
                  type={themeColor === "light" ? "dark" : "light"}
                >
                  <div
                    data-tip
                    data-for={`question_1_${`${rowId}_${index}_${item.result}`}`}
                  >
                    <FilterLabel
                      key={item.id}
                      styleName="float-left"
                      initial={getInitials(item.result)}
                      color={item.result}
                    />
                  </div>
                </CustomTooltip>
              </div>
            );
          }
        });
      return <div className="d-flex align-item-center">{finalHTML}</div>;
    } else {
      return "";
    }
  };

  /**
   * This function will download pdf
   * @param {string} cell
   * @param {object} row
   * @returns
   */

  const handleDownloadPdf = (cell, row) => {
    return row.overallEvaluation !== "PD" ? (
      <FontAwesomeIcon
        key={`${row.interviewResultId}-pdf`}
        className="pdflinkdwd"
        icon={faFilePdf}
        onClick={() =>
          generateNewResultPdf(
            row,
            questionList,
            projectDetail,
            displayTimeZoneOffset
          )
        }
      />
    ) : (
      ""
    );
  };

  const handleMarkReview = (row) => {
    const markReviewAction = row.reviewed ? "Unreviewed" : "Reviewed";
    dispatch("result_review_modal_show", true);
    dispatch("mark_review_result_list", [row]);
    dispatch("mark_review_action", markReviewAction);
  };

  const closeReviewModal = () => {
    dispatch("result_review_modal_show", false);
    dispatch("mark_review_result_list", []);
    dispatch("selected_result_row", []);
  };

  /**
   * This function will handle all the actions
   * @param {string} cell
   * @param {object} row
   * @returns
   */
  const handleActions = (params) => {
    return (
      <>
        <div className="d-flex gap-16">
          {params.row.overallEvaluation !== "PD" ? (
            <>
              <DownloadResultPdf
                row={params.row}
                uniquekey={params.row.interviewResultId}
                pdfLogo={props.pdfLogo}
              />
            </>
          ) : null}

          {permissionList.indexOf("result.follow.up.note.read") >= 0 ? (
            <>
              <CustomTooltip placement="left" title="Outcome-Tracking-t">
                <div
                  className="action-event"
                  data-tip
                  //data-for={`outcome_tracking_t_${index}`}
                  onClick={() => openMitigationModel(params.row)}
                >
                  {params.row.followedUp ? (
                    <img src={stickyFull} className="matigation-image" />
                  ) : (
                    <img src={stickyEmpty} className="matigation-image" />
                  )}
                </div>
              </CustomTooltip>
            </>
          ) : null}

          {params.row.audioQualityControlError ? (
            <>
              <CustomTooltip placement="left" title="Notes-t">
                <div
                  className="action-event"
                  data-tip
                  //data-for={`notes_t_${index}`}
                  onClick={() => openNotesModel(params.row)}
                >
                  <img src={supportFull} className="support-image" />
                </div>
              </CustomTooltip>
            </>
          ) : (
            <>
              {userInfo.tenant.isSystemTenant !== null &&
              userInfo.tenant.isSystemTenant ? (
                <>
                  <CustomTooltip placement="left" title="Notes-t">
                    <div
                      className="action-event"
                      data-tip
                      //data-for={`notes_t_${index}`}
                      onClick={() => openNotesModel(params.row)}
                    >
                      <img src={supportEmpty} className="support-image" />
                    </div>
                  </CustomTooltip>
                </>
              ) : null}
            </>
          )}
          {((userInfo.tenant.isSystemTenant !== null &&
            userInfo.tenant.isSystemTenant) ||
            (permissionList.indexOf("tenant.project.cm.recording.read") >= 0 &&
              projectDetail.cm_recordings_configuration)) &&
            params.row.interviewRecordingExist && (
              <>
                <CustomTooltip placement="left" title={t("Recording-t")}>
                  <div
                    className="action-event"
                    data-tip
                    data-for={`recording_t_${params.row.interviewResultId}`}
                    onClick={() => {
                      setResultDetail(params.row);
                      openAudioPlayerModel(params.row);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlay}
                      style={{ cursor: "pointer" }}
                      color="blue"
                    />
                    <span className="showhoverbox showOnleft">
                      {t("Recording")}
                    </span>
                  </div>
                </CustomTooltip>
              </>
            )}

          {review_unreview_configuration && (
            <>
              <CustomTooltip
                placement="left"
                title={
                  params.row.reviewed
                    ? t("MARK_UNREVIEWED")
                    : t("MARK_REVIEWED")
                }
              >
                <div
                  className={`action-event ${
                    params.row.reviewed
                      ? "mark_review_result"
                      : "un_mark_review_result"
                  }`}
                  data-tip
                  data-for={`mark_item_${params.row.interviewResultId}`}
                  onClick={() => handleMarkReview(params.row)}
                >
                  <img
                    src={params.row.reviewed ? unReviewMark : reviewMark}
                    className="mark-review-icon"
                  />
                </div>
              </CustomTooltip>
            </>
          )}
        </div>
      </>
    );
  };

  /**
   * This function will show different icon based upon result is release or hold
   * @param {object} cell
   * @param {Array} row
   * @returns
   */
  const handleResultSelection = (params) => {
    if (
      permissionList.indexOf("tenant.project.resultshold.internal.update") >= 0
    ) {
      return params.row.released ? (
        <FontAwesomeIcon className="tableicon" icon={faShareSquare} />
      ) : (
        <Checkbox
          type="checkbox"
          id={params.row.interviewResultId}
          className="participant select-all-dropdown checkbox-center-align"
          name={params.row.interviewResultId}
          checked={handleCheckedStatus(params.row.interviewResultId)}
          onChange={(event) =>
            selectResult(event, params.row.interviewResultId)
          }
          label=""
        />
      );
    } else {
      return "";
    }
  };

  /**
   * This function will select  hold Result for release and delete
   * @param {object} cell
   * @param {Array} row
   * @returns
   */
  const selectResult = (event, interviewResultId) => {
    if (event.target.checked) {
      let newSelectedResult = {
        interviewResultId: interviewResultId,
        projectId: parseInt(projectId),
      };
      const updateSelectedResult = [...selectedResultList, newSelectedResult];
      dispatch("selected_result_list", updateSelectedResult);
    } else {
      const updateSelectedResult = selectedResultList.filter(
        (result) => result.interviewResultId !== interviewResultId
      );
      dispatch("selected_result_list", updateSelectedResult);
    }
  };

  /**
   * This function will select All hold Result for release and delete
   * @param {object} cell
   * @param {Array} row
   * @returns
   */
  const selectAllResult = (event) => {
    if (event.target.checked) {
      const copyResults = [...results];
      const selectedResult = copyResults
        .filter((result) => !result.released)
        .map((result) => {
          return {
            interviewResultId: result.interviewResultId,
            projectId: parseInt(projectId),
          };
        });
      dispatch("selected_result_list", selectedResult);
      dispatch("is_select_all", true);
    } else {
      dispatch("selected_result_list", []);
      dispatch("is_select_all", false);
    }
  };

  /**
   * This function will check result is selected for released and delete
   * @param {string} interviewResultId
   * @returns
   */
  const handleCheckedStatus = (interviewResultId) => {
    let isRowChecked = false;
    selectedResultList.map((result) => {
      if (result.interviewResultId === interviewResultId) {
        isRowChecked = true;
      }
    });
    return isRowChecked;
  };

  const formatDate = (params) => {
    return params.row.date
      ? moment(new Date(params.row.date)).format(Date_Format)
      : params.row.date;
  };

  const rowSelectionInput = (params) => {
    return (
      <Checkbox
        checked={isRowSelected(params.row.interviewResultId)}
        onChange={(event) => handleRowSelectionInput(event, params.row)}
        className="form-check-input"
      />
    );
  };

  const isRowSelected = (interviewResultId) => {
    let isRowChecked = false;
    selectedResultRow.map((result) => {
      if (result.interviewResultId === interviewResultId) {
        isRowChecked = true;
      }
    });
    return isRowChecked;
  };

  const handleRowSelectionInput = (event, row) => {
    if (event.target.checked) {
      const updateSelectedResultRow = [...selectedResultRow, row];
      dispatch("selected_result_row", updateSelectedResultRow);
      dispatch("mark_review_result_list", updateSelectedResultRow);
    } else {
      const updateSelectedResultRow = selectedResultRow.filter(
        (result) => result.interviewResultId !== row.interviewResultId
      );
      dispatch("selected_result_row", updateSelectedResultRow);
      dispatch("mark_review_result_list", updateSelectedResultRow);
      if (isSelectAllRow) {
        const updatedData = [...preSelectedRow, row];
        dispatch("pre_selected_row", updatedData);
      }
    }
  };

  const handleIrnClick = (params) => {
    if (projectDetail.questionaire_type === "HYBRID_QUESTIONNAIRE") {
      return (
        <a
          key={params.row.interviewReferenceNumber}
          onClick={() => {
            setResultDetail(params.row);
            setShowQuestionModal(true);
          }}
        >
          <span className="format-irn">
            {params.row.interviewReferenceNumber}
          </span>
        </a>
      );
    }

    return <span>{params.row.interviewReferenceNumber}</span>;
  };

  const styleSortIcon = (dateField) => {
    if (sortOrder[dateField]) {
      return (
        <div className="table-sorting-icon">
          {sortOrder[dateField] == "asc" ? (
            <ArrowUpwardIcon />
          ) : (
            <ArrowDownwardIcon />
          )}
        </div>
      );
    }
    return (
      <div className="table-sorting-hover-icon">
        <SwapVertIcon />
      </div>
    );
  };

  const handleSorting = (dateField) => {
    let sortObject = {
      [dateField]: sortOrder[dateField] === "asc" ? "desc" : "asc",
    };
    dispatch("set_sort_order", sortObject);
    dispatch("question_number", "");
  };

  const formatOverAllHeaderText = (dateField) => {
    return (
      <div
        className="overall-sort"
        onClick={() => handleSorting(dateField.field)}
      >
        {t("OverAll")}
        <span>{styleSortIcon(dateField.field)}</span>
      </div>
    );
  };

  const formatDateHeaderText = (dateField) => {
    return (
      <div className="overall-sort" onClick={() => handleSorting("date")}>
        {t("Date")}
        <span>{styleSortIcon("date")}</span>
      </div>
    );
  };

  const formatIrnHeaderText = () => {
    return (
      <div className="overall-sort" onClick={() => handleSorting("irnno")}>
        {handleIrn(configurationJson)}
        <span>{styleSortIcon("irnno")}</span>
      </div>
    );
  };

  const callSendEmail = (id) => {
    dispatch("loading_results", false);
    const prepareIds = {
      interviewResultsId: [id],
    };
    sendEmail(prepareIds)
      .then((response) => {
        let data = response?.data?.message;
        showError(data, "success", true);
        dispatch("set_re_render", !reRender);
      })
      .catch((error) => {
        console.log("err", error);
        let errMsg = error.response?.data?.message;
        showError(errMsg, "danger", true);
        dispatch("set_re_render", !reRender);
      });
  };

  const callGetRecording = async (id) => {
    //dispatch('loading_results', false);

    try {
      const blob = await getInterviewRecording(id);

      let url = window.URL.createObjectURL(blob);

      dispatch("select_recording", url);
    } catch (error) {
      console.log("err", error);
      let errMsg = error.response?.data?.message;
      showError(errMsg, "danger", true);
      dispatch("set_re_render", !reRender);
    }
  };

  const showError = (errMsg, variant, status) => {
    dispatch("set_alert_msg", errMsg);
    dispatch("set_variant", variant);
    dispatch("set_alert_status", status);
  };

  const handleSendEmail = (params) => {
    return params.row.released ? (
      <CustomTooltip placement="left" title="Send Email">
        <div className="hoverbox">
          <FontAwesomeIcon
            icon={faPaperPlane}
            onClick={() => callSendEmail(params.row.interviewResultId)}
            style={{ cursor: "pointer" }}
          />
        </div>
      </CustomTooltip>
    ) : (
      ""
    );
  };

  const createByCodeFormatter = (params) => {
    return (
      <>
        {params.row.created_by && (
          <div className="participant_created_by">
            <span>{params.row.created_by}</span>
          </div>
        )}
      </>
    );
  };

  const updatedQuestionList = filteredQuestions(questionList);
  const length = updatedQuestionList.length;
  const resultWidth = length > 4 ? length * 60 : 250;

  const systemTenantWidth = enable_indivisual_result_email ? 228 : 208;
  const nonSystemTenantWidth = 180;
  const defaultWidth = 200;

  const actionWidth =
    permissionList.includes("tenant.project.resultshold.internal.update") >= 0
      ? userInfo.tenant?.isSystemTenant
        ? systemTenantWidth
        : nonSystemTenantWidth
      : defaultWidth;

  const actionRightFixedColumn = (() => {
    const { isSystemTenant } = userInfo.tenant || {};
    const hasUpdatePermission = permissionList.includes(
      "tenant.project.resultshold.internal.update"
    );

    return enable_indivisual_result_email && isSystemTenant
      ? hasUpdatePermission
        ? 100
        : 40
      : hasUpdatePermission
      ? 60
      : 0;
  })();

  const resultColumns = [
    {
      headerName: <HandleResultRowSelection />,
      field: "check1",
      renderCell: rowSelectionInput,
      sortable: false,
      fixedColumnClass: "first_col_fix",
      fixedColumnHeadClass: "first_col_head_fix",
      leftColumnFixed: 0,
      width: 50,
    },
    {
      renderHeader: formatIrnHeaderText,
      field: "interviewReferenceNumber",
      renderCell: handleIrnClick,
      width: 150,
      sortable: false,
      headerClassName: "result-selection-header",
      fixedColumnHeadClass: "second_col_head_fix right-border",
      fixedColumnClass: "second_col_fix right-border",
      leftColumnFixed: 50,
    },
    ...(userInfo.tenant.isSystemTenant !== null &&
    !userInfo.tenant.isSystemTenant
      ? [
          {
            headerName: handleListName(configurationJson),
            field: "participantName",
            width: 200,
            sortable: false,
          },
        ]
      : []),
    {
      headerName: `${t("Language")}`,
      field: "participantLanguage",
      width: 110,
      sortable: false,
      fixedColumnHeadClass: "result_lang_head_col",
      fixedColumnClass: "result_lang_col_fix",
      leftColumnFixed: 0,
    },
    {
      renderHeader: formatDateHeaderText,
      field: "dateString",
      renderCell: formatDate,
      width: 110,
      sortable: false,
      fixedColumnHeadClass: "result_lang_head_col",
      fixedColumnClass: "result_lang_col_fix",
    },
    {
      renderHeader: formatOverAllHeaderText,
      field: "overall",
      renderCell: handleOverAllRisk,
      width: 100,
      sortable: false,
    },
    {
      headerName: customQuestionText,
      field: "questions",
      renderCell: questionStructure,
      width: resultWidth,
      sortable: false,
    },
    {
      headerName: `${t("Access Code")}`,
      field: "accesscode",
      renderCell: getMaskedAccessCode,
      width: 150,
      sortable: false,
    },
    ...(enable_indivisual_result_email && userInfo.tenant?.isSystemTenant
      ? [
          {
            headerName: `${t("Status")}`,
            field: "resultEmailStatus",
            width: 120,
            sortable: false,
          },
        ]
      : []),
    {
      headerName: `${t("Created By")}`,
      field: "created_by",
      renderCell: createByCodeFormatter,
      width: 170,
      sortable: false,
    },
    {
      headerName: `${t("Action")}`,
      field: "btnPdf",
      renderCell: handleActions,
      width: actionWidth,
      sortable: false,
      fixedColumnClass: "last_col_fix left-border",
      fixedColumnHeadClass: "last_col_head_fix left-border",
      rightColumnFixed: actionRightFixedColumn,
    },
    ...(enable_indivisual_result_email && userInfo.tenant?.isSystemTenant
      ? [
          {
            headerName: "",
            field: "sendEmail",
            renderCell: handleSendEmail,
            width: 40,
            sortable: false,
            fixedColumnClass: "last_col_fix",
            fixedColumnHeadClass: "last_col_head_fix",
            rightColumnFixed:
              permissionList.indexOf(
                "tenant.project.resultshold.internal.update"
              ) >= 0
                ? 60
                : 0,
            alignItem: "center",
          },
        ]
      : []),
    ...(permissionList.indexOf("tenant.project.resultshold.internal.update") >=
    0
      ? [
          {
            headerName:
              permissionList.indexOf(
                "tenant.project.resultshold.internal.update"
              ) >= 0 ? (
                <Checkbox
                  type="checkbox"
                  className="participant select-all-dropdown"
                  checked={isSelectAll}
                  value={isSelectAll}
                  onChange={selectAllResult}
                  indeterminate={
                    selectedResultList[0] !== selectedResultList[1] &&
                    results.length !== selectedResultList.length
                  }
                  label=""
                />
              ) : (
                ""
              ),
            field: "check",
            sort: false,
            renderCell: handleResultSelection,
            width: 60,
            sortable: false,
            fixedColumnClass: "last_col_fix",
            fixedColumnHeadClass: "last_col_head_fix",
            rightColumnFixed: 0,
            alignItem: "center",
          },
        ]
      : []),
  ];

  const onPageChange = (page) => {
    dispatch("set_current_page", page);
    dispatch("set_table_page_change", !onTablePageChange);
  };

  const onPageSizeChange = (event) => {
    const perPageSize = event.target.value;
    dispatch("set_per_page_count", parseInt(perPageSize));
    dispatch("set_current_page", 0);
  };

  /**
   * This function will closed note modal
   * @param {boolean} status
   */
  const closeNotesModal = (status) => {
    if (status) {
      setTimeout(() => {
        dispatch("result_notes_modal_show", false);
      }, 1000);
    } else {
      setTimeout(() => {
        dispatch("result_notes_modal_show", false);
        props.fetchProjectResult();
      }, 1000);
    }
  };

  const closeCMRecordingModal = (status) => {
    if (status) {
      //setTimeout(() => {
      dispatch("result_cm_recording_modal_show", false);
      //}, 1000);
    } else {
      //setTimeout(() => {
      dispatch("result_cm_recording_modal_show", false);
      //props.fetchProjectResult();
      //}, 1000);
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid size={12}>
          {resultsState.alertStatus && (
            <AlertDismissible
              msg={resultsState.alertMsg}
              variantType={resultsState.variant}
              show={resultsState.alertStatus}
              close={() => dispatch("set_alert_status", false)}
            />
          )}
        </Grid>
        <Grid size={12}>
          <div className="app-table result-module-table">
            <EnhancedTable
              loading={!loadingResults}
              data={results}
              columns={resultColumns}
              currentPage={currentPage}
              perPageCount={perPageCount}
              totalPages={totalResultsCount}
              onChangeRecords={onPageChange}
              onPageSizeChange={onPageSizeChange}
              selectedCount={selectedResultRow.length}
              selectedTableLabel="Result"
            />
          </div>
        </Grid>
      </Grid>
      {resultNotesModalShow && (
        <EditNoteMsgModal
          showNotesModal={resultNotesModalShow}
          closeNotesModal={closeNotesModal}
        />
      )}

      {resultCMRecordingModalShow && selectedRecording && (
        <ModalPopUp
          show={resultCMRecordingModalShow}
          onHide={closeCMRecordingModal}
          title={
            resultDetail?.interviewReferenceNumber &&
            `${t("RECORDING_FOR")} (${resultDetail?.interviewReferenceNumber})`
          }
          className={"CMRecording"}
          styleClass={"cm_modal_container"}
          data={resultsState}
        >
          <div
            onContextMenu={(evt) => {
              evt.preventDefault();
            }}
          >
            {selectedRecording && (
              <audio controlsList="nodownload" controls="controls">
                <source src={selectedRecording} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            )}
          </div>
        </ModalPopUp>
      )}

      {showOutComeTrackingModal && (
        <OutcomeTracking
          showModal={showOutComeTrackingModal}
          closeModal={() => {
            props.fetchProjectResult();
            dispatch("show_out_come_tracking_modal", false);
          }}
        />
      )}
      {showQuestionModal && (
        <QuestionsModal
          show={showQuestionModal}
          closeModal={() => {
            setShowQuestionModal(false);
          }}
          resultDetail={resultDetail}
        />
      )}
      {resultReviewModalShow && (
        <MarkReviewModal
          showReviewModal={resultReviewModalShow}
          closeReviewModal={closeReviewModal}
        />
      )}
    </React.Fragment>
  );
};

export default ResultsTable;
