import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Date_Format } from "../../config/index.js";
import { CustomGrid } from "../CustomTable";
import { useReportContext } from "./context/ReportContext";
import { ReportHeader } from "./ReportHeader";
import { ReportListDetail } from "./ReportListDetail";
import DownloadReport from "./DownloadReport.js";

export const ReportList = () => {
  const { state: reportState } = useReportContext();
  const {
    analyticReport: { data, loading },
  } = reportState;
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    currentPage: 0,
    perPageCount: 10,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [reportListData, setReportListData] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [downloadReportId, setDownloadReportId] = useState(null);
  const [downloadReportFileName, setDownloadReportFileName] = useState(null);

  const onRowClick = (params, event) => {
    setAnchorEl(true);
    setReportListData(params?.row);
    setAnchorPosition({
      top: event.clientY,
      left: event.clientX,
    });
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setReportListData(null);
    setAnchorPosition(null);
  };

  const columns = [
    {
      field: "report_name",
      headerName: t("REPORT_NAME"),
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span>{params.row.report_name}</span>
          </>
        );
      },
    },
    {
      field: "created_by",
      headerName: t("CREATED_BY"),
      flex: 1,
      sortable: false,
    },
    {
      field: "created_date",
      headerName: t("CREATED_TIME"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.created_date
          ? moment(params.row.created_date).format(`${Date_Format} HH:mm:ss`)
          : "Invalid Date";
      },
    },
    {
      field: "action",
      headerName: t("Action"),
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({
        row: { is_succeeded, is_processing, id, report_name },
      }) => {
        if (is_succeeded) {
          return (
            <IconButton
              aria-label="download"
              color="primary"
              size="medium"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setDownloadReportId(id);
                setDownloadReportFileName(report_name);
                setDialogOpen(true);
              }}
            >
              <FileDownloadIcon fontSize="inherit" />
            </IconButton>
          );
        }

        return (
          <span
            className={
              is_processing ? "in-progress-download-link" : "failed-status"
            }
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            {is_processing ? `${t("IN_PROGRESS")}...` : t("Failed")}
          </span>
        );
      },
    },
  ];

  const onChangeRecords = (page) => {
    setPagination({
      ...pagination,
      currentPage: page.page,
      perPageCount: page.pageSize,
    });
  };

  const open = Boolean(anchorEl);

  return (
    <div className="w-1200">
      <ReportHeader />
      <div className="card">
        <div className="table_wrapper custom_table_container app-table analytic-reports-module-table table-row-cursor-selectable">
          <CustomGrid
            data={data || []}
            columns={columns || []}
            getRowId={(row) => row.id}
            perPageCount={pagination.perPageCount}
            currentPage={pagination.currentPage}
            totalPages={data?.length}
            isServerPagination={false}
            onChangeRecords={onChangeRecords}
            loading={loading}
            onRowClick={(params, event) => onRowClick(params, event)}
          />
          {reportListData && (
            <ReportListDetail
              anchorEl={anchorEl}
              isOpen={open}
              data={reportListData}
              handleClose={handlePopoverClose}
              anchorPosition={anchorPosition}
            />
          )}
        </div>
      </div>
      {dialogOpen && (
        <DownloadReport
          show={dialogOpen}
          handleClose={() => {
            setDownloadReportId(null);
            setDialogOpen(false);
            setDownloadReportFileName(null);
          }}
          downloadReportId={downloadReportId}
          fileName={downloadReportFileName}
        >
          {t("DOWNLOAD_CONFIRMATION_MESSAGE")}
        </DownloadReport>
      )}
    </div>
  );
};
