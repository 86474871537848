import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Grid2 as Grid, Tab, Tabs } from "@mui/material";
import approved from "../../../../assets/icon/approved.png";
import editGrayIcon from "../../../../assets/icon/edit_gray.png";
import { Button, ModalPopUp, Spinner } from "../../../../components/Common";
import {
  getResultConfigData,
  getResultTemplateData,
} from "../../../../Services/projectServices.js";
import ResultTemplateFields from "./components/ResultTemplateFields.js";

export default function TemplateModalForm({
  show,
  handleClose,
  handleSubmit,
  rowID,
  dialogModal,
}) {
  const [loading, setLoading] = useState(false);
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [tabKey, setTabKey] = useState(0);
  const [value, setValue] = React.useState(0);
  const [resultConfigJson, setResultConfigJson] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (rowID) {
      fetchResultConfigDataForm();
    } else {
      getResultConfigDataFormInitial();
    }
  }, []);

  const getObjectKey = (obj) => {
    let getkeyTitle = "";
    if (obj) {
      const keys = Object.keys(obj);
      if (keys.length > 0) {
        getkeyTitle = keys[0];
      }
    }
    return getkeyTitle;
  };

  const formateString = (inputString) => {
    // Replace underscores with spaces and capitalize each word
    const formattedString = inputString
      .replace(/_/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());

    // Remove spaces and add / between single letters
    const processedString = formattedString.replace(/(\b\w)\s+/g, "$1/");

    return `${processedString}`;
  };

  const fetchResultConfigDataForm = () => {
    setLoading(true);
    getResultTemplateData(rowID)
      .then((res) => {
        // console.log("res getResultConfigData", res.data);
        setResultConfigJson(res.data);
        let getKey = getObjectKey(res.data.template_type);
        setTabKey(getKey);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getResultConfigDataFormInitial = () => {
    setLoading(true);
    getResultConfigData()
      .then((res) => {
        // console.log("res getResultConfigData", res.data);
        setResultConfigJson(res.data);
        let getKey = getObjectKey(res.data.template_type);
        setTabKey(getKey);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const initialValues = {
    template_name: resultConfigJson?.template_name || "",
    template_type: resultConfigJson?.template_type || {},
  };
  const validationSchema = Yup.object({
    template_name: Yup.string().required("Template name is required"),
  });
  const handleSubmitTemplate = (values) => {
    handleSubmit(values);
  };

  const handleChange = (event, newValue) => {
    console.log("event, newValue", event, newValue);

    setValue(newValue); // Update selected tab
  };

  return (
    <>
      <ModalPopUp show={show} onHide={() => handleClose()}>
        {loading ? (
          <div className="custom-loader modal_center_loader text-center">
            <Spinner />
          </div>
        ) : (
          <>
            {resultConfigJson && (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmitTemplate}
                enableReinitialize
              >
                {(formik) => {
                  const { handleBlur, values, setFieldValue } = formik;
                  return (
                    <Form>
                      <Grid container className="add-template-row">
                        <Grid
                          item
                          className="add-template-col d-flex align-items-center"
                          size={12}
                        >
                          <div
                            className="template_type_input"
                            style={{ width: "35%" }}
                          >
                            <Field
                              type="text"
                              id="template_name"
                              name="template_name"
                              disabled={fieldDisabled}
                              className={"form-control template_name_field"}
                              placeholder={t("Template Name")}
                            />
                            <ErrorMessage
                              name="template_name"
                              render={(msg) => (
                                <div className="required-error template_name_error_msg">
                                  {t(msg)}
                                </div>
                              )}
                              className="required-error"
                            />
                          </div>
                          {fieldDisabled && (
                            <div className="template_icon_img">
                              <img
                                onClick={() => setFieldDisabled(false)}
                                src={editGrayIcon}
                                alt="Edit"
                              />
                            </div>
                          )}
                          {!fieldDisabled && (
                            <div className="template_icon_img">
                              <img
                                onClick={() => setFieldDisabled(true)}
                                src={approved}
                                alt="Save"
                              />
                            </div>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid size={12}>
                          <h6 className="dark-light-text-color">
                            {t("Select Template Type")}
                          </h6>
                        </Grid>
                      </Grid>
                      <Grid container className="tabs_modal">
                        <Grid size={12}>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            className="result_conf_tabs"
                            variant="fullWidth"
                          >
                            {resultConfigJson &&
                              Object.keys(resultConfigJson.template_type).map(
                                (key, index) => (
                                  <Tab
                                    key={`result_conf_temp_type_${key}`}
                                    label={formateString(key)}
                                    value={index}
                                  />
                                )
                              )}
                          </Tabs>
                          {resultConfigJson &&
                            Object.keys(resultConfigJson.template_type).map(
                              (key, index) =>
                                value === index && (
                                  <ResultTemplateFields
                                    key={`result_conf_temp_fields_${key}`}
                                    title={key}
                                    data={resultConfigJson.template_type[key]}
                                    formik={formik}
                                  />
                                )
                            )}
                        </Grid>
                      </Grid>

                      <Grid container className="mt-4">
                        <Grid size={12}>
                          <div className="form-group float-right mb-0">
                            <Button
                              variant="secondary"
                              type="button"
                              className="rounded-0 mr-4 cancel-btn"
                              onClick={() => handleClose()}
                            >
                              {t("Cancel")}
                            </Button>
                            <Button
                              variant="primary"
                              type="submit"
                              className="rounded-0 save-btn button_with_loader"
                              disabled={formik.isSubmitting}
                            >
                              {formik.isSubmitting ? (
                                <>
                                  {dialogModal === "EDIT"
                                    ? t("Update")
                                    : t("Save")}
                                  ...
                                  <div className="custom-loader linesLoader loader_inner_button">
                                    <Spinner color="#ffffff" size={"19px"} />
                                  </div>
                                </>
                              ) : (
                                <span>
                                  {dialogModal === "EDIT"
                                    ? t("Update")
                                    : t("Save")}
                                </span>
                              )}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </>
        )}
      </ModalPopUp>
    </>
  );
}
