import React from "react";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Grid2 as Grid } from "@mui/material";
import "./multiLanguageConfiguration.css";
import MultiLanguageRows from "./MultiLanguageRows";

const MultiLanguageConfiguration = (props) => {
  const { t } = useTranslation();
  const { formik, state } = props;
  const { projectId } = state;

  // use this function for expanding Accordion if there is error in Accordion on save button click
  const createUUIDArray = () => {
    const numberArray = Array.from(Array(30).keys());
    const stringArray = numberArray.map((number) => {
      return number.toString();
    });
    return stringArray;
  };
  return (
    <Grid size={12}>
      <div className="mb-2 mt-2 additional_configuration_accordion">
        <Accordion
          preExpanded={createUUIDArray()}
          allowZeroExpanded="true"
          allowMultipleExpanded="true"
        >
          <AccordionItem uuid={props.isMultiAccordianUUID}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="row-header">
                  <div>
                    <label className="row-header-title">
                      {t("Multi Language Configuration")}
                    </label>
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <MultiLanguageRows
                t={t}
                formik={formik}
                state={state}
                projectId={projectId}
              />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    isMultiAccordianUUID: state.projects?.isMultiAccordianUUID,
  };
};

export default withTranslation()(
  connect(mapStateToProps)(MultiLanguageConfiguration)
);
