import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Card, Grid2 as Grid, CardContent } from "@mui/material";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../components/Common";
import { getlanguageIdFromcode } from "../../Helpers/index";
import i18n from "../../locale/i18n";
import { getAttribute } from "../../Services/projectServices";
import { getUserInfo } from "../../Services/userServices";
import { authActions } from "../../store/actions/authAction";
import { projectActions } from "../../store/actions/projectActions";
import InterviewActivity from "../Dashboard/InterviewActivity";
import Riskytd from "../Dashboard/Riskytd";
import MultiSelectDropDown from "../Utility/MultiSelectDropDown/MultiSelectDropDown";
import TimeDifferenceCalculator from "../Utility/TimeDifferenceCalculator";
import "./projectList.css";

class ProjectList extends Component {
  state = {
    isChecked: true,
    alertMsg: this.props.alertMsg,
    alertstage: this.props.alertstage,
    variant: this.props.variant,
    statusA: [],
    stageA: [],
    filterStatusValue: "",
    filterStageValue: "",
    isLoading: true,
    statusValue: null,
    stageValue: null,
    permission: [],
  };

  handleAlertClose = () => {
    this.props.alertclose();
  };

  getdatssets = (data, datakey) => {
    let questionArray = [];
    data.map((question, index) => {
      questionArray.push(question[datakey + "Count"]);
      //console.log(questionArray)
    });
    return questionArray;
  };

  getfilterdatsset = (data, datakey) => {
    let questionArray = [];
    data.map((question, index) => {
      questionArray.push(Math.abs(question[datakey + "Count"]));
    });
    return questionArray;
  };

  getQuestionlabelarray = (questionlength) => {
    let questionlabel = [];
    for (var i = 0; i < questionlength; i++) {
      questionlabel.push("Q" + (i + 1));
    }
    return questionlabel;
  };
  getQuestionData = (quesarray, questionlength, Title) => {
    const dates = this.getQuestionlabelarray(questionlength);
    const data = {
      labels: dates,
      datasets: [
        {
          label: this.props.t("High Risk"),
          backgroundColor: "#FF0000",
          borderWidth: 0,
          barThickness: 14,
          data: this.getdatssets(quesarray, "hr"),
        },
        {
          label: this.props.t("Potential Risk"),
          backgroundColor: "#FFCA00",
          borderWidth: 0,
          barThickness: 14,
          data: this.getdatssets(quesarray, "pr"),
        },
        {
          label: this.props.t("Average Risk"),
          backgroundColor: "#92D050",
          borderWidth: 0,
          barThickness: 14,
          data: this.getdatssets(quesarray, "ar"),
        },
        {
          label: this.props.t("Low Risk"),
          backgroundColor: "#008000",
          borderWidth: 0,
          barThickness: 14,
          data: this.getdatssets(quesarray, "lr"),
        },
      ],
    };
    return data;
  };

  getSubfilterchartOptions = (completedInterview) => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 10,
      },
      scales: {
        x: {
          barThickness: 8,
          barThickness: 14,
          display: false,
          grid: {
            display: false,
          },
        },
        y: {
          display: false,
          barThickness: 14,
          grid: {
            display: false,
          },
        },
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: -10,
          bottom: 10,
        },
      },
      tooltips: {
        mode: "nearest",
        //  tooltipCaretSize: 0,
        position: "nearest",
        callbacks: {
          label: function (tooltipItem, data) {
            return (
              " " +
              data.datasets[tooltipItem.datasetIndex].label +
              ": " +
              Math.abs(tooltipItem.yLabel) +
              " | " +
              Math.round(
                (Math.abs(tooltipItem.yLabel) / completedInterview) * 100
              ) +
              "% "
            );
          },
          title: function () {
            return "";
          },
          beforeLabel: function (tooltipItem, data) {
            //return " "+tooltipItem.xLabel+ ": "+  Math.round((Math.abs(tooltipItem.yLabel) /completedInterview)*100) +"% " ;
            return "";
          },
        },
      },

      // scales: {
      //   xAxes: [
      //     {
      //       display: false,
      //       // barThickness: 14,
      //       gridLines: { display: false },
      //       ticks: {
      //         fontColor:
      //           this.props.themeColor === "light" ? "#000000" : "#c2c5d1",
      //       },
      //       //  barThickness: 11,
      //     },
      //   ],
      //   yAxes: [
      //     {
      //       // barThickness: 14,
      //       display: false, //this will remove all the x-axis grid lines
      //       min: 0,
      //       ticks: {
      //         fontColor:
      //           this.props.themeColor === "light" ? "#000000" : "#c2c5d1",
      //       },
      //     },
      //   ],
      // }, // scales
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    };
    return options;
  };
  getSubfilterData = (quesarray, questionlength) => {
    const dates = this.getQuestionlabelarray(questionlength);
    // const dates = ["Q1", "Q2", "Q3", "Q4", "Q5", "Q6", "Q7"];
    const data = {
      labels: dates,
      datasets: [
        {
          label: this.props.t("Counter Measure"),
          backgroundColor: "#6758F3",
          borderWidth: 0,
          barThickness: 14,
          data: this.getfilterdatsset(quesarray, "cm"),
        },
        {
          label: this.props.t("Admission"),
          backgroundColor: "#1665D8",
          borderWidth: 0,
          barThickness: 14,
          data: this.getfilterdatsset(quesarray, "ad"),
        },
        {
          label: this.props.t("Non-Compliant"),
          backgroundColor: "#BCBCBC",
          borderWidth: 0,
          barThickness: 14,
          data: this.getfilterdatsset(quesarray, "nc"),
        },
      ],
    };
    return data;
  };

  getInterviewsData = (hr, pr, ar, lr, nc) => {
    let datarisknew = [hr, pr, ar, lr, nc];
    if (hr === 0 && ar == 0 && pr == 0 && lr == 0 && nc == 0) {
      datarisknew = [hr, pr, ar, lr, nc, 1];
    } else {
      datarisknew = [hr, pr, ar, lr, nc];
    }

    const barBackgroundColor =
      this.props.themeColor === "light"
        ? ["#FF0000", "#FFCA00", "#92D050", "#008000", "#d9d9d9", "grey"]
        : ["#ff4560", "#feb019", "#00e396", "#008000", "#d9d9d9", "#ffffff"];

    const doghnutdata = {
      labels: [
        this.props.t("High Risk"),
        this.props.t("Potential Risk"),
        this.props.t("Average Risk"),
        this.props.t("Low Risk"),
        this.props.t("Non-Compliant"),
        this.props.t("No-Interviews"),
      ],
      datasets: [
        {
          data: datarisknew,
          backgroundColor: barBackgroundColor,
          hoverBackgroundColor: barBackgroundColor,
          borderWidth: 1,
          borderColor: this.props.themeColor === "dark" ? "#060818" : "#fffcef",
          cutout: "80%",
        },
      ],
    };
    return doghnutdata;
  };

  getInterviewpercntage = (x, y) => {
    let percent = (x / y) * 100;
    percent = parseInt(percent);
    return Number.isNaN(percent) ? "0" : percent;
  };
  getRouteonStage = (stagelist, projectstage, prjId) => {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    let resultPermissionExists = false;
    if (user?.rolePermission?.permissions) {
      resultPermissionExists = user.rolePermission.permissions.some(
        (obj) => obj.permission === "tenant.project.results.read"
      );
    }
    let participantPermissionExists = false;
    if (user?.rolePermission?.permissions) {
      participantPermissionExists = user?.rolePermission?.permissions?.some(
        (obj) => obj.permission === "tenant.project.participants.read"
      );
    }
    if (
      user &&
      user.rolePermission &&
      user.rolePermission.roleName === "Tenant Support"
    ) {
      return `/main/project/${prjId}`;
    } else {
      let newstagelist =
        stagelist &&
        stagelist.filter((stage) => stage.attributeId === projectstage);

      if (newstagelist && newstagelist.length > 0) {
        let stage = newstagelist[0].sequence;
        if (
          (stage === 3 && resultPermissionExists) ||
          (stage === 4 && resultPermissionExists)
        ) {
          return `/main/project/${prjId}/results`;
        } else if (
          (stage === 3 && participantPermissionExists) ||
          (stage === 4 && participantPermissionExists)
        ) {
          return `/main/project/${prjId}/participants`;
        }
      }
      return `/main/project/${prjId}`;
    }
  };

  componentDidMount() {
    getUserInfo()
      .then((res) => {
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        sessionStorage.setItem("operatingTenant", res.data.operatingTenant);
        let permission = [];
        res.data.rolePermission.permissions.forEach((option) => {
          permission = [...permission, option.permission];
        });
        this.setState({
          permission: permission,
        });
        this.props.setHeaderLogo(res.data.tenantLogo);
      })
      .catch((err) => {});
    const self = this;
    this.props.onLoading();
    setTimeout(() => {
      this.loadAttribute();
    }, 0);

    i18next.on("languageChanged", function (lng) {
      self.setState(
        {
          language: lng,
        },
        () => {
          self.loadAttribute();
        }
      );
    });
  }

  loadAttribute = () => {
    let self = this;
    let languagecode = i18n.language;
    let languageId = getlanguageIdFromcode(languagecode);

    getAttribute(languageId)
      .then((res) => {
        self.filterAttribute(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  filterAttribute = (res) => {
    let statusOption = [];
    let stageOption = [];
    let statusArray = [];
    let stageArray = [];
    let projectFilters = localStorage.getItem("projectFilters");
    const activeLabel = this.props.t("Active") || "Active";

    let status = res.data.attributes.filter((item) => {
      return item.attributeType === "STATUS";
    });

    const defaultStatusOption = status
      .map((option) => {
        return this.createDropdownOption(option);
      })
      .filter((option) => option.label === activeLabel);

    let stage = res.data.attributes.filter((item) => {
      return item.attributeType === "STAGE";
    });

    if (projectFilters === null) {
      statusOption = defaultStatusOption;
      statusOption.map((value) => {
        statusArray.push(Number(value.value));
      });

      stageOption.map((value) => {
        stageArray.push(Number(value.value));
      });
    } else {
      const getFilter = JSON.parse(projectFilters);
      statusOption = getFilter.status || defaultStatusOption;

      stageOption = (getFilter.stage && getFilter.stage) || [];
      statusOption.map((value) => {
        statusArray.push(Number(value));
      });
      stageOption.map((value) => {
        stageArray.push(Number(value));
      });
    }

    this.setState(
      {
        statusA: status,
        stageA: stage,
        statusValue: statusArray,
        stageValue: stageArray,
        filterStatusValue: statusArray.toString(),
        filterStageValue: stageArray.toString(),
      },
      () => {
        this.props.getProjects(
          this.state.filterStatusValue,
          this.state.filterStageValue,
          this.callBackGetProject
        );
      }
    );
  };

  callBackGetProject = () => {
    this.setState({
      isLoading: false,
    });
  };

  createDropdownOption = (option) => {
    return {
      value: option.attributeId,
      label: option.description,
      sequence: option.sequence,
    };
  };

  handleStatus = (selectedOption) => {
    let projectFilters = localStorage.getItem("projectFilters");
    if (projectFilters === null) {
      let newProjectFilters = {
        status: selectedOption,
      };
      setTimeout(() => {
        localStorage.setItem(
          "projectFilters",
          JSON.stringify(newProjectFilters)
        );
      }, 100);
    } else {
      let newProjectFilters = JSON.parse(projectFilters);
      newProjectFilters.status = selectedOption;
      setTimeout(() => {
        localStorage.setItem(
          "projectFilters",
          JSON.stringify(newProjectFilters)
        );
      }, 100);
    }
    this.setState(
      {
        statusValue: selectedOption,
        isLoading: true,
        filterStatusValue: selectedOption.toString(),
      },
      () => {
        this.props.getProjects(
          this.state.filterStatusValue,
          this.state.filterStageValue,
          this.callBackGetProject
        );
      }
    );
  };

  stageFilterHandleChange = (option) => {
    let projectFilters = localStorage.getItem("projectFilters");
    if (projectFilters === null) {
      let newProjectFilters = {
        stage: option,
      };
      setTimeout(() => {
        localStorage.setItem(
          "projectFilters",
          JSON.stringify(newProjectFilters)
        );
      }, 100);
    } else {
      let newProjectFilters = JSON.parse(projectFilters);
      newProjectFilters.stage = option;
      setTimeout(() => {
        localStorage.setItem(
          "projectFilters",
          JSON.stringify(newProjectFilters)
        );
      }, 100);
    }
    this.setState(
      {
        stageValue: option,
        isLoading: true,
        filterStageValue: option.toString(),
      },
      () => {
        this.props.getProjects(
          this.state.filterStatusValue,
          this.state.filterStageValue,
          this.callBackGetProject
        );
      }
    );
  };

  handleNavigate = (id, level) => {
    this.props.history.push(
      `/main/project/${id}/results/resultdetail:${level}`
    );
  };

  handleLabel = (item, project) => {
    let index = item.length > 0 && item[0]?.index;
    let getLabel = this.getInterviewsData(
      project.overallHrCount,
      project.overallPrCount,
      project.overallArCount,
      project.overallLrCount,
      project.overallNcCount
    );

    let riskLevel = getLabel.labels[index];
    this.handleNavigate(project.projectId, riskLevel);
  };

  barChartOptions = (project, isShowXAxis) => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          ticks: {
            display: isShowXAxis,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        y: {
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem) {
              const dataset = tooltipItem.dataset;
              console.log("tooltipItem.dataset", tooltipItem.dataset);

              const currentValue = dataset.data[tooltipItem.dataIndex];
              console.log("currentValue", currentValue);

              const dstLabel = tooltipItem.dataset.label;
              const percentage = Math.floor(
                (currentValue / project.totalCompletedInterview) * 100
              );
              return `${dstLabel}: ${currentValue} | ${percentage}%`;
            },
            title: function () {
              return "";
            },
          },
        },
      },
    };
  };

  render() {
    const { t } = this.props;
    let self = this.props;
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let languageId = parseInt(localStorage.getItem("languageId"));

    const dognutoptions = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          position: "bottom",
          align: "center",
          fullSize: true,
          labels: {
            boxWidth: 40,
            fontColor:
              this.props.themeColor === "light" ? "#000000" : "#c2c5d1",
          },
        },
        tooltip: {
          callbacks: {
            title: (tooltipItem) => {
              return tooltipItem[0].label;
            },
            label: (tooltipItem) => {
              let total = tooltipItem.dataset.data.reduce(function (
                previousValue,
                currentValue
              ) {
                return previousValue + currentValue;
              });
              let currentValue =
                tooltipItem.dataset.data[tooltipItem.dataIndex];
              let precentage = Math.floor((currentValue / total) * 100 + 0.5);

              if (tooltipItem.dataset.data.length >= 6) {
                return "0 | 0% ";
              } else {
                return (
                  " " +
                  tooltipItem.dataset.data[tooltipItem.dataIndex] +
                  " | " +
                  precentage +
                  "%"
                );
              }
            },
          },
        },
      },
      cutout: "100%",
    };

    const listHtmlActive =
      this.props.projectList.length > 0 ? (
        this.props.projectList !== undefined &&
        this.props.projectList.map((project) => {
          let prjStage = "before";
          return (
            <Card key={project.projectId} id={"project_" + project.projectId}>
              <CardContent>
                <CardCorner status={project.active ? "active" : "inactive"} />
                <div className="card-title h5">
                  <Link
                    to={this.getRouteonStage(
                      this.props.stageList,
                      project.projectStageId,
                      project.projectId
                    )}
                  >
                    {project.name}
                  </Link>
                </div>
                <Grid container className="">
                  <Grid size={2}>
                    <ul className="project-stage">
                      {this.props.stageList.map((stage, index) => {
                        if (project.projectStageId === stage.attributeId) {
                          prjStage = "after";
                        }
                        return languageId === stage.languageId ? (
                          <li
                            key={index}
                            id={stage.stageId}
                            className={
                              project.projectStageId === stage.attributeId
                                ? "complete"
                                : `incomplete_${prjStage}`
                            }
                          >
                            {stage.description}
                          </li>
                        ) : (
                          ""
                        );
                      })}
                    </ul>
                  </Grid>

                  <Grid size={4} className="mt-chart">
                    <h3 className="charttitles text-uppercase">
                      {t("Overall Results")}
                    </h3>
                    <div className="doghnut-wrapper">
                      <Riskytd
                        datarisk={this.getInterviewsData(
                          project.overallHrCount,
                          project.overallPrCount,
                          project.overallArCount,
                          project.overallLrCount,
                          project.overallNcCount
                        )}
                        isLabelShow={false}
                        isLoading={this.state.isLoading}
                        onClick={(evt, item) => this.handleLabel(item, project)}
                        optionsrisk={dognutoptions}
                      />
                      <div className="doughnut-center-text">
                        {t("Calls Completed")} <br />{" "}
                        {project.totalLifeTimeCompletedInterview}
                      </div>
                    </div>
                  </Grid>
                  <Grid size={6} className="mt-chart">
                    <h3 className="charttitles text-uppercase">
                      {t("Question Results")}
                    </h3>
                    <div className="barchart-wrapper mt-1">
                      <InterviewActivity
                        data={this.getQuestionData(
                          project.questions,
                          project.totalPertinantQuestions,
                          project.name
                        )}
                        options={this.barChartOptions(project, true)}
                        isLabelShow={false}
                      />
                    </div>
                    <div className="barchart-invert-wrapper">
                      <InterviewActivity
                        data={this.getSubfilterData(
                          project.questions,
                          project.totalPertinantQuestions
                        )}
                        options={this.barChartOptions(project, false)}
                        isLabelShow={false}
                      />
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })
      ) : (
        <p className="text-center mt-5 no_data_text">
          {t("No projects are available")}
        </p>
      );

    let stageOption = this.state.stageA.map((option) => {
      return this.createDropdownOption(option);
    });

    let statusOption = this.state.statusA
      .map((option) => {
        return this.createDropdownOption(option);
      })
      .sort((a, b) => a.sequence - b.sequence);
    return (
      <React.Fragment>
        <div className="project-list page-breadcrumb w-1200 d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {t("Projects")}{" "}
            <ArrowForwardIosIcon
              sx={{
                fontSize: "15px",
                color: "#3e3f42",
                fontWeight: 600,
                marginRight: "5px",
              }}
            />
            &nbsp;
            <Link to={`/main/project`}>
              {t("Projects")} {t("List")}
            </Link>
          </div>

          <div className="text-right schedule_last_update_time_list_page">
            <span className="scheduleLastUpdateTime">
              {this.props.scheduleLastUpdateTime && (
                <TimeDifferenceCalculator
                  epochTimestamp={this.props.scheduleLastUpdateTime}
                />
              )}
            </span>
          </div>
        </div>
        <Card className="project-list-title card w-1200 filter_sticky">
          <CardContent className="card-single-content">
            <Grid container>
              <Grid size={4}>
                <h6 className="page-title">
                  {t("Projects")} {t("List")}{" "}
                </h6>
              </Grid>
              <Grid size={7}>
                <div className="d-flex justify-content-end align-content-center align-items-center filter_project_row">
                  <div className="project_filter_label">
                    <label>{t("Filter By")}</label>
                  </div>

                  {statusOption.length > 0 && (
                    <MultiSelectDropDown
                      options={statusOption}
                      setSelectedOption={this.handleStatus}
                      value={this.state.statusValue}
                      placeholder={t("Select Status")}
                    />
                  )}

                  {stageOption.length > 0 && (
                    <MultiSelectDropDown
                      options={stageOption}
                      setSelectedOption={this.stageFilterHandleChange}
                      value={this.state.stageValue}
                      placeholder={t("Select Stage")}
                    />
                  )}
                </div>
              </Grid>
              <Grid size={1}>
                {this.props.mainstatedata &&
                this.state.permission.indexOf("tenant.project.detail.create") >=
                  0 ? (
                  <Link to={`/main/project/createproject`}>
                    <Button
                      variant="success"
                      className="btn-rounded btn-sm mt-1 float-right btn-success"
                      iconButton={true}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="button_icon_only"
                      />
                    </Button>
                  </Link>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <AlertDismissible
          msg={this.props.alertMsg}
          variantType={this.props.variant}
          show={this.props.alertstage}
          close={this.handleAlertClose}
        ></AlertDismissible>

        <div className="projectlist w-1200">
          {this.state.isLoading ? (
            <div className="custom-loader tableLoader text-center mt-5">
              <Spinner />
            </div>
          ) : (
            listHtmlActive
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projectList: state.projects.projectList,
    stageList: state.projects.stageList,
    user: state.authentication.authentication.user,
    alertstage: state.projects.alertstage,
    alertMsg: state.projects.alertMsg,
    variant: state.projects.variant,
    themeColor: state.theme.themeColor,
    scheduleLastUpdateTime: state.projects.scheduleLastUpdateTime,
  };
};
const mapDispatchToProps = {
  getProjects: projectActions.getProjects,
  alertclose: projectActions.alertclose,
  onLoading: projectActions.dataLoading,
  setHeaderLogo: authActions.setHeaderLogo,
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectList))
);

const CardCorner = (props) => {
  return <div className={"card-corner " + props.status}></div>;
};
