import React from "react";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { FieldArray } from "formik";
import { Grid2 as Grid } from "@mui/material";
import { BusinessLineField } from "./BusinessLineField";

const BusinessLineFields = ({ formik, state, FormType = "ADD" }) => {
  const { t } = useTranslation();
  const { values, setFieldValue, errors, touched, setFieldTouched } = formik;
  const { isBusinessLineOptional } = values;
  const { language, businessLineData } = state;
  const businessLine = businessLineData?.[language] || {};
  const hasOtherValue = (other) => other.some((item) => item.value === "other");
  const isOtherInBusinessFunction =
    values.businessLine && hasOtherValue(values.businessLine);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { isSystemTenant } = userInfo?.tenant;

  const isFieldDisabled = () => {
    if (!isSystemTenant && FormType === "EDIT") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {businessLineData && (
        <FieldArray name="businessLine">
          {({ remove, push, replace }) => (
            <Grid
              container
              className="d-flex p-3 business-section-container"
              key={`business_line_field_array`}
            >
              {values &&
                values.businessLine.length > 0 &&
                values.businessLine.map((item, index) => {
                  return (
                    <BusinessLineField
                      key={item.currentFieldKey}
                      optionsData={businessLine}
                      item={item}
                      index={index}
                      formik={formik}
                      pushArrayField={push}
                      removeArrayField={remove}
                      replace={replace}
                      isBusinessLineOptional={isBusinessLineOptional}
                      isDisabled={isFieldDisabled() || isBusinessLineOptional}
                    />
                  );
                })}
            </Grid>
          )}
        </FieldArray>
      )}
      <Grid container className="d-flex justify-content-between pl-3 pr-3">
        <div className="business-tags-container">
          <div className="form-group">
            <label
              htmlFor="tags"
              className={`${
                !isBusinessLineOptional &&
                isOtherInBusinessFunction &&
                "required"
              } business-line-field`}
              data-permission={values?.tags.length !== 0}
            >
              {t("ADD_TAGS")}
            </label>
            <CreatableSelect
              id="tags"
              className="select-style"
              classNamePrefix="select"
              isMulti
              placeholder={t("Select")}
              onBlur={() => setFieldTouched("tags", true)}
              onChange={(option) => {
                if (option) {
                  setFieldValue("tags", option);
                } else {
                  setFieldValue("tags", []);
                }
              }}
              value={values.tags}
              isDisabled={isFieldDisabled() || isBusinessLineOptional}
            />
            {errors?.tags && touched.tags && (
              <div className="input-feedback">{errors?.tags}</div>
            )}
          </div>
        </div>
      </Grid>
    </>
  );
};

export default BusinessLineFields;
