import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Grid2 as Grid } from "@mui/material";
import moment from "moment";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../../../components/Common";
import { Send_Api_Date_Format } from "../../../../config";
import { createOrder } from "../../../../Services/OrderServices";
import { formatDate } from "../../../Utility/DatePickerFunctions";
import StandardDatePicker from "../../../Utility/StandardDatePicker";
import { SelectField, TextError, getTenantId } from "../Helper";

const CreateOrder = (props) => {
  const { t } = useTranslation();

  const { UpdateChildNav } = props;

  const [alertMsg, setAlertMsg] = useState("");

  const [alertStatus, setAlertStatus] = useState(false);

  const [variant, setVariant] = useState("");

  const initialValues = {
    order_number: "",
    start_date: "",
    end_date: "",
    number_of_calls: "",
    order_status: "",
    tenant_id: getTenantId(),
  };

  /**
   * This function will create order
   * @param {string} values
   * @param {string} submitProps
   */
  const handleCreateOrder = (values, submitProps) => {
    let obj = { ...values };
    obj.start_date = obj.start_date
      ? moment(obj.start_date).format(Send_Api_Date_Format)
      : obj.start_date;
    obj.end_date = obj.end_date
      ? moment(obj.end_date).format(Send_Api_Date_Format)
      : obj.end_date;
    createOrder(obj)
      .then((res) => {
        setTimeout(() => {
          UpdateChildNav("orders");
        }, 2000);
        setAlertMsg(`Order Created Successfully`);
        setVariant("success");
        setAlertStatus(true);
        submitProps.setSubmitting(false);
      })
      .catch((error) => {
        setAlertMsg(error.response?.data?.message);
        setVariant("danger");
        setAlertStatus(true);
        submitProps.setSubmitting(false);
      });
  };

  const validationSchema = Yup.object({
    order_number: Yup.string()
      .matches(/^\d+$/, t("Only numeric is allow"))
      .required(t("Order number is required")),
    start_date: Yup.date().required(t("Start date is required")),
    end_date: Yup.date()
      .when("start_date", (startDate, schema) => {
        if (startDate && startDate instanceof Date) {
          let startDatecopy = new Date(startDate.getTime());
          startDatecopy.setDate(startDatecopy.getDate() + 1);
          return schema.min(
            startDatecopy,
            t("End date must be a greater then start date")
          );
        }
        return schema;
      })
      .required(t("End date is required")),
    number_of_calls: Yup.number()
      .integer()
      .min(1, t("Total number of calls must be greater than 0"))
      .required(t("Number of call is required")),
    order_status: Yup.string().required(t("Order status is required")),
  });

  const orderStatusOption = [
    {
      label: t("Active"),
      value: "Active",
    },
    {
      label: t("Inactive"),
      value: "Inactive",
    },
    {
      label: t("Complete"),
      value: "Complete",
    },
  ];

  const handleAlertClose = () => {
    setAlertStatus(false);
  };

  const cancelCreateOrder = () => {
    setTimeout(() => {
      UpdateChildNav("orders");
    }, 1000);
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-8">
              <h6 className="page-title">{t("Create Order")}</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCreateOrder}
            enableReinitialize
          >
            {(formik) => {
              const { handleBlur, values, setFieldValue } = formik;
              return (
                <Form>
                  <Grid container>
                    <Grid size={12}>
                      <AlertDismissible
                        msg={alertMsg}
                        variantType={variant}
                        show={alertStatus}
                        close={handleAlertClose}
                      ></AlertDismissible>
                    </Grid>
                  </Grid>
                  <Grid container className="add-tenant-row">
                    <Grid className="add-tenant-col" size={4}>
                      <div className="form-group">
                        <label className="required" htmlFor="order_number">
                          {t("Order Number")}
                        </label>
                        <Field
                          type="text"
                          id="order_number"
                          name="order_number"
                          className={"form-control"}
                        />
                        <ErrorMessage
                          name="order_number"
                          component={TextError}
                        />
                      </div>
                    </Grid>
                    <Grid className="add-tenant-col" size={4}>
                      <div className="form-group">
                        <label className="required" htmlFor="order_status">
                          {t("Order Status")}
                        </label>
                        <Field
                          id="order_status"
                          name="order_status"
                          onBlur={handleBlur}
                          component={SelectField}
                          options={orderStatusOption}
                          values={values.order_status}
                        />
                        <ErrorMessage
                          name="order_status"
                          component={TextError}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container className="add-tenant-row">
                    <Grid className="add-tenant-col" size={4}>
                      <div className="form-group">
                        <label className="required" htmlFor="start_date">
                          {t("Start Date")}
                        </label>
                        <StandardDatePicker
                          dateFormat={formatDate()}
                          placeholderText={t("Start Date")}
                          autoComplete="off"
                          className="form-control w-100 "
                          name="start_date"
                          onChange={(selectedValue) =>
                            setFieldValue("start_date", selectedValue)
                          }
                          selected={
                            values.start_date
                              ? new Date(values.start_date)
                              : values.start_date
                          }
                        />

                        <ErrorMessage name="start_date" component={TextError} />
                      </div>
                    </Grid>
                    <Grid className="add-tenant-col" size={4}>
                      <div className="form-group">
                        <label className="required" htmlFor="end_date">
                          {t("End Date")}
                        </label>
                        <StandardDatePicker
                          dateFormat={formatDate()}
                          placeholderText={t("End Date")}
                          id="end_date"
                          name="end_date"
                          autoComplete="off"
                          className="form-control w-100"
                          onChange={(selectedValue) =>
                            setFieldValue("end_date", selectedValue)
                          }
                          selected={values.end_date}
                        />
                        <ErrorMessage name="end_date" component={TextError} />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="add-tenant-row">
                    <Grid className="add-tenant-col" size={4}>
                      <div className="form-group">
                        <label className="required" htmlFor="number_of_calls">
                          {t("Total Number of Calls")}
                        </label>
                        <Field
                          type="number"
                          id="number_of_calls"
                          name="number_of_calls"
                          className={"form-control"}
                        />
                        <ErrorMessage
                          name="number_of_calls"
                          component={TextError}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid size={12}>
                      <div className="form-group float-right">
                        <Button
                          variant="primary"
                          type="submit"
                          className="rounded-0 mr-4 save-btn button_with_loader"
                          disabled={formik.isSubmitting}
                        >
                          {formik.isSubmitting ? (
                            <>
                              {t("Save")}...
                              <div className="custom-loader linesLoader loader_inner_button">
                                <Spinner color="#ffffff" size={"19px"} />
                              </div>
                            </>
                          ) : (
                            <span>{t("Save")}</span>
                          )}
                        </Button>
                        <Button
                          variant="secondary"
                          type="button"
                          className="rounded-0 cancel-btn"
                          onClick={cancelCreateOrder}
                        >
                          {t("Cancel")}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateOrder;
