import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { Alert as AlertDismissible } from "../../components/Common";
import CreateProject from "../../components/CreateProject";
import { getProjectDetail } from "../../Services/projectServices";
import { getUserRolesPermissions } from "../../Services/userServices";
import { projectActions } from "../../store/actions/projectActions";
import { CustomTooltip } from "../Utility/CustomTooltip";
import AccessCode from "./AccessCode";
import Communication from "./Communication";
import ProjectInfo from "./ProjectInfo";
import ProjectParticipant from "./ProjectParticipant";
import ProjectQuestions from "./ProjectQuestions";
import "./projectResults.css";
import ProjectTeams from "./ProjectTeams";
import ResultNew from "./ResultNew";
import { ResultsProvider } from "./ResultNew/ResultsContext";
class ProjectDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectDetailB: {},
      projectDetail: null,
      departmentA: [],
      statusA: [],
      stageA: [],
      riskA: [],
      languageA: [],
      locationA: [],
      tagsA: [],
      id: "",
      teamDetail: {},
      isinternal: [],
      noPermissionmsg: "",
      alertMsg: "",
      alertStatus: false,
      variant: "",
      questionTypeValue: "",
    };
  }

  componentDidMount() {
    const self = this;
    const projectId = this.props.match.params.id;
    if (!this.props.loggedIn) return;
    this.setState({
      id: projectId,
    });
    getProjectDetail(projectId)
      .then((res) => {
        const { stageList } = this.props;

        this.props.isprojectStageSetup(stageList, res.data.stageId);
        self.setState(
          {
            projectDetailB: res.data,
            projectDetail: res.data,
          },
          () => {
            this.questionType();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.status === 403) {
          this.setState({
            alertMsg: err?.response?.data?.message,
            alertStatus: true,
            variant: "danger",
          });
        }
      });
    let internalPermissions = [];
    getUserRolesPermissions(projectId)
      .then((res) => {
        if (
          res.data.role_permissions != undefined &&
          res.data.role_permissions.length > 0
        ) {
          res.data.role_permissions.forEach(function (obj) {
            internalPermissions = [...internalPermissions, ...obj.permissions];
          });
          this.setState({
            isinternal: internalPermissions,
          });
        }
      })
      .catch((err) => {
        console.log("user service err", err);
      });
  }

  questionType = () => {
    let questiontype = this.state.projectDetail.questionaire_type;
    if (questiontype === "PRECISION_QUESTIONNAIRE") {
      this.setState({
        questionTypeValue: "Precision",
      });
    } else if (questiontype === "EXPRESS_QUESTIONNAIRE") {
      this.setState({
        questionTypeValue: "Express",
      });
    } else if (questiontype === "HYBRID_QUESTIONNAIRE") {
      this.setState({
        questionTypeValue: "Hybrid",
      });
    } else if (questiontype === "PRECISION_RED_GREEN_QUESTIONNAIRE") {
      this.setState({
        questionTypeValue: "Precision Red Green",
      });
    }
  };
  handleAlertClose = () => {
    this.setState((prevState) => {
      return {
        alertStatus: !prevState.alertStatus,
      };
    });
  };

  handleIsSetup = (e) => {
    const { isStageSetup } = this.props;

    if (isStageSetup) e.preventDefault();
  };

  render() {
    const { t } = this.props;
    const url = this.props.match.url;
    const projtitle = this.state.projectDetailB.name;
    if (!this.props.loggedIn) return <Redirect to="/login" />;

    return (
      <React.Fragment>
        <div className="page-breadcrumb">
          {t("Projects")}{" "}
          <ArrowForwardIosIcon
            sx={{
              fontSize: "15px",
              color: "#3e3f42",
              fontWeight: 600,
              marginRight: "5px",
            }}
          />
          <span>{projtitle}</span>
        </div>

        <nav as="ul" className="project-info-tab nav">
          <NavLink className="" exact activeClassName="active" to={url}>
            {t("Details")}
          </NavLink>
          {this.state.isinternal.indexOf("tenant.project.team.read") >= 0 ? (
            <NavLink
              exact
              activeClassName="active"
              className=""
              to={`${url}/team`}
            >
              {t("Team")}
            </NavLink>
          ) : (
            ""
          )}
          {this.state.isinternal.indexOf("tenant.project.participants.read") >=
          0 ? (
            <>
              {this.props.isStageSetup ? (
                <CustomTooltip
                  place="bottom"
                  effect="solid"
                  title="NOT_ALLOW_PARTICIPANTS_IN_SETUP_STAGE_MESSAGE"
                >
                  <NavLink
                    onClick={this.handleIsSetup}
                    activeClassName="active"
                    className={
                      this.props.isStageSetup ? "not-allowed-styled-none" : ""
                    }
                    to={`${url}/participants`}
                    data-tip={"Participant"}
                  >
                    {t("Participants")}
                  </NavLink>
                </CustomTooltip>
              ) : (
                <NavLink
                  onClick={this.handleIsSetup}
                  activeClassName="active"
                  className={
                    this.props.isStageSetup ? "not-allowed-styled-none" : ""
                  }
                  to={`${url}/participants`}
                  data-tip={"Participant"}
                >
                  {t("Participants")}
                </NavLink>
              )}
            </>
          ) : (
            ""
          )}
          {this.state.isinternal.indexOf("tenant.project.questions.read") >=
          0 ? (
            <NavLink
              exact
              activeClassName="active"
              className=""
              to={`${url}/questions`}
            >
              {t("Questions")}
            </NavLink>
          ) : (
            ""
          )}
          {this.state.isinternal.indexOf("tenant.project.results.read") >=
            0 && (
            <NavLink
              exact
              activeClassName="active"
              className=""
              to={`${url}/results`}
            >
              {t("Results")}
            </NavLink>
          )}
          {this.state.isinternal.indexOf("tenant.project.accesscode.read") >=
          0 ? (
            <NavLink
              exact
              activeClassName="active"
              className=""
              to={`${url}/accesscode`}
            >
              {t("Access Codes")}
            </NavLink>
          ) : (
            ""
          )}
          {this.state.isinternal.indexOf("tenant.project.communication.read") >=
          0 ? (
            <NavLink
              exact
              activeClassName="active"
              className=""
              to={`${url}/communication`}
            >
              {t("Communication")}
            </NavLink>
          ) : (
            ""
          )}
          {this.state.questionTypeValue ? (
            <div className="show-questiontype">
              <span>{t(this.state.questionTypeValue)}</span>
            </div>
          ) : (
            ""
          )}
        </nav>

        <AlertDismissible
          msg={this.state.alertMsg}
          variantType={this.state.variant}
          show={this.state.alertStatus}
          close={this.handleAlertClose}
        ></AlertDismissible>
        <Switch>
          <Route
            exact
            path={this.props.match.path}
            render={(props) => (
              <ProjectInfo {...props} statedata={this.state} />
            )}
          />
          {this.state.isinternal.indexOf("tenant.project.team.read") >= 0 && (
            <Route
              path={`${this.props.match.path}/team`}
              render={(props) => (
                <ProjectTeams {...props} statedata={this.state} />
              )}
            />
          )}
          <Route
            path={`${this.props.match.path}/createproject`}
            render={(props) => (
              <CreateProject {...props} statedata={this.state} />
            )}
          />
          {this.state.isinternal.indexOf("tenant.project.questions.read") >=
            0 && (
            <Route
              path={`${this.props.match.path}/questions`}
              render={(props) => (
                <ProjectQuestions {...props} statedata={this.state} />
              )}
            />
          )}
          {/* <Route
            path={`${this.props.match.path}/results/resultdetail:filterRisk`}
            render={(props) => (
              <ProjectResults {...props} statedata={this.state} />
            )}
          /> */}

          {this.state.isinternal.indexOf("tenant.project.results.read") >=
            0 && (
            <Route
              path={`${this.props.match.path}/results`}
              render={() =>
                this.state.isinternal.length > 0 && this.state.projectDetail ? (
                  <ResultsProvider
                    projectDetail={this.state.projectDetail}
                    permissionList={this.state.isinternal}
                  >
                    <ResultNew />
                  </ResultsProvider>
                ) : (
                  ""
                )
              }
            />
          )}
          {this.state.isinternal.indexOf("tenant.project.participants.read") >=
            0 && (
            <Route
              path={`${this.props.match.path}/participants`}
              render={(props) => (
                <>
                  {!this.props.isStageSetup && (
                    <ProjectParticipant {...props} statedata={this.state} />
                  )}
                  {this.props.isStageSetup && (
                    <p className="not_accessible_page">
                      {t("NOT_ALLOW_PARTICIPANTS_IN_SETUP_STAGE_MESSAGE")}
                    </p>
                  )}
                </>
              )}
            />
          )}
          {this.state.isinternal.indexOf("tenant.project.accesscode.read") >=
            0 && (
            <Route
              exact
              path={`${this.props.match.path}/accesscode`}
              render={(props) => (
                <AccessCode {...props} statedata={this.state} />
              )}
            />
          )}

          {this.state.isinternal.indexOf("tenant.project.communication.read") >=
            0 && (
            <Route
              exact
              path={`${this.props.match.path}/communication`}
              render={(props) => (
                <Communication {...props} statedata={this.state} />
              )}
            />
          )}
          {this.state.isinternal.length > 0 && (
            <Redirect to={"/main/project"} />
          )}
        </Switch>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loggedIn: state.authentication.loggedIn,
    stageList: state.projects.stageList,
    isStageSetup: state.projects.isStageSetup,
  };
};

const mapDispatchToProps = {
  isprojectStageSetup: projectActions.isprojectStageSetup,
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectDetail))
);
