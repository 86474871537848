import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Field } from "formik";
import { Grid2 as Grid } from "@mui/material";
import { uploadISWMsg } from "../../../../Services/projectServices";
import { SelectField, fileDownload } from "../Helper";
import ISWOptions from "./ISWOptions";

const ISW = (props) => {
  const { t } = useTranslation();
  const iswFileInput = useRef();
  const { formik, state } = props;
  const { canUserUpdateField } = state;
  const { projectId } = state;
  const { irnVerificationMethodsOption } = ISWOptions(state);
  const { values, handleBlur, setFieldValue } = formik;
  const [iSWmsg, setISWmsg] = useState("");
  const [disableIswbtn, setDisableIswbtn] = useState(false);
  const [colorToggle, setColorToggle] = useState(true);

  /**
   * This funciton will upload file on server
   * @param {object} event
   */
  const selectISWFile = (event) => {
    event.preventDefault();
    setDisableIswbtn(true);
    let updatedProjectId = parseInt(projectId);
    let fileName = iswFileInput.current.files[0];
    let formData = new FormData();
    formData.append("file", fileName);
    uploadISWMsg(updatedProjectId, formData)
      .then(() => {
        setFieldValue("isw_xlsx_file_name", iswFileInput.current.files[0].name);
        iswFileInput.current.value = "";
        setColorToggle(true);
        setISWmsg("File uploaded successfully");
        setDisableIswbtn(false);
        setTimeout(() => {
          setISWmsg("");
        }, 5000);
      })
      .catch(() => {
        iswFileInput.current.value = "";
        setColorToggle(false);
        setISWmsg("Error occurred in file upload please try again");
        setDisableIswbtn(false);
        setTimeout(() => {
          setISWmsg("");
        }, 5000);
      });
  };

  return (
    <Grid size={12}>
      <div className="mb-2 mt-2 additional_configuration_accordion">
        <Accordion allowZeroExpanded="true" allowMultipleExpanded="true">
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="row-header">
                  <div>
                    <label className="row-header-title">
                      {t("ISW Options")}
                    </label>
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Grid
                container
                className="internal_view_old pt-4 pb-4"
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid size={3}>
                  <div className="form-group">
                    <label htmlFor="irnVerificationMethodId">
                      {t("IRN verification method")}
                    </label>
                    <Field
                      isDisabled={canUserUpdateField}
                      name={"irnVerificationMethodId"}
                      id="irnVerificationMethodId"
                      onBlur={handleBlur}
                      component={SelectField}
                      options={irnVerificationMethodsOption}
                      values={values.irnVerificationMethodId}
                    />
                  </div>
                </Grid>
                <Grid size={3}>
                  <div className="form-group">
                    <label htmlFor="file">{t("Upload ISW Messages")}</label>
                    <div
                      className="custom-upload"
                      data-permission={canUserUpdateField}
                    >
                      <input
                        name="file"
                        ref={iswFileInput}
                        accept=".xlsx, .xls"
                        type="file"
                        className="configuration-file-input upload-xlsx"
                        onChange={selectISWFile}
                        disabled={disableIswbtn}
                        data-content={t("UPLOAD XLSX")}
                      />
                    </div>

                    {values.isw_xlsx_file_name != null ? (
                      <div className="file_download_link_parent">
                        <p
                          onClick={() =>
                            fileDownload(
                              projectId,
                              "ISW_XLSX",
                              values.isw_xlsx_file_name
                            )
                          }
                        >
                          {values.isw_xlsx_file_name}
                        </p>
                      </div>
                    ) : null}

                    <div className="supervisormsg">
                      {iSWmsg ? (
                        <p className={colorToggle ? "success" : "error"}>
                          {iSWmsg}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </Grid>
  );
};

export default ISW;
