import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Grid2 as Grid,
} from "@mui/material";
import moment from "moment";
import * as FileSaver from "file-saver";
import { sortByLabel } from "../../../Helpers/index";
import {
  exportCallRecords,
  getCallRecordsDirectdial,
} from "../../../Services/executionService";
import { getTenantProjects } from "../../../Services/userServices";
import dataoptions from "../../../assets/icon/dataoptions.png";
import dataoptionsw from "../../../assets/icon/dataoptionsw.png";
import export_icon from "../../../assets/icon/export_icon.png";
import export_iconw from "../../../assets/icon/export_iconw.png";
import { Alert as AlertDismissible, Button } from "../../../components/Common";
import { Date_Time_Format, Send_Api_Date_Format } from "../../../config";
import i18n from "../../../locale/i18n";
import { EnhancedTable } from "../../CustomTable";
import { formatDate } from "../../Utility/DatePickerFunctions";
import FullScreenLoader from "../../Utility/FullScreenLoader";
import StandardDatePicker from "../../Utility/StandardDatePicker";

class ReportsCallrecords extends Component {
  constructor(props) {
    const displayTImeZoneOption = require("../../../config/timezone.json");
    const telcoDropdown = require("../../../config/telco.json");
    const selectRef = null;
    const { t } = props;
    super(props);
    this.state = {
      language: i18n.language,
      isinternal: [],
      reportStartDate: new Date(),
      reportEndDate: new Date(),
      searchText: "",
      accountsDetails: [],
      tenantDropOption: [],
      projectDropDownOption: [],
      perPageCount: 10,
      selectedProjectId: "",
      selectedProjectLabel: "",
      selectedAcccountId: "",
      selectedAcccountLabel: "",
      currentPage: 0,
      displayTimeZone: {
        label: "(GMT-8) Pacific Time (US & Canada)",
        value: "6",
      },
      displayTImeZoneOption: displayTImeZoneOption,
      displayTimeZoneOffset: "-08:00",
      telcoDropdown: telcoDropdown,
      totalPages: 0,
      totalResults: 0,
      callRecordsData: [],
      alertMsg: "",
      alertStatus: false,
      variant: "",
      selectedTelcoId: "",
      minDuration: 240,
      maxDuration: 0,
      noPermissionmsg: "",
      loading: true,
      selectAllCheckbox: false,
      selectAllPageData: false,
      selectedParticipant: [],
      dateErrorMsg: false,
      durationOption: { value: "3", label: `${t("More than 4 minutes")}` },
      isDownloadCallRecords: false,
    };
    this.selectDropdown = this.selectDropdown.bind(this);
  }

  componentDidMount() {
    let userinfo = JSON.parse(localStorage.getItem("userInfo"));
    let internalPermissions = userinfo.rolePermission.permissions;
    let isinternalarray = [];
    internalPermissions.map((item, index) => {
      isinternalarray.push(item.permission);

      this.setState({ isinternal: [...isinternalarray], noPermissionmsg: "" });
    });
    this.props.action("Call Logs");
    getTenantProjects()
      .then((res) => {
        let tenantDropDown = res.data
          .map((option) => {
            return {
              value: option.tenantId,
              label: option.tenantName,
            };
          })
          .sort(sortByLabel);
        this.setState(
          {
            accountsDetails: res.data,
            tenantDropOption: tenantDropDown,
          },
          () => {
            let filters = this.getinitialData();
            this.getCallRecords(filters);
          }
        );
      })
      .catch((err) => {});
  }
  searchCallByText = (event) => {
    let text = event.target.value;
    this.setState((preState) => {
      return { searchText: text };
    });
  };
  getinitialData = () => {
    let initialdata = {
      projectId: this.state.selectedProjectId,
      tenantId: this.state.selectedAcccountId,
      page: this.state.currentPage,
      pageSize: this.state.perPageCount,
      displayTimeZone: this.state.displayTimeZoneOffset,
      startDate: this.state.reportStartDate
        ? moment(this.state.reportStartDate).format(Send_Api_Date_Format)
        : this.state.reportStartDate,
      endDate: this.state.reportEndDate
        ? moment(this.state.reportEndDate).format(Send_Api_Date_Format)
        : this.state.reportEndDate,
      search: this.state.searchText,
      minDuration: this.state.minDuration,
      maxDuration: this.state.maxDuration,
      telcoProvider: this.state.selectedTelcoId,
    };
    return initialdata;
  };
  getOnchangeAccount = (operatingTenant, data) => {
    let selectedAccount = data.filter((item) => {
      return item.tenantId == operatingTenant;
    });
    if (
      selectedAccount[0] != undefined &&
      selectedAccount[0].projects != null
    ) {
      let projectDropDown = selectedAccount[0].projects
        .map((option) => {
          return {
            value: option.projectId,
            label: option.name,
          };
        })
        .sort(sortByLabel);
      return projectDropDown;
    }
  };

  onChangeTenant = (e) => {
    if (e !== null) {
      console.log(e);
      let operatingTenant = e.value;
      let projectDropDown = this.getOnchangeAccount(
        operatingTenant,
        this.state.accountsDetails
      );
      this.setState(
        {
          projectDropDownOption: projectDropDown,
          selectedAcccountId: e.value,
          selectedAcccountLabel: e.label,
          selectedProjectId: "",
          selectedProjectLabel: "",
          currentPage: 0,
          totalResults: 0,
          totalPages: 0,
          alertMsg: "",
          variant: "",
          alertStatus: false,
          loading: true,
        },
        () => {
          let filters = this.getinitialData();
          this.getCallRecords(filters);
        }
      );
    } else {
      //this.selectRef.select.clearValue();
      this.setState(
        {
          projectDropDownOption: [],
          selectedAcccountId: "",
          selectedAcccountLabel: "",
          selectedProjectId: "",
          selectedProjectLabel: "",
          currentPage: 0,
          totalResults: 0,
          totalPages: 0,
          alertMsg: "",
          variant: "",
          alertStatus: false,
        },
        () => {
          let filters = this.getinitialData();
          this.getCallRecords(filters);
        }
      );
    }
  };

  onChangeTenantProject = (e) => {
    console.log(e);
    if (e !== null) {
      this.setState(
        {
          selectedProjectId: e.value,
          selectedProjectLabel: e.label,
          currentPage: 0,
          loading: true,
        },
        () => {
          let filters = this.getinitialData();
          this.getCallRecords(filters);
        }
      );
    } else {
      this.setState(
        {
          selectedProjectId: "",
          selectedProjectLabel: "",
          currentPage: 0,
        },
        () => {
          let filters = this.getinitialData();
          this.getCallRecords(filters);
        }
      );
    }
  };

  onChangeTelco = (e) => {
    if (e !== null) {
      console.log(e);
      this.setState(
        {
          selectedTelcoId: e.value,
        },
        () => {}
      );
    } else {
      this.setState(
        {
          selectedTelcoId: "",
        },
        () => {}
      );
    }
  };

  onChangeRecords = (perPageCount) => {
    this.setState(
      {
        currentPage: perPageCount,
        loading: true,
        selectAll: false,
        selectAllCheckbox: false,
        selectedParticipant: [],
      },
      () => {
        let filters = this.getinitialData();
        this.getCallRecords(filters);
      }
    );
  };

  onPageSizeChange = (event) => {
    const perPageSize = event.target.value;
    this.setState(
      {
        perPageCount: perPageSize,
        currentPage: 0,
        loading: true,
        selectAll: false,
        selectAllCheckbox: false,
        selectedParticipant: [],
      },
      () => {
        let filters = this.getinitialData();
        this.getCallRecords(filters);
      }
    );
  };

  getExportData = () => {
    let exportData = {
      projectId: this.state.selectedProjectId,
      tenantId: this.state.selectedAcccountId,
      page: this.state.currentPage,
      pageSize: this.state.perPageCount,
      displayTimeZone: this.state.displayTimeZoneOffset,
      startDate: this.state.reportStartDate
        ? moment(this.state.reportStartDate).format(Send_Api_Date_Format)
        : this.state.reportStartDate,
      endDate: this.state.reportEndDate
        ? moment(this.state.reportEndDate).format(Send_Api_Date_Format)
        : this.state.reportEndDate,
      search: this.state.searchText,
      minDuration: this.state.minDuration,
      maxDuration: this.state.maxDuration,
      telcoProvider: this.state.selectedTelcoId,
      allRecords: this.state.selectAllPageData,
      callIdsToExport: this.state.selectedParticipant,
    };
    return exportData;
  };

  exportCallRecords = () => {
    this.setState({
      isDownloadCallRecords: true,
    });
    let Obj = this.getExportData();
    exportCallRecords(Obj)
      .then((res) => {
        const blob = new Blob([res.data], { type: "text/csv; charset=utf-8" });
        FileSaver.saveAs(blob, "callrecords.xlsx");
        this.setState({
          isDownloadCallRecords: false,
        });
      })
      .catch((err) => {
        console.log(
          "error in export participant",
          err.code,
          err.stack,
          err.message
        );
        if (err?.response?.status === 500) {
          let errMsg = err?.response?.data?.message;
          this.setState({
            alertMsg: errMsg,
            variant: "danger",
            alertStatus: true,
            isDownloadCallRecords: false,
          });
        } else {
          let errMsg = err.response?.data?.message;
          const buffer = err.response ? "" : Buffer.from(err?.response?.data);
          const object = err.response
            ? ""
            : JSON.parse(buffer.toString("utf-8"));
          this.setState({
            alertMsg:
              object?.status === 409
                ? object?.message
                : errMsg || object?.message,
            variant: "danger",
            alertStatus: true,
            isDownloadCallRecords: false,
          });
        }
      });
  };

  getCallRecords = (filters) => {
    getCallRecordsDirectdial(filters)
      .then((res) => {
        this.setState({
          callRecordsData: [],
        });
        const totalPages = Math.ceil(
          res.data.totalResults / this.state.perPageCount
        );
        if (!res.data.calls.length) {
          this.setState({
            alertMsg: this.props.t("There are no records to show."),
            variant: "success",
            alertStatus: true,
          });
        } else {
          this.setState({
            alertMsg: "",
            variant: "",
            alertStatus: false,
          });
        }
        this.setState(
          {
            callRecordsData: res.data.calls,
            totalResults: res.data.totalResults,
            totalPages: totalPages,
            loading: false,
          },
          () => {}
        );
      })
      .catch((err) => {});
  };

  onChangeTimeZone = (option) => {
    const timearray = this.state.displayTImeZoneOption;
    let filterOffset =
      timearray != undefined &&
      timearray.filter(function (timearray) {
        return timearray.value === option.value;
      });
    console.log(filterOffset);

    this.setState(
      {
        displayTimeZone: option,
        displayTimeZoneOffset: filterOffset[0].offset,
      },
      () => {}
    );
  };
  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  setStartDatepickerValue = (selectedDate) => {
    let startTime = selectedDate;
    let endTime = this.state.reportEndDate;

    this.setState(
      {
        reportStartDate: selectedDate,
      },
      () => {
        if (startTime.setHours(0, 0, 0, 0) > endTime.setHours(0, 0, 0, 0)) {
          this.setState({
            dateErrorMsg: true,
          });
        } else {
          this.setState({
            dateErrorMsg: false,
          });
        }
      }
    );
  };

  setEndDatepickerValue = (selectedDate) => {
    let startTime = this.state.reportStartDate;
    let endTime = selectedDate;
    this.setState(
      {
        reportEndDate: selectedDate,
      },
      () => {
        if (startTime.setHours(0, 0, 0, 0) > endTime.setHours(0, 0, 0, 0)) {
          this.setState({
            dateErrorMsg: true,
          });
        } else {
          this.setState({
            dateErrorMsg: false,
          });
        }
      }
    );
  };
  handleMinDuration = (option) => {
    console.log(option);
    if (option !== null) {
      let value = option.value;
      switch (value) {
        case "1": {
          this.setState({
            durationOption: option,
            maxDuration: 60,
            minDuration: 0,
          });
          break;
        }
        case "2": {
          this.setState({
            durationOption: option,
            maxDuration: 240,
            minDuration: 60,
          });
          break;
        }
        case "3": {
          this.setState({
            durationOption: option,
            maxDuration: 0,
            minDuration: 240,
          });
          break;
        }
      }
    } else {
      this.setState({
        durationOption: null,
        maxDuration: 0,
        minDuration: 0,
      });
    }
  };
  handleFilterApplyChange = () => {
    this.setState(
      {
        currentPage: 0,
        loading: true,
      },
      () => {
        let filters = this.getinitialData();
        this.getCallRecords(filters);
      }
    );
  };

  secondsToMinutes = (seconds) => {
    return seconds ? new Date(seconds * 1000).toISOString().slice(14, 19) : "";
  };

  selectDropdown(e) {
    console.log("select dropdown", e);
    switch (e) {
      case "allRecord":
        this.setState(
          {
            selectAllPageData: true,
            selectAllCheckbox: true,
          },
          () => {
            this.selectAllParticipant(true);
          }
        );
        break;
      case "selected":
        this.selectAllParticipant(true);
        this.setState({
          selectAllCheckbox: true,
          selectAllPageData: false,
        });
        break;
      case "none":
        this.selectAllParticipant(false);
        this.setState(
          {
            selectAllCheckbox: false,
            selectAllPageData: false,
          },
          () => {
            this.setDeleteMsg();
          }
        );
        break;
    }
  }
  selectAllParticipant = (value) => {
    let preSelect = [];
    let pageKey = "page" + this.state.currentPage;
    let keyObj = {};
    this.state.callRecordsData.map((participant) => {
      let cname = `call_${participant.id}`;
      if (value) {
        preSelect = [...preSelect, participant.id];
      } else {
        preSelect = [];
      }
      keyObj = { ...keyObj, [cname]: value };
    });
    this.setState(
      (preState) => {
        return {
          ...preState,
          ...keyObj,
          selectedParticipant: preSelect,
          [pageKey]: preSelect,
        };
      },
      () => {
        console.log(
          "this.state.selectedParticipant",
          this.state.selectedParticipant
        );
      }
    );
  };
  selectAll = (event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    let name = event.target.name;
    this.selectAllParticipant(value);
    if (!value) {
      this.setState((prevState) => {
        return {
          selectAllPageData: value,
        };
      });
    }
    this.setState(
      (prevState) => {
        return {
          [name]: value,
        };
      },
      () => {}
    );
  };

  selectCall = (participant, event) => {
    let selectAll = false;
    let currentPage = this.state.currentPage;
    let name = event.target.name;
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let preParticipant =
      this.state["page" + currentPage] == undefined
        ? []
        : [...this.state["page" + currentPage]];
    if (value) {
      preParticipant.push(participant.id);
    } else {
      // let index = preParticipant.findIndex((d) => d.id == participant.id);
      // preParticipant.splice(index, 1);
      preParticipant = preParticipant.filter((id) => id !== participant.id);
    }
    this.setState(
      (prevState) => {
        return {
          [name]: value,
          ["page" + currentPage]: preParticipant,
          selectedParticipant: preParticipant,
          selectAllPageData: false,
        };
      },
      () => {
        console.log(this.state.selectedParticipant);
        if (
          this.state["page" + currentPage].length ==
          this.state.callRecordsData.length
        ) {
          selectAll = true;
        }
        this.setState((prevState) => {
          return {
            selectAllCheckbox: selectAll,
          };
        });
        this.setDeleteMsg();
      }
    );
  };

  setDeleteMsg = () => {
    for (let i = 0; i < this.state.selectedParticipant.length; i++) {
      let part = this.state.selectedParticipant[i];
      let index = this.state.callRecordsData.findIndex(
        (item) => item.id === part.id
      );
    }
  };

  getRowId = (row) => row.callId;

  render() {
    const { t } = this.props;
    const {
      isDownloadCallRecords,
      selectedParticipant,
      callRecordsData,
      selectAllCheckbox,
    } = this.state;

    let columns = [
      {
        headerName: (
          <>
            <Checkbox
              type="checkbox"
              name="selectAllCheckbox"
              onChange={this.selectAll}
              //className="participant select-all-dropdown"
              indeterminate={
                selectedParticipant[0] !== selectedParticipant[1] &&
                callRecordsData.length !== selectedParticipant.length
              }
              checked={selectAllCheckbox}
            />
          </>
        ),
        field: "check",
        width: 70,
        sortable: false,
        fixedColumnClass: "first_col_fix",
        fixedColumnHeadClass: "first_col_head_fix",
        leftColumnFixed: 0,
        renderCell: (params) => {
          const isRowSelected = (selectedId) => {
            let isRowChecked = false;
            selectedParticipant?.map((id) => {
              if (id === selectedId) {
                isRowChecked = true;
              }
            });
            return isRowChecked;
          };
          return (
            <div value={params.row.id}>
              <span>
                <Checkbox
                  type="checkbox"
                  name={`call_${params.row.id}`}
                  id={`call_${params.row.id}`}
                  //checked={this.state[`call_${params.row.id}`]}
                  checked={isRowSelected(params.row.id)}
                  onChange={(event) => this.selectCall(params.row, event)}
                  className="form-check-input"
                />
                <label className="form-check-label"> </label>
              </span>
            </div>
          );
        },
      },
      {
        headerName: `${t("Irn")}`,
        field: "interviewReferenceNumber",
        width: 150,
        sortable: true,
        fixedColumnClass: "second_col_fix",
        fixedColumnHeadClass: "second_col_fix",
        leftColumnFixed: 70,
      },
      {
        headerName: t("Client Name"),
        field: "tenantName",
        width: 150,
        sortable: false,
      },
      {
        headerName: `${t("Call Date Time")}`,
        field: "callDateTimeString",
        renderCell: (params) =>
          moment(params.row.callDateTimeString).format(Date_Time_Format),
        width: 250,
        sortable: true,
      },
      {
        headerName: `${t("Access Code")}`,
        field: "accessCode",
        width: 250,
        sortable: false,
        renderCell: (params) =>
          params.row.accessCode == undefined ? "-" : params.row.accessCode,
      },
      {
        headerName: `${t("Number Dialed")}`,
        field: "dialInNumber",
        width: 200,
        sortable: true,
      },
      {
        headerName: `${t("Call Duration")}`,
        field: "duration",
        renderCell: (params) =>
          this.secondsToMinutes(params.row.durationSeconds),
        width: 200,
        sortable: true,
      },
      {
        headerName: `${t("DTMFS")}`,
        field: "dtmfs",
        width: 150,
        renderCell: (params) =>
          params.row.dtmfs == undefined ? "-" : params.row.dtmfs,
        sortable: false,
      },
      {
        headerName: `${t("Telco")}`,
        field: "providerName",
        renderCell: (params) => (
          <label className="form-check-label">{params.row.providerName}</label>
        ),
        width: 200,
        sortable: true,
      },
      {
        headerName: `${t("Caller Id")}`,
        field: "callerNumber",
        width: 200,
        sortable: true,
      },
      {
        headerName: `${t("Destination #")}`,
        field: "destinationNumber",
        width: 200,
        sortable: false,
      },
    ];

    const minDuration = [
      { value: "1", label: `${t("Less than 1 minute")}` },
      { value: "2", label: `${t("Between 1 minute and 4 minute")}` },
      { value: "3", label: `${t("More than 4 minutes")}` },
    ];

    return (
      <React.Fragment>
        <div className="w-1200">
          {isDownloadCallRecords && (
            <FullScreenLoader message={"Please Wait"} />
          )}
          {this.state.isinternal &&
          this.state.isinternal.indexOf("tenant.project.report.call.read") >=
            0 ? (
            <React.Fragment>
              <h6 className="account-labels row">
                <Grid size={12}>
                  <span className="float-right ml-4 badge badge-sm badge-primary">
                    {this.state.selectedAcccountLabel != undefined &&
                    this.state.selectedAcccountLabel != ""
                      ? `Account : ${this.state.selectedAcccountLabel}`
                      : ""}
                  </span>
                  <span className="float-right ml-4 badge badge-sm badge-primary">
                    {this.state.selectedProjectLabel != undefined &&
                    this.state.selectedProjectLabel != ""
                      ? `Project : ${this.state.selectedProjectLabel}`
                      : ""}
                  </span>
                </Grid>
              </h6>
              <Grid container className="participants-page">
                <Grid size={12}>
                  <div className="">
                    <Card className="resultsearchindex-high zindex99">
                      <CardHeader
                        title={
                          <Grid container>
                            <div className="col-lg-4">
                              {t("Accounts Options")}
                            </div>
                            <div className="col-lg-2">
                              {t("Display Options")}
                            </div>
                            <div className="col-lg-4">
                              {t("Filter by Date")}
                            </div>
                            <div className="col-lg-2">{t("Table Options")}</div>
                          </Grid>
                        }
                      ></CardHeader>
                      <CardContent>
                        <Grid container>
                          <div className="position-relative col-lg-2">
                            <Select
                              name="status"
                              className="select-style"
                              classNamePrefix="select"
                              options={this.state.tenantDropOption}
                              isClearable={true}
                              placeholder={t("Select Account")}
                              onChange={this.onChangeTenant}
                            />
                          </div>
                          <div className="position-relative col-lg-2">
                            <Select
                              ref={(ref) => {
                                this.selectRef = ref;
                              }}
                              name="location"
                              className="select-style"
                              classNamePrefix="select"
                              options={this.state.projectDropDownOption}
                              isClearable={true}
                              placeholder={t("Select Project")}
                              onChange={this.onChangeTenantProject}
                            />
                          </div>
                          <div className="position-relative col-lg-2">
                            <Select
                              name="location"
                              className="select-style"
                              classNamePrefix="select"
                              options={this.state.displayTImeZoneOption}
                              isClearable={false}
                              value={this.state.displayTimeZone}
                              placeholder={t("Select Timezone")}
                              onChange={this.onChangeTimeZone}
                            />
                          </div>
                          <div className="position-relative col-lg-4">
                            <Grid container>
                              <div className="position-relative col-lg-6">
                                <StandardDatePicker
                                  dateFormat={formatDate()}
                                  placeholderText="Start Date"
                                  autoComplete="off"
                                  className="form-control w-100 "
                                  name="reportStartDate"
                                  onChange={(selectedDate) => {
                                    this.setStartDatepickerValue(selectedDate);
                                  }}
                                  selected={this.state.reportStartDate}
                                  label={t("From")}
                                />
                              </div>
                              <div className="position-relative col-lg-6">
                                <StandardDatePicker
                                  dateFormat={formatDate()}
                                  placeholderText="End Date"
                                  autoComplete="off"
                                  className="form-control w-100 "
                                  name="reportEndDate"
                                  onChange={(selectedDate) => {
                                    this.setEndDatepickerValue(selectedDate);
                                  }}
                                  selected={this.state.reportEndDate}
                                  label={t("To")}
                                />
                              </div>
                            </Grid>
                            {this.state.dateErrorMsg ? (
                              <span className="input-feedback">
                                End Date must be greater than Start Date
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="position-relative col-lg-2">
                            <div className="position-relative">
                              <div className="data-option-container">
                                <div className="data-control">
                                  <div className="d-flex align-items-center download-btn">
                                    <div>
                                      <img
                                        src={
                                          this.props.themeColor === "light"
                                            ? dataoptions
                                            : dataoptionsw
                                        }
                                      />
                                    </div>
                                    <span className="text-uppercase ml-3">
                                      {t("Data Options")}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex flex-column">
                                  <nav className="data-menu nav">
                                    <div className="nav-item">
                                      <div className="d-flex align-items-center download-btn data-item">
                                        <div>
                                          <img
                                            src={
                                              this.props.themeColor === "light"
                                                ? dataoptions
                                                : dataoptionsw
                                            }
                                          />
                                        </div>
                                        <span className="text-uppercase ml-3">
                                          {t("Data Options")}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="nav-item">
                                      <div
                                        className="d-flex align-items-center download-btn data-item"
                                        onClick={
                                          this.state.selectedParticipant
                                            .length > 0
                                            ? this.exportCallRecords
                                            : () => {}
                                        }
                                      >
                                        <div>
                                          <img
                                            src={
                                              this.props.themeColor === "light"
                                                ? export_icon
                                                : export_iconw
                                            }
                                          />
                                        </div>
                                        <span className="text-uppercase ml-3">
                                          {t("Export Records")}
                                        </span>
                                      </div>
                                    </div>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
                <Grid size={12}>
                  <div className="">
                    <Card>
                      <CardHeader
                        title={
                          <Grid container>
                            <div className="col-lg-3">
                              {t("Search For Call")}
                            </div>
                            <div className="col-lg-4"> {t("Filter")}</div>
                          </Grid>
                        }
                      ></CardHeader>
                      <CardContent>
                        <Grid container>
                          <div className="position-relative col-lg-3">
                            <input
                              className="form-control"
                              type="text"
                              placeholder={t("Search")}
                              onChange={this.searchCallByText}
                            />
                          </div>
                          <div className="position-relative col-lg-3">
                            <Select
                              name="status"
                              className="select-style"
                              classNamePrefix="select"
                              options={this.state.telcoDropdown}
                              isClearable={true}
                              placeholder={t("Telco")}
                              onChange={this.onChangeTelco}
                            />
                          </div>
                          <div className="position-relative col-lg-3">
                            <Select
                              name="location"
                              className="select-style"
                              classNamePrefix="select"
                              options={minDuration}
                              value={this.state.durationOption}
                              isClearable={true}
                              placeholder={t("Min Duration")}
                              onChange={this.handleMinDuration}
                            />
                          </div>

                          <div className="position-relative col-lg-1">
                            <Button
                              variant="primary"
                              ref=""
                              onClick={(e) => this.handleFilterApplyChange()}
                              className="text-uppercase btn-sm-text float-right"
                            >
                              {t("Apply")}
                            </Button>
                          </div>
                        </Grid>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid size={12}>
                  <AlertDismissible
                    msg={this.state.alertMsg}
                    variantType={this.state.variant}
                    show={this.state.alertStatus}
                    close={this.handleAlertClose}
                  ></AlertDismissible>
                </Grid>
                <Grid size={12}>
                  <div className="app-table result-module-table">
                    <EnhancedTable
                      columns={columns}
                      data={this.state.callRecordsData}
                      loading={this.state.loading}
                      currentPage={this.state.currentPage}
                      perPageCount={this.state.perPageCount}
                      totalPages={this.state.totalResults}
                      onChangeRecords={this.onChangeRecords}
                      onPageSizeChange={this.onPageSizeChange}
                      isServerSorting={false}
                      selectedCount={selectedParticipant?.length}
                    />
                  </div>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projectList: state.projects.projectList,
    themeColor: state.theme.themeColor,
  };
};
export default withTranslation()(connect(mapStateToProps)(ReportsCallrecords));
