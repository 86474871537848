import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext, ErrorMessage, Field } from "formik";
import { Grid2 as Grid, Checkbox, FormControlLabel, Box } from "@mui/material";
import { useReportContext } from "../context/ReportContext";

const SelectAttributesStep = ({ handleDisable }) => {
  const { t } = useTranslation();
  const { state: reportState } = useReportContext();
  const { values, setFieldValue, handleChange } = useFormikContext();

  const {
    reportTypeList: { data },
  } = reportState;

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectAll(values.attributes.length > 0);
  }, []);

  useEffect(() => {
    if (checkEmptyFields(values)) {
      handleDisable(true);
    } else {
      handleDisable(false);
    }
  }, [values.attributes]);

  const checkEmptyFields = (obj) => {
    return obj.attributes.length === 0;
  };

  const getColumnData =
    data && data.filter((item) => item.table?.key === values.reportType);

  const isIndeterminate = (values) =>
    values.length > 0 && values.length < getColumnData[0].columns.length;

  const handleSelectAll = (setFieldValue) => {
    const allSelected = getColumnData[0].columns.every((opt) =>
      values.attributes.includes(opt.key)
    );

    if (allSelected) {
      setFieldValue("attributes", []);
    } else {
      setFieldValue(
        "attributes",
        getColumnData[0].columns.map((opt) => opt.key)
      );
    }
    setSelectAll(!allSelected);
    handleDisable(false);
  };

  return (
    <div className="content-max-width-stepper">
      <Box sx={{ mb: 2 }}>
        <div className="dialog-step-info">{t("SELECT_ATTRIBUTES_INFO")}</div>
      </Box>
      <Grid container>
        <Grid sx={12}>
          <FormControlLabel
            className="attributes-select-deselect"
            control={
              <Checkbox
                checked={selectAll}
                indeterminate={isIndeterminate(values.attributes)}
                onChange={() =>
                  handleSelectAll(setFieldValue, values.attributes)
                }
              />
            }
            label="Select All"
          />
        </Grid>
        <Grid size={12}>
          <Grid container>
            {getColumnData[0].length !== 0 &&
              getColumnData[0].columns.map((option, index) => (
                <Grid
                  item
                  size={4}
                  className="word-break"
                  key={`render_attributes_${index}`}
                >
                  <FormControlLabel
                    key={option.key}
                    className="attributes-selection"
                    control={
                      <Field
                        as={Checkbox}
                        type="checkbox"
                        name="attributes"
                        value={option.key}
                        checked={values.attributes.includes(option.key)}
                        onChange={(e) => {
                          handleChange(e);
                          setSelectAll(
                            e.target.checked &&
                              values.attributes.length + 1 ===
                                getColumnData[0].columns.length
                          );
                          handleDisable(false);
                        }}
                      />
                    }
                    label={option.name}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        <div className="error">
          <ErrorMessage name="attributes" />
        </div>
      </Grid>
    </div>
  );
};
export default SelectAttributesStep;
