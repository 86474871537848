import React, { useEffect } from "react";
import { connect } from "react-redux";
import Routes from "../Routes";
import { themeActions } from "../store/actions/ThemeAction";
import { authActions } from "../store/actions/authAction";

import "./App.css";
import "./dark_theme.scss";

const App = (props) => {
  useEffect(() => {
    if (localStorage.getItem("theme_preference") !== null) {
      const getThemePreference = localStorage.getItem("theme_preference");
      props.getTheme(getThemePreference);
      if (getThemePreference === "dark") {
        document.body.classList.add("dark_theme");
      }
    } else {
      props.getTheme("light");
      document.body.className = document.body.className.replace(
        "dark_theme",
        ""
      );
    }
  }, [props.themeColor]);

  useEffect(() => {
    props.setAuthorization(false);
  }, []);

  return (
    <div
      className={`${
        props.themeColor === "light" ? "light_theme" : "dark_theme"
      }`}
    >
      <Routes />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    themeColor: state.theme.themeColor,
  };
};
const mapDispatchToProps = {
  getTheme: themeActions.getTheme,
  setAuthorization: authActions.setAuthorization,
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
