import React from "react";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";
import { Pagination as MuiPagination, Typography } from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import "./table.css";

const CustomGrid = ({
  columns,
  data = [],
  loading = false,
  onChangeRecords,
  perPageCount,
  currentPage,
  totalPages,
  isServerPagination,
  getRowId,
  isServerSorting,
  onSortModelChange,
  onRowClick,
  searchBar,
  checkboxSelection,
  handleRowSelectable,
  onRowSelectionModelChange,
  rowHeight,
  selectedCount = 0,
  selectionModel,
  disableMultipleRowSelection = false,
}) => {
  const { t } = useTranslation();
  const customOverlay = () => {
    return <div className="table-empty-container">{t("NO_RECORD_FOUND")}</div>;
  };

  const CustomFooter = () => {
    const Pagination = ({ page, onPageChange, className }) => {
      const totalPageCount = Math.ceil(totalPages / perPageCount);
      return (
        <MuiPagination
          color="primary"
          className={className}
          count={totalPageCount}
          page={page + 1}
          onChange={(event, newPage) => {
            onPageChange(event, newPage - 1);
          }}
        />
      );
    };
    return (
      <GridFooterContainer>
        <Typography className="pagination-footer-text">
          {selectedCount !== 0 &&
            `${selectedCount} row${selectedCount !== 1 ? "s" : ""} selected`}
        </Typography>
        <GridPagination ActionsComponent={Pagination} />
      </GridFooterContainer>
    );
  };

  const UnsortedIcon = ({ className }) => {
    return <SwapVertIcon className={className} />;
  };

  return (
    <>
      <DataGrid
        rows={data ? data : []}
        checkboxSelection={checkboxSelection}
        columns={columns}
        getRowId={getRowId}
        rowCount={Number(totalPages)}
        paginationModel={{ page: currentPage, pageSize: perPageCount }}
        paginationMode={isServerPagination ? "server" : "client"}
        sortingMode={isServerSorting ? "server" : "client"}
        pageSizeOptions={[10, 25, 50, 100]}
        disableColumnMenu
        loading={loading}
        columnHeaderHeight={70}
        onPaginationModelChange={onChangeRecords}
        onSortModelChange={onSortModelChange}
        rowHeight={rowHeight ? rowHeight : 50}
        sx={{
          "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
            width: "auto",
          },
          "& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon":
            {
              opacity: 0.5,
            },
        }}
        localeText={{
          MuiTablePagination: {
            labelRowsPerPage: t("ROWS_PER_PAGE"),
          },
        }}
        slots={{
          noRowsOverlay: customOverlay,
          toolbar: searchBar,
          footer: CustomFooter,
          columnUnsortedIcon: UnsortedIcon,
        }}
        onRowClick={onRowClick}
        isRowSelectable={handleRowSelectable}
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowSelectionModel={selectionModel}
        disableMultipleRowSelection={disableMultipleRowSelection}
      />
    </>
  );
};
export default CustomGrid;
