import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../store/actions/authAction";
import { BASE_URL } from "../config";
import { useAuth0 } from "@auth0/auth0-react";
const api = axios.create({
  baseURL: BASE_URL,
});

const ApiInterceptor = ({ children }) => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  useEffect(() => {
    const responseInterceptor = (response) => {
      const isAuthorization = response?.data?.authorization?.application;
      const byPassURLs = [
        "user-service/v1/user/authorizer",
        "user-service/v1/user/login",
        "user-service/v1/user/logout",
      ];
      if (byPassURLs.includes(response?.config?.url)) {
        return response;
      }
      if (isAuthorization === undefined) {
        return response;
      } else {
        dispatch(authActions.setAuthorization(true));
      }
    };

    const errorInterceptor = (error) => {
      if (error.response) {
        const { status } = error.response;

        if (status === 401) {
          dispatch(authActions.logOut());
          logout();
        } else if (status === 500) {
          // Handle 500 server error
          error.response = {
            data: {
              message:
                "Something went wrong! We are unable to process your request at this time. Please contact Clearspeed support for assistance.",
            },
            status: 500,
          };
        }
      }

      return Promise.reject(error);
    };

    const requestInterceptor = (config) => {
      const token = JSON.parse(localStorage.getItem("token"));

      if (token) {
        config.headers["Authorization"] = `Bearer ${token.access_token}`;
        config.headers["Id-Token"] = token.id_token;
        //config.headers['Content-Type'] = contentType;
      } else {
        dispatch(authActions.logOut());
        logout();
      }

      return config;
    };

    const responseInterceptorId = api.interceptors.response.use(
      responseInterceptor,
      errorInterceptor
    );
    const requestInterceptorId =
      api.interceptors.request.use(requestInterceptor);

    return () => {
      api.interceptors.response.eject(responseInterceptorId);
      api.interceptors.request.eject(requestInterceptorId);
    };
  }, [dispatch]);

  return children;
};

export default api;
export { ApiInterceptor };
