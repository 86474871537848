import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Grid2 as Grid } from "@mui/material";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../../components/Common";
import {
  filterJson,
  scrollTopWindow,
  showPrivilegeField,
} from "../../../Helpers/index";
import { createUser } from "../../../Services/userServices";
import PreventLeaveRoute from "../../Utility/PreventLeaveRoute.js";
import { SelectField } from "../../Utility/selectField";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMsg: "",
      alertStatus: false,
      variant: "",
      dirty: false,
      disablesavebtn: false,
      isPrivilegeFieldEnable: false,
    };
  }

  componentDidMount() {
    this.setState({ isPrivilegeFieldEnable: showPrivilegeField(this.props) });
  }
  cancelUserClick = () => {
    this.props.updateNavBar("User");
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  dirtyFlagChanged = (dirty) => {
    if (dirty != this.state.dirty) {
      this.setState({
        dirty: dirty,
      });
      this.props.dataFromParent.handleChildDirtyGFlag(dirty);
    }
  };

  cancelUserClick = () => {
    this.props.updateNavBar("User");
  };

  showUserList = () => {
    setTimeout(() => {
      this.cancelUserClick();
    }, 2000);
  };

  handleAddUser = (fields, tenantId, resetForm) => {
    this.setState({
      disablesavebtn: true,
    });
    const isPrivilegeFieldEnable = this.state.isPrivilegeFieldEnable;
    createUser(fields, tenantId, isPrivilegeFieldEnable)
      .then((res) => {
        this.setState({
          alertMsg: `User ${res.data.firstName} ${res.data.lastName} Created Successfully`,
          variant: "success",
          alertStatus: true,
          projectDetail: res.data,
          dirty: false,
          disablesavebtn: false,
        });
        scrollTopWindow();
        this.showUserList();
        this.props.dataFromParent.handleChildDirtyGFlag(false);
        resetForm();
      })
      .catch((err) => {
        let errMsg = Object.assign({}, err).response?.data?.message;
        this.setState(
          {
            alertMsg: errMsg,
            variant: "danger",
            alertStatus: true,
            disablesavebtn: false,
          },
          () => {
            scrollTopWindow();
          }
        );
      });
  };

  createDropdownOption = (option) => {
    return {
      value: option.attributeId,
      label: option.description,
      sequence: option.sequence,
      selected: option.sequence === 1 ? true : false,
    };
  };

  render() {
    const { t } = this.props;
    let userRolesOption = this.props.dataFromParent.statedata.roles;
    userRolesOption = userRolesOption.filter((option) => {
      if (
        option.name !== "Call Center Admin" &&
        option.name !== "Call Center Supervisor" &&
        option.name !== "Call Center Agent"
      ) {
        return option;
      }
    });
    const tenantId = this.props.dataFromParent.statedata.tenantId;
    const userStatusOption = [
      { id: 1, value: true, label: t("Active"), status: true },
      { id: 2, value: false, label: t("Inactive"), status: false },
    ];

    let specialPrivilegeOption = filterJson(
      this.props.dataFromParent.tenantAttributes,
      "SPECIAL_PRIVILEGE"
    );

    let sortSpecialPrivilegeOption = specialPrivilegeOption
      .map((option) => {
        return this.createDropdownOption(option);
      })
      .sort((a, b) => a.sequence - b.sequence);

    let defaultSpecialPrivilegeOption = sortSpecialPrivilegeOption.filter(
      (option) => option.label === "No Privilege Access"
    );

    const isSystemTenant =
      this.props.dataFromParent?.statedata?.userInfo?.tenant?.isSystemTenant;

    return (
      <Formik
        enableReinitialize
        initialValues={{
          firstName: "",
          lastName: "",
          title: "",
          email: "",
          roleId: "",
          status: userStatusOption[0].value,
          privilegeId:
            sortSpecialPrivilegeOption.length > 0
              ? defaultSpecialPrivilegeOption[0].value
              : "",
          isUkEnvironment: this.state.isPrivilegeFieldEnable,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required(t("First Name is required")),
          lastName: Yup.string().required(t("Last Name is required")),
          title: Yup.string().required(t("Title is required")),
          email: Yup.string()
            .email(t("Email is invalid"))
            .required(t("Email is invalid")),
          roleId: Yup.string().required(t("Please select role")),
          isUkEnvironment: Yup.boolean(),
          privilegeId: Yup.string().when("isUkEnvironment", {
            is: () => true,
            then: () =>
              Yup.string().required("Special Privilege is Required").nullable(),
            otherwise: Yup.string().nullable(),
          }),
        })}
        onSubmit={(fields, { resetForm }) => {
          this.handleAddUser(fields, tenantId, resetForm);
        }}
        render={({
          errors,
          values,
          touched,
          handleReset,
          dirty,
          handleBlur,
          setFieldValue,
        }) => (
          <Form onChange={this.dirtyFlagChanged(dirty)}>
            <PreventLeaveRoute
              reset={handleReset}
              when={dirty}
              // Navigate function
              navigate={(field, historyObj) => {
                if (field == "/main/admin/users") {
                  this.cancelUserClick();
                } else {
                  historyObj.push(field);
                }
              }}
              // Use as "message" prop of Prompt of React-Router
              shouldBlockNavigation={(location) => {
                if (location.pathname === "/") {
                  return false;
                }
                return !!(dirty || this.state.dirty);
              }}
            />
            <Grid container>
              <Grid size={12}>
                <AlertDismissible
                  msg={this.state.alertMsg}
                  variantType={this.state.variant}
                  show={this.state.alertStatus}
                  close={this.handleAlertClose}
                ></AlertDismissible>
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid size={4}>
                <div className="form-group">
                  <label className="required" htmlFor="firstName">
                    {t("FIRST NAME")}
                  </label>
                  <Field
                    name="firstName"
                    autoComplete="off"
                    type="text"
                    className={
                      "form-control" +
                      (errors.firstName && touched.firstName
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {errors.firstName && touched.firstName && (
                    <div className="input-feedback add-tenant-validation">
                      {errors.firstName}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid size={4}>
                <div className="form-group">
                  <label className="required" htmlFor="lastName">
                    {t("LAST NAME")}
                  </label>
                  <Field
                    name="lastName"
                    autoComplete="off"
                    type="text"
                    className={
                      "form-control" +
                      (errors.lastName && touched.lastName
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {errors.lastName && touched.lastName && (
                    <div className="input-feedback add-tenant-validation">
                      {errors.lastName}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid size={4}>
                <div className="form-group">
                  <label className="required" htmlFor="email">
                    {t("EMAIL")}
                  </label>
                  <Field
                    name="email"
                    type="text"
                    autoComplete="off"
                    onChange={(e) =>
                      setFieldValue("email", e.target.value.toLowerCase())
                    }
                    className={
                      "form-control" +
                      (errors.email && touched.email
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {errors.email && touched.email && (
                    <div className="input-feedback add-tenant-validation">
                      {errors.email}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid size={4}>
                <div className="form-group">
                  <label className="required" htmlFor="title">
                    {t("TITLE")}
                  </label>
                  <Field
                    name="title"
                    type="text"
                    autoComplete="off"
                    className={
                      "form-control" +
                      (errors.title && touched.title
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {errors.title && touched.title && (
                    <div className="input-feedback add-tenant-validation">
                      {errors.title}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid size={4}>
                <div className="form-group roleselect">
                  <label className="required" htmlFor="roleId">
                    {t("ROLE")}
                  </label>
                  <Field
                    name={"roleId"}
                    onBlur={handleBlur}
                    component={SelectField}
                    options={userRolesOption}
                    values={values.roleId}
                  />
                  {errors.roleId && touched.roleId && (
                    <div className="input-feedback add-tenant-validation">
                      {errors.roleId}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid size={4}>
                <div className="form-group">
                  <label className="required" htmlFor="status">
                    {t("STATUS")}
                  </label>
                  <Field
                    name={"status"}
                    onBlur={handleBlur}
                    component={SelectField}
                    options={userStatusOption}
                    values={values.status}
                  />
                  {errors.status && touched.status && (
                    <div className="input-feedback">{errors.status}</div>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid size={4}>
                {this.state.isPrivilegeFieldEnable ? (
                  <div className="form-group">
                    <label className="required" htmlFor="special_privilege">
                      {t("Special Privilege")}
                    </label>
                    <Field
                      name={"privilegeId"}
                      onBlur={handleBlur}
                      component={SelectField}
                      options={sortSpecialPrivilegeOption}
                      values={values.privilegeId}
                    />
                    {errors.privilegeId && touched.privilegeId && (
                      <div className="input-feedback">{errors.privilegeId}</div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid size={12}>
                <div className="form-group float-right">
                  <Button
                    variant="primary"
                    type="submit"
                    className="rounded-0 mr-4 save-btn button_with_loader"
                    disabled={this.state.disablesavebtn}
                  >
                    {this.state.disablesavebtn ? (
                      <>
                        {t("Save")}...
                        <div className="custom-loader linesLoader loader_inner_button">
                          <Spinner color="#ffffff" size={"19px"} />
                        </div>
                      </>
                    ) : (
                      <span>{t("Save")}</span>
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    className="rounded-0 cancel-btn"
                    onClick={this.cancelUserClick}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  }
}

export default withTranslation()(AddUser);
