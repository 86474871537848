import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { faCopy, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { Card, Grid2 as Grid, CardContent, CardHeader } from "@mui/material";
import moment from "moment";
import * as FileSaver from "file-saver";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/adventure_time.css";
import * as XLSX from "xlsx";
import { sortByLabel } from "../../../Helpers/index";
import { getAuditLog } from "../../../Services/auditService";
import {
  getFilterActivity,
  getTenantProjects,
} from "../../../Services/userServices";
import dataoptions from "../../../assets/icon/dataoptions.png";
import dataoptionsw from "../../../assets/icon/dataoptionsw.png";
import export_icon from "../../../assets/icon/export_icon.png";
import export_iconw from "../../../assets/icon/export_iconw.png";
import { Button, ModalPopUp } from "../../../components/Common";
import { Send_Api_Date_Format } from "../../../config";
import { CustomGrid } from "../../CustomTable";
import { formatDate } from "../../Utility/DatePickerFunctions";
import StandardDatePicker from "../../Utility/StandardDatePicker";

class AuditLog extends React.Component {
  constructor(props) {
    super(props);
    const displayTImeZoneOption = require("../../../config/timezone.json");
    this.state = {
      auditRecordsData: [],
      displayTImeZoneOption: displayTImeZoneOption,
      displayTimeZone: {
        label: "(GMT-8) Pacific Time (US & Canada)",
        value: "6",
      },
      displayTimeZoneOffset: "-08:00",
      reportStartDate: moment(new Date()).format(Send_Api_Date_Format),
      reportEndDate: moment(new Date()).format(Send_Api_Date_Format),
      start_event_time: moment().startOf("day"),
      end_event_time: moment().endOf("day").unix(),
      loading: true,
      perPageCount: 10,
      totalPages: 10,
      currentPage: 0,
      rowData: null,
      show: false,
      selectedAcccountId: "",
      activity: "",
      searchText: "",
      dateErrorMsg: false,
      clipmsg: "",
      TenantName: "",
    };
  }

  componentDidMount() {
    this.props.action("Audit Log");
    getTenantProjects()
      .then((res) => {
        console.log("getTenantProjects", res);
        let tenantDropDown = res.data
          .map((option) => {
            return {
              value: option.tenantId,
              label: option.tenantName,
            };
          })
          .sort(sortByLabel);
        this.setState(
          {
            accountsDetails: res.data,
            tenantDropOption: tenantDropDown,
          },
          () => {
            let filters = this.getinitialData();
            this.getAuditRecords(filters);
          }
        );
      })
      .catch((err) => {});

    getFilterActivity()
      .then((res) => {
        let FilterDropDown = res.data
          .map((option) => {
            return {
              value: option,
              label: option,
            };
          })
          .sort(sortByLabel);
        this.setState(
          {
            filterDropOption: FilterDropDown,
          },
          () => {}
        );
      })
      .catch((err) => {});
  }

  ExportCSV = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = mm + "/" + dd + "/" + yyyy;
    console.log(today);
    let userTenantName = JSON.parse(localStorage.getItem("userInfo")).tenant
      .tenantName;
    if (this.state.TenantName == null) {
      this.setState({
        TenantName: userTenantName,
      });
    }

    const csvData = this.state.auditRecordsData;
    const fileName = userTenantName + "_" + today + "_" + "auditlog";
    this.exportToCSV(csvData, fileName);
  };
  exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  searchResultsByText = (event) => {
    let text = event.target.value;
    this.setState(
      (preState) => {
        return { searchText: text };
      },
      () => {}
    );
  };
  getinitialData = () => {
    let environment = process.env.NODE_ENV === "development" ? "dev" : "prod";
    let eventStartTime = moment(this.state.start_event_time)
      .utcOffset(this.state.displayTimeZoneOffset)
      .format("MM/DD/YYYY HH:mm:ss");
    let tenantId = JSON.parse(localStorage.getItem("userInfo")).tenantId;
    console.log(eventStartTime);
    let initialdata = {
      environment: environment,
      start_event_time: moment(eventStartTime).unix(),
      end_event_time: this.state.end_event_time,
      limit: this.state.perPageCount,
      tenantId: tenantId,
    };
    if (this.state.selectedAcccountId != "") {
      initialdata.tenantId = this.state.selectedAcccountId;
    }
    if (this.state.searchText != "") {
      initialdata.email = this.state.searchText;
    }
    if (this.state.activity != "") {
      initialdata.activity = this.state.activity;
    }
    return initialdata;
  };
  rowModalData = (rowData) => {
    this.setState({
      show: true,
      rowData: rowData,
      complete_msg: JSON.parse(rowData.complete_msg),
    });
  };

  hideModal = () => {
    this.setState({
      show: false,
      clipmsg: "",
    });
  };

  handleCopy = (el) => {
    const data = el.props.data;
    let textArea = document.createElement("textarea");
    textArea.width = "1px";
    textArea.height = "1px";
    textArea.background = "transparents";
    textArea.value = data;
    document.body.append(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    this.setState({
      clipmsg: "Text Copied to Clipboard",
    });
  };
  handleActivity = (option) => {
    this.setState({
      activity: option !== null ? option.value : "",
    });
  };

  setStartDatepickerValue = (d) => {
    this.setState(
      {
        start_event_time: d,
        reportStartDate: d,
      },
      () => this.dateCompare()
    );
  };

  setEndDatepickerValue = (d) => {
    this.setState(
      {
        end_event_time: moment(d).endOf("day").unix(),
        reportEndDate: d,
      },
      () => this.dateCompare()
    );
  };
  dateCompare = () => {
    let startTime = moment(this.state.start_event_time).unix();
    let endTime = moment(this.state.reportEndDate).endOf("day").unix();
    this.setState({
      dateErrorMsg: startTime > endTime ? true : false,
    });
  };

  onChangeTenant = (e) => {
    this.setState(
      {
        selectedAcccountId: e !== null ? e.value : "",
        selectedAcccountLabel: e !== null ? e.label : "",
        selectedProjectId: "",
        selectedProjectLabel: "",
        currentPage: 0,
        totalPages: 0,
        alertMsg: "",
        variant: "",
        alertStatus: false,
        loading: false,
      },
      () => {
        let filters = this.getinitialData();
        this.getAuditRecords(filters);
      }
    );
  };

  handlefilterApplyChange = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        let filters = this.getinitialData();
        this.getAuditRecords(filters);
      }
    );
  };

  onChangeRecords = (perPageCount) => {
    this.setState(
      {
        perPageCount: perPageCount.pageSize,
        currentPage: perPageCount.page,
        loading: true,
      },
      () => {
        let filters = this.getinitialData();
        this.getAuditRecords(filters);
      }
    );
  };

  onColumnSort = (type, { sortField, sortOrder, data }) => {
    console.log(type, sortField, sortOrder, data);
    let Sortobeject = {};
    if (sortField == "interviewReferenceNumber")
      Sortobeject["irnno"] = sortOrder;
    if (sortField == "phoneCallDuration") Sortobeject["duration"] = sortOrder;
    this.setState(
      (preState) => {
        return {
          ...preState,
          sortOrder: Sortobeject,
          loading: false, // true when api integrate
        };
      },
      () => {}
    );
  };

  getAuditRecords = (filters) => {
    getAuditLog(filters, this.state.perPageCount)
      .then((res) => {
        this.setState({
          auditRecordsData: [],
        });
        console.log("call-auditlog", res.data.data);
        if (!res.data.data.length) {
          this.setState({
            alertMsg: this.props.t("There are no records to show."),
            variant: "success",
            alertStatus: true,
            loading: false,
          });
        } else {
          this.setState({
            alertMsg: "",
            variant: "",
            alertStatus: false,
            loading: false,
          });
        }
        this.setState(
          {
            auditRecordsData: res.data.data,
            loading: false,
          },
          () => {}
        );
      })
      .catch((err) => {
        console.log("initial err", err);
        this.setState({
          alertMsg: err.message,
          variant: "error",
          alertStatus: true,
          loading: false,
          auditRecordsData: [],
        });
      });
  };

  onChangeTimeZone = (option) => {
    const timearray = this.state.displayTImeZoneOption;
    let filterOffset =
      timearray != undefined &&
      timearray.filter(function (timearray) {
        return timearray.value === option.value;
      });
    this.setState(
      {
        displayTimeZone: option,
        displayTimeZoneOffset: filterOffset[0].offset,
      },
      () => {
        console.log("off set on timezone", this.state);
      }
    );
  };
  getAuditrecordsRows = (data) => {
    let row = data.map((audData) => {
      return {
        clientname: audData.complete_msg,
        email: audData.email,
        activity: audData.activity,
        ip: audData.ip,
        complete_msg: audData.complete_msg,
        event_time: audData.event_time,
        payload: audData,
      };
    });
    return row;
  };
  render() {
    let JSONPrettyMon = require("react-json-pretty/themes/adventure_time.css");
    let { t } = this.props;
    let rows = this.getAuditrecordsRows(this.state.auditRecordsData);
    let columns = [
      {
        headerName: `${t("Client Name")}`,
        field: "clientname",
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          return <pre>{JSON.parse(params.value).audit_msg.tenantName}</pre>;
        },
      },
      {
        headerName: `${t("Email")}`,
        field: "email",
        sortable: true,
        flex: 1,
      },
      {
        headerName: `${t("Date")}`,
        field: "event_time",
        sortable: false,
        flex: 1,
        renderCell: (params) =>
          params.value != null
            ? moment
                .unix(params.value / 1000)
                .utcOffset(this.state.displayTimeZoneOffset)
                .format("MM/DD/YYYY HH:mm:ss")
            : "-",
      },
      {
        headerName: `${t("Activity")}`,
        field: "activity",
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t("IP")}`,
        field: "ip",
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t("Token Issue Time")}`,
        field: "complete_msg",
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          const msg =
            JSON.parse(params.value).audit_msg.tokenIssueDateTimeMS != null
              ? moment(JSON.parse(params.value).audit_msg.tokenIssueDateTimeMS)
                  .utcOffset("-07:00")
                  .format("MM/DD/YYYY HH:mm:ss")
              : "-";
          return <pre>{msg}</pre>;
        },
      },
      {
        headerName: `${t("Payload")}`,
        field: "payload",
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return (
            <div className="text-center">
              <FontAwesomeIcon
                icon={faInfoCircle}
                onClick={() => this.rowModalData(params.value)}
                style={{ cursor: "pointer" }}
              />
            </div>
          );
        },
      },
    ];

    return (
      <div className="w-1200">
        <h6 className="account-labels row">
          <Grid size={12}>
            <span className="float-right ml-4 badge badge-sm badge-primary">
              {this.state.selectedAcccountLabel != undefined &&
              this.state.selectedAcccountLabel != ""
                ? `Account : ${this.state.selectedAcccountLabel}`
                : ""}
            </span>
          </Grid>
        </h6>
        <Grid container className="participants-page">
          <Grid size={12}>
            <div className="">
              <Card>
                <CardHeader
                  title={
                    <Grid container>
                      <div className="col-lg-3"> {t("Accounts Options")}</div>
                      <div className="col-lg-3"> {t("Display Options")}</div>
                      <div className="col-lg-4">
                        {" "}
                        {t("Filter by Date Range")}
                      </div>
                      <div className="col-lg-2"> {t("Table Options")}</div>
                    </Grid>
                  }
                ></CardHeader>
                <CardContent className="resultsearchindex pt-2">
                  <Grid container>
                    <div className="position-relative col-lg-3">
                      <Select
                        name="status"
                        className="select-style"
                        classNamePrefix="select"
                        options={this.state.tenantDropOption}
                        isClearable={true}
                        placeholder={t("Select Account")}
                        onChange={this.onChangeTenant}
                      />
                    </div>
                    <div className="position-relative col-lg-3">
                      <Select
                        name="location"
                        className="select-style"
                        classNamePrefix="select"
                        options={this.state.displayTImeZoneOption}
                        isClearable={false}
                        value={this.state.displayTimeZone}
                        placeholder={t("Select Timezone")}
                        onChange={(option) => this.onChangeTimeZone(option)}
                      />
                    </div>
                    <div className="position-relative col-lg-4">
                      <Grid container>
                        <div className="position-relative col-lg-6">
                          <StandardDatePicker
                            dateFormat={formatDate()}
                            placeholderText="Start Date"
                            className="form-control w-100 "
                            autoComplete="off"
                            name="start_event_time"
                            onChange={(d) => {
                              this.setStartDatepickerValue(d);
                            }}
                            selected={this.state.start_event_time}
                            label={t("From")}
                          />
                        </div>
                        <div className="position-relative col-lg-6">
                          <StandardDatePicker
                            dateFormat={formatDate()}
                            placeholderText="End Date"
                            className="form-control w-100 "
                            autoComplete="off"
                            name="reportEndDate"
                            onChange={(d) => {
                              this.setEndDatepickerValue(d);
                            }}
                            selected={this.state.reportEndDate}
                            label={t("To")}
                          />
                        </div>
                      </Grid>
                      {this.state.dateErrorMsg ? (
                        <span className="input-feedback">
                          End Date must be greater than Start Date
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="position-relative col-lg-2">
                      <div className="position-relative">
                        <div className="data-option-container">
                          <div className="data-control">
                            <div className="d-flex align-items-center download-btn">
                              <div>
                                <img
                                  src={
                                    this.props.themeColor === "light"
                                      ? dataoptions
                                      : dataoptionsw
                                  }
                                />
                              </div>
                              <span className="text-uppercase ml-3">
                                {t("Data Options")}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <nav className="data-menu nav">
                              <div className="nav-item">
                                <div className="d-flex align-items-center download-btn data-item">
                                  <div>
                                    <img
                                      src={
                                        this.props.themeColor === "light"
                                          ? dataoptions
                                          : dataoptionsw
                                      }
                                    />
                                  </div>
                                  <span className="text-uppercase ml-3">
                                    {t("Data Options")}
                                  </span>
                                </div>
                              </div>
                              <div className="nav-item">
                                <div
                                  className="d-flex align-items-center download-btn data-item"
                                  onClick={(e) => this.ExportCSV()}
                                >
                                  <div>
                                    <img
                                      src={
                                        this.props.themeColor === "light"
                                          ? export_icon
                                          : export_iconw
                                      }
                                    />
                                  </div>
                                  <span className="text-uppercase ml-3">
                                    {t("Export Records")}
                                  </span>
                                </div>
                              </div>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
          <Grid size={12}>
            <div className="">
              <Card>
                <CardHeader
                  title={
                    <Grid container>
                      <div className="col-lg-4"> {t("Search For Email")}</div>
                      <div className="col-lg-5"> {t("Filter")}</div>
                    </Grid>
                  }
                ></CardHeader>
                <CardContent>
                  <Grid container className="align-items-end">
                    <div className="position-relative col-lg-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("Search For Email")}
                        onChange={this.searchResultsByText}
                      />
                    </div>
                    <div className="position-relative col-lg-4">
                      <Select
                        name="activity"
                        className="select-style"
                        classNamePrefix="select"
                        options={this.state.filterDropOption}
                        isClearable={true}
                        placeholder={t("Select Activity")}
                        onChange={this.handleActivity}
                      />
                    </div>
                    <div className="position-relative col-lg-1">
                      <Button
                        variant="primary"
                        ref=""
                        onClick={(e) => this.handlefilterApplyChange()}
                        className="text-uppercase btn-sm-text float-right"
                      >
                        {t("Apply")}
                      </Button>
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid size={12}>
            <Card>
              <div className="table_wrapper custom_table_container app-table">
                <ModalPopUp
                  show={this.state.show}
                  onHide={this.hideModal}
                  title="Payload Details"
                >
                  <div className="clearfix">
                    <div className="clearfix">
                      <a
                        className="rounded-0 pull-right btn btn-primary btn-sm text-white mb-1"
                        onClick={() => this.handleCopy(this.sample)}
                      >
                        <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                        {t("Copy")}
                      </a>
                      <span className="ml-2">{this.state.clipmsg}</span>
                    </div>
                    <pre>
                      <JSONPretty
                        id="json-pretty"
                        theme={JSONPrettyMon}
                        ref={(r) => (this.sample = r)}
                        data={
                          this.state.rowData != null
                            ? this.state.rowData.complete_msg
                            : "NO Data Found"
                        }
                      ></JSONPretty>
                    </pre>
                  </div>
                  <div>
                    <Button variant="secondary" onClick={this.hideModal}>
                      Close
                    </Button>
                  </div>
                </ModalPopUp>
                <CustomGrid
                  columns={columns}
                  data={rows}
                  currentPage={this.state.currentPage}
                  perPageCount={this.state.perPageCount}
                  totalPages={this.state.auditRecordsData?.length}
                  onChangeRecords={this.onChangeRecords}
                  isServerPagination={true}
                  loading={this.state.loading}
                  getRowId={(row) => row}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    themeColor: state.theme.themeColor,
  };
};
export default withTranslation()(connect(mapStateToProps)(AuditLog));
