import React from "react";
import { Grid2 as Grid } from "@mui/material";
import LeftSideFilters from "./LeftSideFilters";
import RightSideFilters from "./RightSideFilters";

const ResultsFilters = (props) => {
  return (
    <Grid container className="participants-page zindex99">
      <Grid className="flex70 pr-3">
        <LeftSideFilters {...props} />
      </Grid>
      <Grid className="flex30">
        <RightSideFilters {...props} />
      </Grid>
    </Grid>
  );
};
export default ResultsFilters;
