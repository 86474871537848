import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid2 as Grid } from "@mui/material";
import * as FileSaver from "file-saver";
import moment from "moment";
import { Alert as AlertDismissible, Button } from "../../../components/Common";
import { Date_Time_Format } from "../../../config";
import {
  deleteProjectQuestions,
  getProjectQuestions,
  getQuestionsApproved,
  getQuestionsDownload,
  updateProjectQuestions,
} from "../../../Services/projectServices";
import { filenameFromResponseHeader } from "../../../shared/utils";
import { IsConfirm } from "../../Utility/ModalPopUp";
import PreventLeaveRoute from "../../Utility/PreventLeaveRoute";

class ProjectQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionDetail: [],
      projectId: "",
      postdata: {},
      addQuestion: false,
      newQuestiondatatext: "",
      newQuestionEmpty: false,
      newQuestiondatapertinent: true,
      newQuestiondataexpanswer: "No",
      newQuestiondatasequence: 0,
      alertMsg: "",
      alertStatus: false,
      variant: "",
      isinternal: [],
      noPermissionmsg: "Loading...",
      disableSaveBtn: true,
      disableAddBtn: false,
      disabledeletBtn: false,
      disableApproveBtn: false,
      userInfostate: JSON.parse(localStorage.getItem("userInfo")),
      stageList: [],
      projectDetailB: {},
      isConfirm: false,
      isApproveConfirm: false,
      showPara: true,
      questionApproveDetail: "",
      questionsEditable: true,
      disablePointerEvent: false,
      userPermissions: [],
      TenantName: "",
    };
    this.handlePertinentChange = this.handlePertinentChange.bind(this);
    this.handleChangetextarea = this.handleChangetextarea.bind(this);
    this.saveQuestionList = this.saveQuestionList.bind(this);
    this.textarea = [];
  }

  getSequence = () => {
    let data = this.state.questionDetail;
    return data.map((d) => d.sequence);
  };

  getMinSequence = () => {
    console.log("getSequence", this.getSequence().length);
    if (this.getSequence().length > 0) {
      return Math.min(...this.getSequence());
    } else {
      return 1;
    }
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };
  deleteQuestionList = (id) => {
    const newqarray3 = this.state.questionDetail.filter(function (obj) {
      return obj.projectQuestionId !== id;
    });
    this.setState({
      questionDetail: newqarray3,
    });
    deleteProjectQuestions(id, this.state.projectId)
      .then((res) => {
        this.setState({
          questionDetail: res.data.questions,
          alertMsg: "Question Deleted Successfully",
          variant: "success",
          alertStatus: true,
          isConfirm: false,
        });
      })
      .catch((err) => {
        let errMsg = err?.response?.data?.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
          isConfirm: false,
        });
      });
  };

  saveQuestionList = (data) => {
    const isNewQuestion = this.state.addQuestion;
    const newQuestiondatatext = this.state.newQuestiondatatext;
    let newdata = {};
    let newdata2 = {};
    if (isNewQuestion) {
      if (newQuestiondatatext.trim() != "") {
        newdata2 = {
          questionText: this.state.newQuestiondatatext,
          isPertinant: this.state.newQuestiondatapertinent,
          sequence: this.getMinSequence() - 1,
          expectedAnswer: this.state.newQuestiondataexpanswer,
          preInterview: false,
        };
        let newqarray4 = this.state.questionDetail;
        newqarray4 = [...newqarray4, newdata2];
        console.log("oldarray", newqarray4);
        newdata = {
          projectId: this.state.projectId,
          tenantId: this.state.userInfostate.tenantId,
          questions: newqarray4,
        };
        updateProjectQuestions(newdata, this.state.projectId)
          .then((res) => {
            this.setState({
              addQuestion: false,
              questionDetail: res.data.questions,
              newQuestiondatatext: "",
              newQuestiondatapertinent: true,
              newQuestiondataexpanswer: "No",
              newQuestiondatasequence: 0,
              alertMsg: "Question Created Successfully",
              variant: "success",
              alertStatus: true,
              disableSaveBtn: true,
            });
          })
          .catch((err) => {
            let errMsg = err?.response?.data?.message;
            this.setState({
              alertMsg: errMsg,
              variant: "danger",
              alertStatus: true,
              disableSaveBtn: true,
            });
          });
      } else {
        this.setState({ newQuestionEmpty: true });
      }
    } else {
      newdata = {
        projectId: this.state.projectId,
        tenantId: this.state.userInfostate.tenantId,
        questions: this.state.questionDetail,
      };

      updateProjectQuestions(newdata, this.state.projectId)
        .then((res) => {
          this.setState({
            addQuestion: false,
            questionDetail: res.data.questions,
            newQuestiondatatext: "",
            newQuestiondatapertinent: true,
            newQuestiondataexpanswer: "No",
            newQuestiondatasequence: 0,
            alertMsg: "Questions Updated Successfully",
            variant: "success",
            alertStatus: true,
            disableSaveBtn: true,
          });
        })
        .catch((err) => {
          let errMsg = err?.response?.data?.message;
          this.setState({
            alertMsg: errMsg,
            variant: "danger",
            alertStatus: true,
          });
        });
    }
  };

  autoExpand = (field) => {
    // Reset field height
    field.style.height = "inherit";

    // Get the computed styles for the element
    let computed = window.getComputedStyle(field);

    // Calculate the height
    let height =
      parseInt(computed.getPropertyValue("border-top-width"), 10) +
      parseInt(computed.getPropertyValue("padding-top"), 10) +
      field.scrollHeight +
      parseInt(computed.getPropertyValue("padding-bottom"), 10) +
      parseInt(computed.getPropertyValue("border-bottom-width"), 10);

    field.style.height = height + "px";
  };

  handleChangeNewTextarea = (event) => {
    if (event.target.tagName.toLowerCase() !== "textarea") return;
    this.autoExpand(event.target);
    let questiontextdata = event.target.value;
    if (questiontextdata.trim() !== "") {
      this.setState({
        newQuestiondatatext: event.target.value,
        newQuestionEmpty: false,
        disableSaveBtn: false,
      });
    } else {
      this.setState({
        newQuestiondatatext: event.target.value,
        newQuestionEmpty: true,
        disableSaveBtn: false,
      });
    }
  };
  handleChangetextarea = (event, id) => {
    if (event.target.tagName.toLowerCase() !== "textarea") return;
    this.autoExpand(event.target);
    let questiontextdata = event.target.value;
    console.log("value", questiontextdata);
    if (
      (questiontextdata === "" && questiontextdata.trim() === "") ||
      questiontextdata.trim() !== ""
    ) {
      let newqarray2 = this.state.questionDetail;
      newqarray2.filter(function (item) {
        if (item.projectQuestionId == id) {
          item.questionText = event.target.value;
        }
      });
      this.setState({
        questionDetail: newqarray2,
        disableSaveBtn: questiontextdata.trim() === "",
      });
    }
  };

  handleExpectedansChange = (event, id) => {
    const value = event.target.value;
    console.log("handleExpectedansChange", value);
    let newqarray5 = this.state.questionDetail;
    console.log("newqarray5", newqarray5);
    newqarray5.filter(function (item) {
      if (item.projectQuestionId == id) {
        item.expectedAnswer = value;
      }
    });
    this.setState({
      questionDetail: newqarray5,
      disableSaveBtn: false,
    });
  };

  quetsionparaclick = (queId, quepId, index) => {
    document.getElementById(quepId).style.display = "none";
    document.getElementById(queId).style.display = "block";
    let length = this.textarea[index].value.length;
    console.log(
      "this.textarea[index]",
      length,
      this.textarea[index].selectionEnd
    );
    this.textarea[index].selectionEnd = length + 2;
    this.textarea[index].selectionStart = this.textarea[index].selectionEnd =
      length + 2;
    document.getElementById(queId).focus();
    this.autoExpand(document.getElementById(queId));
  };

  handlePertinentChange = (event, id) => {
    const target = event.target;
    const value = target.checked;
    let newqarray1 = this.state.questionDetail;
    newqarray1.filter(function (item) {
      if (item.projectQuestionId == id) {
        item.isPertinant = value;
      }
    });
    this.setState({
      questionDetail: newqarray1,
      disableSaveBtn: false,
    });
  };
  handlePertinentNewChange = (event) => {
    const target = event.target;
    const value = target.checked;
    const expanswer = value ? "No" : "Yes";
    console.log(expanswer);
    this.setState({
      disableSaveBtn: false,
      newQuestiondatapertinent: event.target.checked,
      // newQuestiondataexpanswer: expanswer,
    });
  };
  stageIsExecution = (stagelist, projectstage) => {
    let newstagelist =
      stagelist != undefined &&
      stagelist.filter(function (stagelist) {
        return stagelist.attributeId === projectstage;
      });

    return !!(newstagelist.length > 0 && newstagelist[0].sequence === 3);
  };
  stageIsComplete = (stagelist, projectstage) => {
    let newstagelist =
      stagelist != undefined &&
      stagelist.filter(function (stagelist) {
        return stagelist.attributeId === projectstage;
      });

    return !!(newstagelist.length > 0 && newstagelist[0].sequence === 4);
  };
  onConfirmBox = (id) => {
    this.setState({ isConfirm: id });
  };
  approveConfirmBox = () => {
    this.setState({ isApproveConfirm: true });
  };
  handleConfirm = (e) => {
    if (e !== false) {
      this.deleteQuestionList(this.state.isConfirm);
    }
    this.setState({ isConfirm: false });
  };
  QuestionsDownload = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = mm + dd + yyyy;
    this.state.TenantName = localStorage.getItem("operatingTenantName");
    let userTenantName = JSON.parse(localStorage.getItem("userInfo")).tenant
      .tenantName;
    if (this.state.TenantName == null) {
      this.setState({
        TenantName: userTenantName,
      });
    }
    const id = this.state.projectId;
    getQuestionsDownload(this.state.projectId)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/pdf;charset=utf-8",
        });
        FileSaver.saveAs(
          blob,
          filenameFromResponseHeader(res.headers["content-disposition"])
        );
        this.setState({
          alertMsg: this.props.t("Questions Downloaded Successfully"),
          variant: "success",
          alertStatus: true,
        });
      })
      .catch((err) => {
        let errMsg = err?.response?.data?.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
        });
      });
  };
  handleApproveConfirm = (e) => {
    if (e !== false) {
      getQuestionsApproved(this.state.projectId)
        .then((res) => {
          this.setState(
            {
              alertMsg: this.props.t("Questions Approved Successfully"),
              variant: "success",
              alertStatus: true,
              disableApproveBtn: true,
            },
            () => {
              this.loadProjectQuestion();
            }
          );
        })
        .catch((err) => {
          let errMsg = err?.response?.data?.message;
          this.setState({
            alertMsg: errMsg,
            variant: "danger",
            alertStatus: true,
          });
        });
    }
    this.setState({ isApproveConfirm: false });
  };
  componentWillReceiveProps(nextProps) {
    if (
      this.state.isinternal.length !== nextProps.statedata.isinternal.length
    ) {
      this.setState({
        isinternal: nextProps.statedata.isinternal,
        noPermissionmsg: "",
      });
    }
    if (this.state.stageList !== nextProps.stageList) {
      this.setState({
        stageList: nextProps.stageList,
      });
    }
    if (this.state.projectDetailB !== nextProps.statedata.projectDetailB) {
      this.setState({
        projectDetailB: nextProps.statedata.projectDetailB,
      });
    }
  }
  componentDidMount() {
    let userPermissions = [];
    this.loadProjectQuestion();
    let permissions = JSON.parse(localStorage.getItem("userInfo"))
      .rolePermission.permissions;
    permissions.map((permission) => {
      userPermissions.push(permission.permission);
    });
    this.setState({
      userPermissions: userPermissions,
    });
  }
  loadProjectQuestion = () => {
    let isSystemTenant = JSON.parse(localStorage.getItem("userInfo")).tenant
      .isSystemTenant;
    const userRoleName = JSON.parse(localStorage.getItem("userInfo"))
      .rolePermission.roleName;
    const isACGRAdmin = userRoleName == "ACGR Admin" ? true : false;
    const self = this;
    const id = this.props.match.params.id;
    getProjectQuestions(id)
      .then((res) => {
        self.setState({
          questionDetail: res.data.questions,
          questionApproveDetail: res.data,
          projectId: id,
        });
        if (isACGRAdmin && res.data.questionsApproved === true) {
          this.setState({
            disableApproveBtn: isSystemTenant ? false : true,
          });
        } else if (res.data.questionsApproved === true) {
          this.setState({
            disablePointerEvent: isSystemTenant ? false : true,
            disableAddBtn: isSystemTenant ? false : true,
            disableApproveBtn: isSystemTenant ? false : true,
            disabledeletBtn: isSystemTenant ? false : true,
            questionsEditable: isSystemTenant ? true : false,
          });
        }
      })
      .catch((err) => {
        let errMsg = err?.response?.data?.message;
        self.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
          projectId: id,
          disableApproveBtn: true,
        });
      });
  };
  render() {
    const { t, statedata, stageList } = this.props;
    const stage = [...stageList];
    const prjStageId = { ...statedata.projectDetailB };
    const isexecution = this.stageIsExecution(stage, prjStageId.stageId);
    const isComplete = this.stageIsComplete(stage, prjStageId.stageId);
    let sortingFlag = isexecution || isComplete;
    let isSystemTenant = JSON.parse(localStorage.getItem("userInfo")).tenant
      .isSystemTenant;

    let addQuestionInternal = (
      <ul className="questionlist-sort1">
        <Grid size={12} className="add-new-question 1">
          <li className="question-list">
            <Grid container className="m-0 ">
              <Grid size={7}>
                <span className="ques-sequence">+</span>
                <span className="ques-text 1">
                  <textarea
                    rows="3"
                    value={this.state.newQuestiondatatext}
                    onChange={(event) => this.handleChangeNewTextarea(event)}
                  />
                  {this.state.newQuestionEmpty ? (
                    <small className="form-text text-danger">
                      Question text can not be empty
                    </small>
                  ) : (
                    ""
                  )}
                </span>
              </Grid>
              <Grid size={2} className="text-center">
                <div className="label">{t("Pertinent")}</div>
                <input
                  className="question-pertinent mt-1"
                  type="checkbox"
                  label=""
                  defaultChecked={this.state.newQuestiondatapertinent}
                  onChange={(event) => this.handlePertinentNewChange(event)}
                />
              </Grid>
              <Grid size={2} className="p-0">
                <div className="ques-expanswer">
                  <div className="label">
                    <span className="underline"> {t("Expected Answer")} </span>
                  </div>
                  <label className="custom-radio mt-2">
                    {t("Yes")}
                    <input
                      type="radio"
                      defaultChecked={
                        this.state.newQuestiondataexpanswer === "Yes"
                      }
                      checked={this.state.newQuestiondataexpanswer === "Yes"}
                      value="Yes"
                      name="question_new"
                      onChange={(event) =>
                        this.setState({
                          newQuestiondataexpanswer: event.target.value,
                        })
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="custom-radio mt-2">
                    {t("No")}
                    <input
                      type="radio"
                      defaultChecked={
                        this.state.newQuestiondataexpanswer === "No"
                      }
                      checked={this.state.newQuestiondataexpanswer === "No"}
                      value="No"
                      name="question_new"
                      onChange={(event) =>
                        this.setState({
                          newQuestiondataexpanswer: event.target.value,
                        })
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  {this.state.questionApproveDetail.questionnaireType ==
                  "HYBRID_QUESTIONNAIRE" ? (
                    <label className="custom-radio mt-2">
                      {t("Narr")}
                      <input
                        type="radio"
                        defaultChecked={
                          this.state.newQuestiondataexpanswer === "Narr"
                        }
                        checked={this.state.newQuestiondataexpanswer === "Narr"}
                        value="Narr"
                        name="question_new"
                        onChange={(event) =>
                          this.setState({
                            newQuestiondataexpanswer: event.target.value,
                          })
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid size={1}>
                <div className="delete-question">
                  <Button
                    variant="secondary"
                    disabled={this.state.disabledeletBtn}
                    className="btn-rounded btn-sm mt-2 float-right"
                    onClick={(event) => this.setState({ addQuestion: false })}
                    iconButton={true}
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="button_icon_only"
                    />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </li>
        </Grid>
      </ul>
    );
    let addQuestion = (
      <ul className="questionlist-sort1">
        <Grid size={12} className="add-new-question">
          <li className="question-list">
            <Grid container className="m-0 ">
              <Grid size={7}>
                <span className="ques-sequence">+</span>
                <span className="ques-text 2">
                  <textarea
                    rows="3"
                    value={this.state.newQuestiondatatext}
                    onChange={(event) => this.handleChangeNewTextarea(event)}
                  />
                  {this.state.newQuestionEmpty ? (
                    <small className="form-text text-danger">
                      Question text can not be empty
                    </small>
                  ) : (
                    ""
                  )}
                </span>
              </Grid>
              <Grid size={2} className="text-center">
                <div className="label">{t("Pertinent")}</div>
                <input
                  className="question-pertinent mt-1"
                  type="checkbox"
                  label=""
                  defaultChecked={this.state.newQuestiondatapertinent}
                  onChange={(event) => this.handlePertinentNewChange(event)}
                />
              </Grid>
              <Grid size={2} className="p-0">
                <div className="ques-expanswer">
                  <div className="label">
                    <span className="underline"> {t("Expected Answer")} </span>
                  </div>
                  <label className="custom-radio mt-2">
                    {t("Yes")}
                    <input
                      type="radio"
                      value="Yes"
                      name="question_new"
                      onChange={(event) =>
                        this.setState({
                          newQuestiondataexpanswer: event.target.value,
                        })
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="custom-radio mt-2">
                    {t("No")}
                    <input
                      type="radio"
                      defaultChecked={true}
                      value="No"
                      name="question_new"
                      onChange={(event) =>
                        this.setState({
                          newQuestiondataexpanswer: event.target.value,
                        })
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  {this.state.questionApproveDetail.questionnaireType ==
                  "HYBRID_QUESTIONNAIRE" ? (
                    <label className="custom-radio mt-2">
                      {t("Narr")}
                      <input
                        type="radio"
                        value="Narr"
                        name="question_new"
                        onChange={(event) =>
                          this.setState({
                            newQuestiondataexpanswer: event.target.value,
                          })
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid size={1}>
                <div className="delete-question">
                  <Button
                    variant="secondary"
                    className="btn-rounded btn-sm mt-2 float-right"
                    disabled={this.state.disabledeletBtn}
                    onClick={(event) => this.setState({ addQuestion: false })}
                    iconButton={true}
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="button_icon_only"
                    />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </li>
        </Grid>
      </ul>
    );
    let pertinentIndexinternal = 0;
    let questionListInternal = this.state.questionDetail
      .sort((a, b) => a.sequence - b.sequence)
      .map((question, index) => {
        {
          question.isPertinant
            ? (pertinentIndexinternal = pertinentIndexinternal + 1)
            : (pertinentIndexinternal = pertinentIndexinternal + 0);
        }

        return (
          <li
            key={question.projectQuestionId}
            data-id={question.projectQuestionId}
            className="question-list clearfix"
          >
            <Grid
              container
              className="question-list-row"
              style={
                this.state.disablePointerEvent ? { pointerEvents: "none" } : {}
              }
            >
              <Grid size={7}>
                <span
                  className={`ques-sequence ${
                    question.isPertinant ? "active" : "inactive"
                  }`}
                >
                  {question.isPertinant ? index + 1 : "N"}
                </span>
                <span
                  className="ques-text"
                  style={
                    isexecution || isComplete ? { pointerEvents: "all" } : {}
                  }
                >
                  <p
                    className="questionpara"
                    id={"questionIdp-" + question.projectQuestionId}
                    onClick={
                      this.state.questionsEditable == true
                        ? () =>
                            this.quetsionparaclick(
                              "questionId-" + question.projectQuestionId,
                              "questionIdp-" + question.projectQuestionId,
                              index
                            )
                        : null
                    }
                  >
                    <pre>{question.questionText}</pre>
                  </p>
                  <textarea
                    ref={(ref) => (this.textarea[index] = ref)}
                    id={"questionId-" + question.projectQuestionId}
                    rows="3"
                    value={question.questionText}
                    onChange={(event) =>
                      isexecution || isComplete
                        ? {}
                        : this.handleChangetextarea(
                            event,
                            question.projectQuestionId
                          )
                    }
                    style={{ display: "none" }}
                  />
                  {!question.questionText && (
                    <small className="form-text text-danger">
                      Question text can not be empty
                    </small>
                  )}
                </span>
              </Grid>
              <Grid size={2} className="text-center">
                <div className="label">{t("Pertinent")}</div>
                <input
                  className="question-pertinent mt-1"
                  type="checkbox"
                  label=""
                  defaultChecked={question.isPertinant}
                  onChange={(event) =>
                    this.handlePertinentChange(
                      event,
                      question.projectQuestionId
                    )
                  }
                />
              </Grid>
              <Grid size={2} className="p-0">
                <div className="ques-expanswer">
                  <div className="label">
                    <span className="underline">{t("Expected Answer")}</span>
                  </div>
                  <label className="custom-radio mt-2">
                    {t("Yes")}
                    <input
                      type="radio"
                      value="Yes"
                      name={`question_${question.projectQuestionId}`}
                      defaultChecked={
                        question.expectedAnswer == "Yes" ? "true" : ""
                      }
                      onChange={(event) =>
                        this.handleExpectedansChange(
                          event,
                          question.projectQuestionId
                        )
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="custom-radio mt-2">
                    {t("No")}
                    <input
                      type="radio"
                      value="No"
                      name={`question_${question.projectQuestionId}`}
                      defaultChecked={
                        question.expectedAnswer == "No" ? "true" : ""
                      }
                      onChange={(event) =>
                        this.handleExpectedansChange(
                          event,
                          question.projectQuestionId
                        )
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  {this.state.questionApproveDetail.questionnaireType ==
                  "HYBRID_QUESTIONNAIRE" ? (
                    <label className="custom-radio mt-2">
                      {t("Narr")}
                      <input
                        type="radio"
                        value="Narr"
                        name={`question_${question.projectQuestionId}`}
                        defaultChecked={
                          question.expectedAnswer == "Narr" ? "true" : ""
                        }
                        onChange={(event) =>
                          this.handleExpectedansChange(
                            event,
                            question.projectQuestionId
                          )
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid size={1}>
                {!isexecution || !isComplete ? (
                  <div className="delete-question">
                    {statedata.isinternal.length > 0 &&
                    statedata.isinternal.indexOf(
                      "tenant.project.questions.delete"
                    ) >= 0 ? (
                      <Button
                        variant="secondary"
                        className="btn-rounded btn-sm mt-3 float-right"
                        disabled={this.state.disabledeletBtn}
                        onClick={(e) =>
                          this.onConfirmBox(question.projectQuestionId)
                        }
                        iconButton={true}
                      >
                        <FontAwesomeIcon
                          icon={faMinus}
                          className="button_icon_only"
                        />
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </li>
        );
      });
    return (
      <React.Fragment>
        <PreventLeaveRoute
          reset={() => {}}
          when={!this.state.disableSaveBtn}
          // Navigate function
          navigate={(field, historyObj) => {
            historyObj.push(field);
          }}
          // Use as "message" prop of Prompt of React-Router
          shouldBlockNavigation={(location) => {
            if (location.pathname === "/") {
              return false;
            }
            return !this.state.disableSaveBtn;
          }}
        />
        {statedata.isinternal.length > 0 &&
        statedata.isinternal.indexOf("tenant.project.questions.read") >= 0 ? (
          <div className="w-1200">
            <Grid container className="mt-3">
              <Grid size={12}>
                <AlertDismissible
                  msg={this.state.alertMsg}
                  variantType={this.state.variant}
                  show={this.state.alertStatus}
                  close={this.handleAlertClose}
                ></AlertDismissible>
              </Grid>
            </Grid>
            <div className="card">
              <div className="card-body">
                <Grid container className="">
                  {!isexecution && !isComplete ? (
                    <Grid size={12}>
                      {statedata.isinternal.indexOf(
                        "tenant.project.questions.create"
                      ) >= 0 ? (
                        <Button
                          variant="success"
                          onClick={(event) =>
                            this.setState({ addQuestion: true })
                          }
                          className="mr-2 text-uppercase"
                          disabled={this.state.disableAddBtn}
                        >
                          {t("Add")}
                        </Button>
                      ) : (
                        ""
                      )}
                      {statedata.isinternal.indexOf(
                        "tenant.project.questions.update"
                      ) >= 0 ||
                      statedata.isinternal.indexOf(
                        "tenant.project.questions.internal.update"
                      ) >= 0 ? (
                        <Button
                          variant="primary"
                          onClick={(e) =>
                            this.saveQuestionList(this.state.questionDetail)
                          }
                          className="text-uppercase btn-primary"
                          ref="savequestion"
                          disabled={this.state.disableSaveBtn}
                        >
                          {t("Save")}
                        </Button>
                      ) : (
                        ""
                      )}
                      <div className="float-right">
                        {statedata.isinternal.indexOf(
                          "tenant.project.questions.approve.update"
                        ) >= 0 ? (
                          <Button
                            variant="primary"
                            className="btn-primary mr-2"
                            ref="approvebtn"
                            disabled={this.state.disableApproveBtn}
                            onClick={() => this.approveConfirmBox()}
                          >
                            {t("Approve")}
                          </Button>
                        ) : (
                          ""
                        )}
                        <Button
                          variant="primary"
                          className=""
                          onClick={() => this.QuestionsDownload()}
                        >
                          {t("Download")}
                        </Button>
                      </div>
                    </Grid>
                  ) : (
                    <Grid size={12}>
                      <div className="float-right">
                        <Button
                          variant="primary"
                          className="btn-primary mr-2"
                          disabled="disabled"
                        >
                          {t("Approve")}
                        </Button>
                        <Button
                          variant="primary"
                          className=""
                          onClick={() => this.QuestionsDownload()}
                        >
                          {t("Download")}
                        </Button>
                      </div>
                      <div className="question-note mt-2">
                        {t("Note")}:{" "}
                        {t(
                          "Questions are not editable when a project enters execution stage."
                        )}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>

            {this.state.addQuestion
              ? statedata.isinternal.indexOf(
                  "tenant.project.questions.internal.update"
                ) >= 0
                ? addQuestionInternal
                : addQuestion
              : ""}
            {this.state.questionApproveDetail.questionsApproved === true ? (
              <div className="approvedetail clearfix">
                <h6>Questions Approved by:</h6>
                <div>
                  <span style={{ marginRight: "40px" }}>
                    {this.state.questionApproveDetail.questionsApprovedBy}
                  </span>
                  <span>
                    {moment(
                      this.state.questionApproveDetail.questionApprovedDateTime
                    )
                      .tz("America/Los_Angeles")
                      .format(Date_Time_Format)}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="clearfix">
              <ReactSortable
                filter=".addImageButtonContainer"
                dragClass="sortableDrag"
                list={this.state.questionDetail}
                setList={(newState) => {
                  let newqarray = [];
                  newState.map((item, index) => {
                    item.sequence = index;
                    newqarray.push(item);
                  });
                  if (newState.length !== 0) {
                    this.setState({
                      questionDetail: newqarray,
                      disableSaveBtn: false,
                    });
                  }
                }}
                animation="200"
                easing="ease-out"
                className={
                  isexecution || isComplete
                    ? "avoid-clicks questionlist-sort"
                    : "questionlist-sort"
                }
              >
                {questionListInternal}
              </ReactSortable>
            </div>
          </div>
        ) : (
          <div className="text-center w-100 text-color">
            {this.state.noPermissionmsg}
          </div>
        )}
        <IsConfirm
          show={this.state.isConfirm}
          onOkay={(e) => this.handleConfirm(e)}
          t={this.props.t}
        >
          Do you really want to delete this question ?
        </IsConfirm>
        <IsConfirm
          show={this.state.isApproveConfirm}
          onOkay={(e) => this.handleApproveConfirm(e)}
          t={this.props.t}
        >
          {t(
            "Questions can not be edited after you approve. Are you sure you want to approve the questions?"
          )}
        </IsConfirm>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stageList: state.projects.stageList,
  };
};
export default withTranslation()(connect(mapStateToProps)(ProjectQuestions));
