import * as actionTypes from "../constants";
import { getProjectList } from "../../Services/projectServices";
const getProjects = (filterStatusValue, filterStageValue, callBack) => {
  return (dispatch) => {
    getProjectList(filterStatusValue, filterStageValue)
      .then((res) => {
        dispatch(getProjectAction(res.data));
        callBack();
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response !== undefined && err?.response?.data?.message;
        dispatch(
          getProjectAction({
            projects: [],
            stages: [],
            alertstage: true,
            alertMsg: errMsg,
            variant: "danger",
          })
        );
        callBack();
      });
  };
};

const getProjectAction = (res) => {
  return {
    type: actionTypes.GET_PROJECTS,
    payload: res,
    isLoading: false,
  };
};

const isprojectStageSetup = (stagelist, projectstage) => {
  let newstagelist =
    stagelist != undefined &&
    stagelist.filter(function (stagelist) {
      return stagelist.attributeId === projectstage;
    });
  const isSetupStage = !!(
    newstagelist.length > 0 && newstagelist[0].sequence === 1
  );
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_STAGE_SETUP,
      payload: isSetupStage,
    });
  };
};

const alertclose = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ALERT_CLOSE,
      payload: false,
    });
  };
};

const dataLoading = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DATA_LOADING,
    });
  };
};

const projectCodeExist = () => {
  return {
    type: actionTypes.IS_PROJECTCODE_EXIST,
  };
};

const projectCodeNotExist = () => {
  return {
    type: actionTypes.IS_PROJECTCODE_NOT_EXIST,
  };
};
const setAccordianUUId = () => {
  return {
    type: actionTypes.ACCORDIAN_UUID,
  };
};
const setMultiAccordianUUId = () => {
  return {
    type: actionTypes.MULTI_ACCORDIAN_UUID,
  };
};
const setProjectCodeError = (ProjectCodeError) => {
  return {
    type: actionTypes.PROJECT_CODE_ERRORS,
    payload: ProjectCodeError,
  };
};

const setProjectPhoneNumberError = (projectPhoneNumberErrors) => {
  return {
    type: actionTypes.PROJECT_PHONE_NUMBER_ERROR,
    payload: projectPhoneNumberErrors,
  };
};

export const projectActions = {
  getProjects,
  alertclose,
  dataLoading,
  projectCodeExist,
  projectCodeNotExist,
  setAccordianUUId,
  setProjectCodeError,
  setMultiAccordianUUId,
  isprojectStageSetup,
  setProjectPhoneNumberError,
};
