import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import { Grid2 as Grid } from "@mui/material";
import { getlanguageIdFromcode } from "../../Helpers";
import i18n from "../../locale/i18n";
import { getLookUpList } from "../../Services/lookUpService";
import { getProjectRoles } from "../../Services/projectServices";
import {
  getlaguageAttribute,
  getTenantList,
} from "../../Services/tenantService";
import {
  getAllUsersByTenantID,
  getUserInfo,
} from "../../Services/userServices";
import * as userPermission from "../Utility/userPermission.js";
import "./admin.css";
import CallCenters from "./CallCenter";
import Clientapp from "./Clientapp";
import Lookups from "./Lookups";
import OrderDetails from "./OrderDetails";
import Permissions from "./Permissions";
import ResultConfiguration from "./ResultConfiguration";
import Roles from "./Roles";
import Tenant from "./Tenant";
import Users from "./Users";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminDetail: {
        navBar: "Tenant",
        prevNavBar: "Tenant",
        navBarAction: "",
      },
      userInfo: {
        rolePermission: { permissions: [] },
      },
      childFormDirty: false,
      tenants: [],
      tenantUser: false,
      tenantReadEnabled: false,
      tenantCreateEnabled: false,
      tenantUpdateEnabled: false,
      userReadEnabled: false,
      userCreateEnabled: false,
      userDeleteEnabled: false,
      userUpdateEnabled: false,
      roleReadEnabled: false,
      roleCreateEnabled: false,
      permissionReadEnabled: false,
      permissionUpdateEnabled: false,
      lookUpReadEnabled: false,
      lookUpCreateEnabled: false,
      lookUpUpdateEnabled: false,
      callCenterUser: false,
      users: [],
      roles: [],
      permissions: [],
      lookups: [],
      callCenters: [],
      tenantId: "",
      addRow: {},
      editRow: {},
      callCenterId: "",
      totalTenants: "",
      page: 0,
      sizePerPage: 25,
      searchValue: "",
      tableLoader: true,
      sortOrder: {
        tenantName: "",
        createTs: "desc",
      },
      orderDetailToggle: true,
      tenantAttributes: [],
    };
  }

  searchHandleClick = (e, value) => {
    e.preventDefault();
    this.setState(
      {
        searchValue: value,
        page: 1,
      },
      () => {
        this.updateTenantInfo(this.state.userInfo);
      }
    );
  };

  handleTableChange = (params) => {
    this.setState(
      { page: params.page, sizePerPage: params.pageSize, tableLoader: true },
      () => {
        this.updateTenantInfo(this.state.userInfo);
      }
    );
  };

  handleTableSorting = (sortModel) => {
    const copySortobeject = { ...this.state.sortOrder };
    copySortobeject.tenantName = sortModel[0]?.sort;
    this.setState(
      {
        sortOrder: copySortobeject,
        tableLoader: true,
      },
      () => {
        this.updateTenantInfo(this.state.userInfo);
      }
    );
  };

  handleEditRow = (rowVal, navBar, callCenterId = null) => {
    if (navBar === "Edit CallCenter") {
      this.setState({
        adminDetail: {
          navBar: navBar,
        },
        editRow: rowVal,
        callCenterId: callCenterId,
      });
    } else if (navBar === "Edit Agent") {
      this.setState({
        adminDetail: {
          navBar: navBar,
        },
        editRow: rowVal,
        editAgentId: callCenterId,
      });
    } else {
      this.setState({
        adminDetail: {
          navBar: navBar,
        },
        editRow: rowVal,
      });
    }
  };

  handleBreadCrumInAdmin = (navBar) => {
    this.getAllInfo();
    this.setState({
      adminDetail: {
        navBar: navBar,
        prevNavBar: this.state.adminDetail.navBar,
      },
    });
  };

  handleTabClick = (navBar) => {
    if (navBar === "Order Details") {
      this.setState({ orderDetailToggle: !this.state.orderDetailToggle });
    }
    if (this.state.adminDetail.navBar != navBar && !this.state.childFormDirty) {
      this.handleBreadCrumInAdmin(navBar);
    }
  };

  handleChildDirtyGFlag = (dirty) => {
    this.setState({
      childFormDirty: dirty,
    });
  };

  getLookUpFieldNameLabel = (attributeType) => {
    const lookUpFieldNameOption = require("../../config/lookUpFieldNameOption.json");
    let attributeTypeVal = attributeType;
    lookUpFieldNameOption.map((lookUp) => {
      if (lookUp.value == attributeType) {
        attributeTypeVal = lookUp.label;
      }
    });
    return attributeTypeVal;
  };

  getLookUp = async (userInfo) => {
    return await getLookUpList(userInfo.tenantId).then((res) => {
      let lookUpGroup = {};
      res.data.attributes.map((lookUp) => {
        if (lookUpGroup[lookUp.attributeId] != null) {
          lookUpGroup[lookUp.attributeId].push(lookUp);
        } else {
          lookUpGroup[lookUp.attributeId] = [lookUp];
        }
      });

      const lookUpGroupList = Object.keys(lookUpGroup).map(
        (keys) => lookUpGroup[keys]
      );

      const lookUpList = lookUpGroupList.map((lookUp) => {
        return {
          id: lookUp[0].id,
          englishId: lookUp[0].id,
          spanishId: lookUp[1] != null ? lookUp[1].id : null,
          attributeId: lookUp[0].attributeId,
          attributeType: lookUp[0].attributeType,
          attributeTypeVal: this.getLookUpFieldNameLabel(
            lookUp[0].attributeType
          ),
          englishValue: lookUp[0].description,
          spanishValue: lookUp[1] != null ? lookUp[1].description : null,
          englishLanguageId: lookUp[0].languageId,
          spanishLanguageId: lookUp[1] != null ? lookUp[1].languageId : null,
          status: lookUp[0].active,
          sequence: lookUp[0].sequence,
          tenantId: lookUp[0].tenantId,
          default: lookUp[0].default,
          lookUpGroup: lookUp,
        };
      });
      return lookUpList;
    });
  };

  getTenants = async () => {
    const { page, sizePerPage, searchValue, sortOrder } = this.state;
    return await getTenantList(page, sizePerPage, searchValue, sortOrder).then(
      (res) => {
        this.setState({
          totalTenants: res.data.totalTenants,
          tableLoader: false,
        });
        const tenantList = res.data.tenantList.map((tenant) => {
          tenant.userName =
            tenant.defaultUserFirstName + " " + tenant.defaultUserLastName;
          tenant.id = tenant.tenantId;
          tenant.statusValue = tenant.status ? "Active" : "Inactive";
          return tenant;
        });
        return tenantList;
      }
    );
  };

  getUserDetails = async (userInfo) => {
    return await getAllUsersByTenantID(userInfo.tenantId).then((res) => {
      const userList = res.data.map((user) => {
        user.userName = user.firstName + " " + user.lastName;
        user.tenantId = userInfo.tenantId;
        user.statusValue = user.isActive ? "Active" : "Inactive";
        return user;
      });
      return userList;
    });
  };

  getRoleDetails = async (userInfo) => {
    const userRoles = await getProjectRoles(userInfo.tenantId);

    const userRolesList = userRoles.data.map((roles) => {
      roles.id = roles.roleId;
      roles.userRoleName = roles.name;
      roles.value = roles.roleId;
      roles.label = roles.name;
      return roles;
    });

    return userRolesList;
  };

  updateChildProp = (object, navBar) => {
    if (object && !this.state.childFormDirty) {
      object.updateNavBar(navBar);
    }
  };

  updateTenantInfo = async (userInfo) => {
    Promise.all([this.getTenants()]).then(([tenantList]) => {
      const tenantListSorted = tenantList;
      this.setState({
        tenants: tenantListSorted,
        tenantUser: true,
        tenantReadEnabled: userPermission.isTenantReadEnabled(
          userInfo.rolePermission.permissions
        ),
        tenantCreateEnabled: userPermission.isTenantCreateEnabled(
          userInfo.rolePermission.permissions
        ),
        tenantUpdateEnabled: userPermission.isTenantEditEnabled(
          userInfo.rolePermission.permissions
        ),
        userInfo: userInfo,
        tenantId: userInfo.tenantId,
      });
    });
  };

  updateUserInfo = async (userInfo) => {
    Promise.all([this.getUserDetails(userInfo)]).then(([userList]) => {
      const userListSorted = userList.sort((a, b) =>
        a.userName.toUpperCase() > b.userName.toUpperCase() ? 1 : -1
      );
      this.setState({
        users: userListSorted,
        userReadEnabled: userPermission.isUserReadEnabled(
          userInfo.rolePermission.permissions
        ),
        userCreateEnabled: userPermission.isUserCreateEnabled(
          userInfo.rolePermission.permissions
        ),
        userDeleteEnabled: userPermission.isUserDeleteEnabled(
          userInfo.rolePermission.permissions
        ),
        userUpdateEnabled: userPermission.isUserUpdateEnabled(
          userInfo.rolePermission.permissions
        ),
        userInfo: userInfo,
        tenantId: userInfo.tenantId,
      });
    });
  };

  updateRolePermissionInfo = async (userInfo) => {
    Promise.all([this.getRoleDetails(userInfo)]).then(([roleList]) => {
      const roleListSorted = roleList.sort((a, b) =>
        a.userRoleName.toUpperCase() > b.userRoleName.toUpperCase() ? 1 : -1
      );
      this.setState({
        roles: roleListSorted,
        roleReadEnabled: userPermission.isRoleReadEnabled(
          userInfo.rolePermission.permissions
        ),
        roleCreateEnabled: userPermission.isRoleCreateEnabled(
          userInfo.rolePermission.permissions
        ),
        permissionReadEnabled: userPermission.isPermissionReadEnabled(
          userInfo.rolePermission.permissions
        ),
        permissionUpdateEnabled: userPermission.isPermissionUpdateEnabled(
          userInfo.rolePermission.permissions
        ),
        userInfo: userInfo,
        tenantId: userInfo.tenantId,
      });
    });
  };

  updateLookUpInfo = async (userInfo) => {
    Promise.all([this.getLookUp(userInfo)]).then(([lookUpList]) => {
      const lookUpListSorted = lookUpList.sort((a, b) =>
        a.attributeType.toUpperCase() > b.attributeType.toUpperCase() ? 1 : -1
      );
      this.setState({
        lookups: lookUpListSorted,
        lookUpReadEnabled: userPermission.isLookupReadEnabled(
          userInfo.rolePermission.permissions
        ),
        lookUpCreateEnabled: userPermission.isLookupCreateEnabled(
          userInfo.rolePermission.permissions
        ),
        lookUpUpdateEnabled: userPermission.isLookupUpdateEnabled(
          userInfo.rolePermission.permissions
        ),
        userInfo: userInfo,
        tenantId: userInfo.tenantId,
      });
    });
  };

  getAllInfo = async () => {
    const userInfo = await getUserInfo();
    if (
      userPermission.isTenantReadEnabled(
        userInfo.data.rolePermission.permissions
      )
    ) {
      this.updateTenantInfo(userInfo.data);
    }
    if (
      userPermission.isUserReadEnabled(userInfo.data.rolePermission.permissions)
    ) {
      this.updateUserInfo(userInfo.data);
    }
    if (
      userPermission.isRoleReadEnabled(userInfo.data.rolePermission.permissions)
    ) {
      this.updateRolePermissionInfo(userInfo.data);
    }
    if (
      userPermission.isLookupReadEnabled(
        userInfo.data.rolePermission.permissions
      )
    ) {
      this.updateLookUpInfo(userInfo.data);
    }
    if (
      userPermission.isCallCenterReadEnabled(
        userInfo.data.rolePermission.permissions
      )
    ) {
      this.setState({
        callCenterUser: true,
      });
    }

    this.setState({
      userInfo: userInfo.data,
      tenantId: userInfo.data.tenantId,
    });
  };

  loadAttribute = () => {
    let languageCode = i18n.language;
    let languageId = getlanguageIdFromcode(languageCode);
    getlaguageAttribute(languageId)
      .then((res) => {
        this.setState({
          tenantAttributes: res.data.tenantAttributes,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.getAllInfo();
    this.loadAttribute();
  }

  render() {
    const { t } = this.props;

    let userPermissionVal = [];
    if (
      this.state.userInfo &&
      this.state.userInfo.rolePermission &&
      this.state.userInfo.rolePermission.permissions
    ) {
      userPermissionVal = this.state.userInfo.rolePermission.permissions;
    }

    let id;
    const navBarTenant = this.state.adminDetail.navBar.includes("Tenant")
      ? "Tenant"
      : "";
    const navBarUsers = this.state.adminDetail.navBar.includes("User")
      ? "User"
      : "";
    const navBarRoles = this.state.adminDetail.navBar.includes("Roles")
      ? "Roles"
      : "";
    const navBarPermissions = this.state.adminDetail.navBar.includes(
      "Permissions"
    )
      ? "Permissions"
      : "";
    const navBarLookups = this.state.adminDetail.navBar.includes("Lookups")
      ? "Lookups"
      : "";
    const navBarCallCenters = this.state.adminDetail.navBar.includes(
      "CallCenter"
    )
      ? "CallCenters"
      : "";
    const navBarAgents = this.state.adminDetail.navBar.includes("Agent")
      ? "Agent"
      : "";
    const agentFlag = this.state.adminDetail.navBar.includes("Edit CallCenter")
      ? true
      : false;
    const navBarClientapp = this.state.adminDetail.navBar.includes(
      "Client Application"
    )
      ? "Client Applications"
      : "";

    const navBarOrderDetails = this.state.adminDetail.navBar.includes(
      "Order Details"
    )
      ? "Order Details"
      : "";

    const navBarResultConfiguration = this.state.adminDetail.navBar.includes(
      "Result Configuration"
    )
      ? "Result Configuration"
      : "";
    id =
      navBarAgents == "Agent" || this.props.match.params.id !== undefined
        ? this.props.match.params.id
        : this.state.callCenterId;

    return (
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid size={12}>
            <div className="admin-page-breadcrumb">
              <label className="admin-breadcrum">{t("Admin")}</label>
              <ArrowForwardIosIcon
                sx={{
                  fontSize: "15px",
                  color: "#3e3f42",
                  fontWeight: 600,
                  marginRight: "5px",
                }}
              />
              <span className="admin-first-breadcrum blue-text">
                {t(this.state.adminDetail.navBar)}
              </span>
            </div>
            <ul as="ul" className="project-info-tab nav">
              {userPermission.isTenantReadEnabled(userPermissionVal) ? (
                <Link
                  className={navBarTenant == "Tenant" ? "active admin-tab" : ""}
                  onClick={() => {
                    this.updateChildProp(this.childTenantRef, "Tenant");
                    this.handleTabClick("Tenant");
                  }}
                  to={`/main/admin/tenant`}
                >
                  {t("Tenant")}
                </Link>
              ) : (
                ""
              )}
              {userPermission.isUserReadEnabled(userPermissionVal) ? (
                <Link
                  className={navBarUsers == "User" ? "active admin-tab" : ""}
                  onClick={() => {
                    this.updateChildProp(this.childUserRef, "User");
                    this.handleTabClick("User");
                  }}
                  to={`/main/admin/users`}
                >
                  {t("User")}
                </Link>
              ) : (
                ""
              )}
              {userPermission.isRoleReadEnabled(userPermissionVal) ? (
                <Link
                  className={navBarRoles == "Roles" ? "active admin-tab" : ""}
                  onClick={() => {
                    this.updateChildProp(this.childRoleRef, "Roles");
                    this.handleTabClick("Roles");
                  }}
                  to={`/main/admin/roles`}
                >
                  {t("Role")}
                </Link>
              ) : (
                ""
              )}
              {userPermission.isRoleReadEnabled(userPermissionVal) ? (
                <Link
                  className={
                    navBarPermissions == "Permissions" ? "active admin-tab" : ""
                  }
                  onClick={() => {
                    this.handleTabClick("Permissions");
                  }}
                  to={`/main/admin/permissions`}
                >
                  {t("Permissions")}
                </Link>
              ) : (
                ""
              )}
              {userPermission.isLookupReadEnabled(userPermissionVal) ? (
                <Link
                  className={
                    navBarLookups == "Lookups" ? "active admin-tab" : ""
                  }
                  onClick={() => {
                    this.updateChildProp(this.childLookUpRef, "Lookups");
                    this.handleTabClick("Lookups");
                  }}
                  to={`/main/admin/lookups`}
                >
                  {t("Lookups")}
                </Link>
              ) : (
                ""
              )}
              {userPermission.isCallCenterReadEnabled(userPermissionVal) ? (
                <Link
                  className={
                    navBarCallCenters == "CallCenters" ? "active admin-tab" : ""
                  }
                  onClick={() => {
                    this.updateChildProp(
                      this.childCallCenterRef,
                      "CallCenters"
                    );
                    this.handleTabClick("CallCenters");
                  }}
                  to={`/main/admin/callcenters`}
                >
                  {t("Call Centers")}
                </Link>
              ) : (
                ""
              )}
              {userPermission.isClientReadEnabled(userPermissionVal) ? (
                <Link
                  className={
                    navBarClientapp == "Client Applications"
                      ? "active admin-tab"
                      : ""
                  }
                  onClick={() => {
                    this.updateChildProp(
                      this.childClientappRef,
                      "Client Applications"
                    );
                    this.handleTabClick("Client Applications");
                  }}
                  to={`/main/admin/clientapp`}
                >
                  {t("Applications")}
                </Link>
              ) : (
                ""
              )}
              {userPermission.isOrderReadEnabled(userPermissionVal) ? (
                <Link
                  className={
                    navBarOrderDetails == "Order Details"
                      ? "active admin-tab"
                      : ""
                  }
                  onClick={() => {
                    this.handleTabClick("Order Details");
                  }}
                  to={`/main/admin/orderdetails`}
                >
                  {t("Order Details")}
                </Link>
              ) : (
                ""
              )}
              {userPermission.isResultConfigurationReadEnabled(
                userPermissionVal
              ) ? (
                <Link
                  className={
                    navBarResultConfiguration == "Result Configuration"
                      ? "active admin-tab"
                      : ""
                  }
                  onClick={() => {
                    this.updateChildProp(
                      this.childUserRef,
                      "Result Configuration"
                    );
                    this.handleTabClick("Result Configuration");
                  }}
                  to={`/main/admin/resultconfiguration`}
                >
                  {t("Result Configuration")}
                </Link>
              ) : null}
            </ul>
            <div className="w-1200">
              <Switch>
                {userPermission.isTenantReadEnabled(userPermissionVal) ? (
                  <Route
                    exact
                    path="/main/admin/tenant"
                    render={(props) => (
                      <Tenant
                        {...props}
                        handleSearch={this.searchHandleClick}
                        searchValue={this.state.searchValue}
                        onRef={(ref) => (this.childTenantRef = ref)}
                        statedata={this.state}
                        handleBreadCrumInAdmin={this.handleBreadCrumInAdmin}
                        handleEditRow={this.handleEditRow}
                        handleTableChange={this.handleTableChange}
                        handleTableSorting={this.handleTableSorting}
                        handleChildDirtyGFlag={this.handleChildDirtyGFlag}
                      />
                    )}
                  />
                ) : (
                  ""
                )}
                {userPermission.isUserReadEnabled(userPermissionVal) ? (
                  <Route
                    exact
                    path="/main/admin/users"
                    render={(props) => (
                      <Users
                        {...props}
                        onRef={(ref) => (this.childUserRef = ref)}
                        statedata={this.state}
                        handleBreadCrumInAdmin={this.handleBreadCrumInAdmin}
                        handleEditRow={this.handleEditRow}
                        handleChildDirtyGFlag={this.handleChildDirtyGFlag}
                        handleList={this.getAllInfo}
                        tenantAttributes={this.state.tenantAttributes}
                      />
                    )}
                  />
                ) : (
                  ""
                )}
                {userPermission.isRoleReadEnabled(userPermissionVal) ? (
                  <Route
                    exact
                    path="/main/admin/roles"
                    render={(props) => (
                      <Roles
                        {...props}
                        onRef={(ref) => (this.childRoleRef = ref)}
                        statedata={this.state}
                        handleBreadCrumInAdmin={this.handleBreadCrumInAdmin}
                        handleChildDirtyGFlag={this.handleChildDirtyGFlag}
                      />
                    )}
                    display="none"
                  />
                ) : (
                  ""
                )}
                {userPermission.isRoleReadEnabled(userPermissionVal) ? (
                  <Route
                    exact
                    path="/main/admin/permissions"
                    render={(props) => (
                      <Permissions
                        {...props}
                        statedata={this.state}
                        handleBreadCrumInAdmin={this.handleBreadCrumInAdmin}
                        handleChildDirtyGFlag={this.handleChildDirtyGFlag}
                      />
                    )}
                  />
                ) : (
                  ""
                )}
                {userPermission.isLookupReadEnabled(userPermissionVal) ? (
                  <Route
                    exact
                    path="/main/admin/lookups"
                    render={(props) => (
                      <Lookups
                        {...props}
                        onRef={(ref) => (this.childLookUpRef = ref)}
                        statedata={this.state}
                        handleBreadCrumInAdmin={this.handleBreadCrumInAdmin}
                        handleEditRow={this.handleEditRow}
                        handleChildDirtyGFlag={this.handleChildDirtyGFlag}
                      />
                    )}
                  />
                ) : (
                  ""
                )}
                {userPermission.isCallCenterReadEnabled(userPermissionVal) ? (
                  <Route
                    exact
                    path="/main/admin/callcenters"
                    render={(props) => (
                      <CallCenters
                        {...props}
                        onRef={(ref) => (this.childCallCenterRef = ref)}
                        statedata={this.state}
                        handleBreadCrumInAdmin={this.handleBreadCrumInAdmin}
                        handleEditRow={this.handleEditRow}
                        handleChildDirtyGFlag={this.handleChildDirtyGFlag}
                      />
                    )}
                  />
                ) : (
                  ""
                )}
                {userPermission.isClientReadEnabled(userPermissionVal) ? (
                  <Route
                    exact
                    path="/main/admin/clientapp"
                    render={(props) => (
                      <Clientapp
                        {...props}
                        onRef={(ref) => (this.childClientappRef = ref)}
                        statedata={this.state}
                        handleBreadCrumInAdmin={this.handleBreadCrumInAdmin}
                        handleEditRow={this.handleEditRow}
                        handleChildDirtyGFlag={this.handleChildDirtyGFlag}
                      />
                    )}
                  />
                ) : (
                  ""
                )}
                {userPermission.isOrderReadEnabled(userPermissionVal) ? (
                  <Route
                    exact
                    path="/main/admin/orderdetails"
                    render={(props) => (
                      <OrderDetails
                        orderDetailToggle={this.state.orderDetailToggle}
                        handleBreadCrumInAdmin={this.handleBreadCrumInAdmin}
                        statedata={this.state}
                      />
                    )}
                  />
                ) : (
                  ""
                )}
                {userPermission.isResultConfigurationReadEnabled(
                  userPermissionVal
                ) ? (
                  <Route
                    exact
                    path="/main/admin/resultconfiguration"
                    render={(props) => (
                      <ResultConfiguration
                        statedata={this.state}
                        handleBreadCrumInAdmin={this.handleBreadCrumInAdmin}
                      />
                    )}
                  />
                ) : null}
              </Switch>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withRouter(Admin));
