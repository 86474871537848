import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Grid2 as Grid, CardContent } from "@mui/material";
import moment from "moment";
import {
  getAccessCodeFiles,
  uploadAccessCode,
} from "../../../Services/projectServices";
import file_icon from "../../../assets/icon/file.png";
import uploadfile_icon from "../../../assets/icon/uploadfile.png";
import {
  Alert as AlertDismissible,
  Button,
  ModalPopUp,
} from "../../../components/Common";
import { Date_Time_Format } from "../../../config";
import { CustomGrid } from "../../CustomTable/index";
import BurnAccessCodeTable from "./BurnAccessCodeTable";

const getDateInFormat = (time, format) => {
  if (time != null) return moment(time).format(format);
  return "";
};
class AccessCode extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      fileModalShow: false,
      filename: "Select File",
      defaultView: "Access_Code_List",
      selectedAccessCode: [],
      checkboxes: {},
      viewCodeRows: [],
      accessCodeFiles: [],
      alertStatus: false,
      alertMsg: "",
      variant: "",
      disabledselect: false,
      loading: false,
      currentPage: 0,
      perPageCount: 10,
    };
  }

  componentDidMount() {
    this.loadAccessCodeFiles();
  }

  loadAccessCodeFiles = () => {
    let projectId = parseInt(this.props.match.params.id);
    getAccessCodeFiles(projectId)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            accessCodeFiles: res.data,
            loading: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          accessCodeFiles: [],
          loading: true,
        });
      });
  };

  getFilesRow = (files) => {
    let row = files.map((file) => {
      return {
        file: file.originalFileName || "",
        projectCode: file.projectCode || "",
        totalRecorded: file.totalRecorded || "",
        assigned: file.assigned,
        available: file.available,
        burned: file.burned,
        dateImported:
          getDateInFormat(file.dateImported, Date_Time_Format) || "",
      };
    });
    return row;
  };

  openFileModal = () => {
    this.setState({
      fileModalShow: true,
    });
  };

  handleModalClose = () => {
    this.setState({
      fileModalShow: false,
      filename: "Select File",
    });
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
      alertMsg: "",
      variant: "",
    });
  };

  selectUploadFile = (event) => {
    event.preventDefault();
    let id = parseInt(this.props.match.params.id);
    let fileName = this.fileInput.current.files[0].name;
    let file = this.fileInput.current.files[0];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("projectId", id);
    this.fileInput.current.value = "";
    this.setState({
      fileData: formData,
      filename: fileName,
    });
  };

  selectFileUpload = () => {
    let formData = this.state.fileData;
    this.setState({
      disabledselect: true,
    });
    uploadAccessCode(formData)
      .then((res) => {
        let msg, varient;
        if (res.status == 200) {
          msg = res.data.status;
          varient = "success";
        }
        if (res.data.erroCode !== undefined) {
          let str = res.data.erroCode;
          this.setState({
            alertMsg: (
              <>
                {msg} <div dangerouslySetInnerHTML={{ __html: str }}></div>
              </>
            ),
            alertStatus: true,
            variant: "danger",
            disabledselect: false,
          });
        } else {
          this.setState(
            {
              alertMsg: msg,
              alertStatus: true,
              variant: varient,
              disabledselect: false,
            },
            () => {
              if (this.state.alertStatus) {
                setTimeout(() => {
                  this.handleAlertClose();
                  this.handleModalClose();
                }, 4000);
              }
              this.loadAccessCodeFiles();
            }
          );
        }
      })
      .catch((err) => {
        let str = err?.response?.data?.message;
        this.setState({
          alertMsg: str,
          alertStatus: true,
          variant: "danger",
          disabledselect: false,
        });
      });
  };

  changeView = (viewName) => {
    this.setState({
      defaultView: viewName,
    });
  };

  onChangeRecords = (page) => {
    this.setState({
      currentPage: page.page,
      perPageCount: page.pageSize,
    });
  };

  render() {
    let fileRow = this.getFilesRow(this.state.accessCodeFiles);
    let { t } = this.props;
    let columns = [
      {
        headerName: `${t("File")}`,
        field: "file",
        flex: 1.5,
        sortable: false,
      },
      {
        headerName: `${t("Project Code")}`,
        field: "projectCode",
        flex: 1,
        sortable: false,
      },
      {
        headerName: `${t("Total")}`,
        field: "totalRecorded",
        flex: 1,
        sortable: false,
      },
      {
        headerName: `${t("Used")}`,
        field: "assigned",
        flex: 1,
        sortable: false,
      },
      {
        headerName: `${t("Available")}`,
        field: "available",
        flex: 1,
        sortable: false,
      },
      {
        headerName: `${t("Burnt")}`,
        field: "burned",
        width: 100,
        sortable: false,
      },
      {
        headerName: `${t("Imported")}`,
        field: "dateImported",
        flex: 1,
        sortable: false,
      },
    ];

    return (
      <React.Fragment>
        <div className="w-1200">
          {this.state.defaultView === "Access_Code_List" && (
            <>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid size={12}>
                      <h5 className="page-title access-title">
                        {t("Access Codes")}{" "}
                      </h5>
                      <div className="float-right">
                        <Button
                          variant="success"
                          className="float-right text-uppercase"
                          onClick={this.openFileModal}
                        >
                          <img src={uploadfile_icon} className="mr-2" />
                          {t("Upload")}
                        </Button>
                        <Button
                          variant="primary"
                          className="float-right mr-2 text-uppercase"
                          onClick={
                            fileRow.length > 0
                              ? () => this.changeView("View_Code")
                              : () => {}
                          }
                        >
                          <img src={file_icon} className="mr-2" />
                          {t("View Codes")}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card>
                <div className="table_wrapper custom_table_container app-table accesscode-module-table">
                  <CustomGrid
                    loading={!this.state.loading}
                    data={fileRow}
                    columns={columns}
                    currentPage={this.state.currentPage}
                    perPageCount={this.state.perPageCount}
                    totalPages={this.state.accessCodeFiles.length}
                    onChangeRecords={this.onChangeRecords}
                    getRowId={(row) =>
                      `${row?.projectCode}_${row?.dateImported}_${row?.totalRecorded}`
                    }
                    isServerPagination={false}
                  />
                </div>
              </Card>
            </>
          )}
          {this.state.defaultView === "View_Code" && (
            <BurnAccessCodeTable projectId={this.props.match.params.id} />
          )}
        </div>
        <ModalPopUp
          onHide={this.handleModalClose}
          title={t("Upload Access Code File")}
          show={this.state.fileModalShow}
        >
          <div className="form-group position-relative">
            <input
              className="form-control"
              value={t(this.state.filename)}
              readOnly
            />
            <FontAwesomeIcon
              icon={faFolder}
              className="folder-icon"
            ></FontAwesomeIcon>
            <input
              type="file"
              name="file"
              className="upload-file-input ac-upload-btn"
              accept=".xlsx, .xls, .txt"
              ref={this.fileInput}
              onChange={this.selectUploadFile}
            />
          </div>
          <AlertDismissible
            msg={this.state.alertMsg}
            variantType={this.state.variant}
            show={this.state.alertStatus}
            close={this.handleAlertClose}
          ></AlertDismissible>
          <div className="text-center mt-5">
            <Button
              variant="primary"
              className="mx-1"
              disabled={this.state.disabledselect}
              onClick={this.selectFileUpload}
            >
              {t("Select")}
            </Button>
            <Button
              variant="secondary"
              className="mx-1"
              onClick={this.handleModalClose}
            >
              {t("Cancel")}
            </Button>
          </div>
        </ModalPopUp>
      </React.Fragment>
    );
  }
}
export default withTranslation()(AccessCode);
