import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Grid2 as Grid } from "@mui/material";
import {
  Alert as AlertDismissible,
  ModalPopUp,
  Spinner,
} from "../../../../../../components/Common";
import { getlanguageId } from "../../../../../../Helpers";
import {
  getResultMitigationDetails,
  updateResultMitigation,
} from "../../../../../../Services/executionService";
import { formatDate } from "../../../../../Utility/DatePickerFunctions";
import StandardDatePicker from "../../../../../Utility/StandardDatePicker";
import { isPermissionExistsInList } from "../../../../../Utility/userPermission";
import { ResultsContext } from "../../../ResultsContext";
import "./OutComeTracking.css";
import OutComeTrackingHistory from "./OutComeTrackingHistory";
import OutComeTrackingOption from "./OutComeTrackingOption";
import TextError from "./TextError";

const OutcomeTracking = (props) => {
  const { showModal, closeModal } = props;

  const { resultsState, permissionList, projectDetail } =
    useContext(ResultsContext);

  const canUserUpdateField = !isPermissionExistsInList(
    permissionList,
    "result.follow.up.note.add"
  );

  const { resultDetails, language, allAttributes, tanentAttributes } =
    resultsState;
  const [loading, setLoading] = useState(false);
  const [finalMitigationStatus, setFinalMitigationStatus] = useState("");
  const [outComeHistory, setoutComeHistory] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertVariant, setAlertVariant] = useState("success");
  const { t } = useTranslation();

  const { statusOption, currencyOption } = OutComeTrackingOption(
    tanentAttributes,
    allAttributes,
    language
  );

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    getResultMitigationDetails(resultDetails.interviewResultId)
      .then((res) => {
        const MitigationStatus =
          res.data.length !== 0 ? res.data[0].outcomeTrackingActionId : null;
        setoutComeHistory(res.data);
        setFinalMitigationStatus(MitigationStatus);
        setLoading(true);
      })
      .catch((error) => {
        setAlertMsg(error.response?.data?.message);
        setAlertVariant("danger");
        setShowAlert(true);
      });
  }, []);

  const initialValues = {
    resultId: resultDetails.interviewResultId,
    outcomeTrackingActionId: "",
    followUpStatusId: "",
    followUpDate: "",
    note: "",
    currency: projectDetail.currencyId || userInfo?.currencyId || "",
    amount: "",
    isCurrencySelected:
      projectDetail.currencyId != null || userInfo?.currencyId != null
        ? true
        : false,
  };

  const validationSchema = Yup.object({
    resultId: Yup.string().required("This field is required"),
    outcomeTrackingActionId: Yup.string().required("This field is required"),
    followUpDate: Yup.string().required("This field is required"),
    isCurrencySelected: Yup.boolean(),
    amount: Yup.number().when("isCurrencySelected", {
      is: () => true,
      then: () => Yup.number().required("This field is required"),
    }),
  });

  /**
   * This function will return label of slected field
   * @param {stirng} language
   * @param {stirng} id
   * @returns
   */
  const getAttributeLabel = (languageCode, id) => {
    if (id) {
      let language = getlanguageId(languageCode);
      let response = [...allAttributes];
      let attributeLabel = response.filter((item) => {
        return item.attributeId === id && item.languageId === language;
      });
      let finalAttributeLabel;
      attributeLabel.map(function (item, index) {
        finalAttributeLabel = item.description;
      });
      return finalAttributeLabel;
    }
  };

  /**
   * This function will handle note submission in auto come tracking
   * @param {object} values
   * @param {object} submitProps
   */
  const manageHandleSubmit = (values, submitProps) => {
    const copyValues = { ...values };
    delete copyValues.isCurrencySelected;
    copyValues.followUpDate = copyValues.followUpDate
      ? copyValues.followUpDate.getTime()
      : null;

    updateResultMitigation(copyValues)
      .then((res) => {
        const MitigationStatus =
          res.data.length !== 0 ? res.data[0].outcomeTrackingActionId : null;
        setoutComeHistory(res.data);
        setFinalMitigationStatus(MitigationStatus);
        submitProps.resetForm();
      })
      .catch((error) => {
        setAlertMsg(error.response?.data?.message);
        setAlertVariant("danger");
        setShowAlert(true);
        submitProps.setSubmitting();
      });
  };

  const selectCurrencyStyles = {
    container: (provided) => ({
      ...provided,
      zIndex: "1",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "150px",
      overflowY: "auto",
    }),
  };

  const selectStatusStyles = {
    container: (provided) => ({
      ...provided,
      zIndex: "2",
    }),
  };

  return (
    <ModalPopUp
      show={showModal}
      onHide={closeModal}
      backdrop={"static"}
      keyboard={false}
      centered
      title={
        t("Outcome Tracking") +
        " " +
        " (IRN -" +
        " " +
        resultDetails.interviewReferenceNumber +
        ")"
      }
      className={"outcome_traking_modal_height"}
    >
      <div className={`outcome_traking_modal_height`}>
        {loading ? (
          <>
            <AlertDismissible
              className="w-100"
              msg={alertMsg}
              variantType={alertVariant}
              show={showAlert}
              close={() => setShowAlert(false)}
              autoClose={true}
            ></AlertDismissible>
            {finalMitigationStatus ? (
              <Grid container>
                <div className="col-lg-12 text-right ">
                  <span className="float-right badge-lg btn btn-sm btn-primary font-15">
                    {getAttributeLabel(language, finalMitigationStatus)}
                  </span>
                </div>
              </Grid>
            ) : (
              ""
            )}
            <div className="result-note-msg">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={manageHandleSubmit}
                enableReinitialize
              >
                {(formik) => {
                  return (
                    <Form>
                      <Grid container>
                        <div className="col-lg-6 my-2">
                          <label
                            htmlFor="status"
                            className="text-uppercase required"
                          >
                            {t("status")}
                          </label>
                          <Select
                            name="status"
                            isDisabled={canUserUpdateField}
                            className="select-style my-1"
                            classNamePrefix="select"
                            value={statusOption.filter(
                              (option) =>
                                option.value ===
                                formik.values.outcomeTrackingActionId
                            )}
                            styles={selectStatusStyles}
                            options={statusOption}
                            isClearable={true}
                            placeholder={t("Action")}
                            onChange={(option) => {
                              if (option) {
                                formik.setFieldValue(
                                  "outcomeTrackingActionId",
                                  option.value
                                );
                              } else {
                                formik.setFieldValue(
                                  "outcomeTrackingActionId",
                                  ""
                                );
                              }
                            }}
                          />
                          <ErrorMessage
                            name="outcomeTrackingActionId"
                            component={TextError}
                          />
                        </div>
                        <div className="col-lg-6 my-2">
                          <label
                            htmlFor="followUpDate"
                            className="text-uppercase required"
                          >
                            {t("Date")}
                          </label>
                          <div className="">
                            <StandardDatePicker
                              disabled={canUserUpdateField}
                              dateFormat={formatDate()}
                              placeholderText="Start Date"
                              autoComplete="off"
                              name="followUpDate"
                              className="form-control w-100 my-1 "
                              onChange={(selectedDate) => {
                                formik.setFieldValue(
                                  "followUpDate",
                                  selectedDate
                                );
                              }}
                              selected={formik.values.followUpDate}
                            />
                          </div>
                          <ErrorMessage
                            name="followUpDate"
                            component={TextError}
                          />
                        </div>
                      </Grid>
                      <Grid container>
                        <div className="col-lg-12 my-2">
                          <label htmlFor="notes" className="text-uppercase ">
                            {t("Note")}
                          </label>
                          <Field
                            disabled={canUserUpdateField}
                            rows="2"
                            className="mt-1"
                            component="textarea"
                            id="note"
                            name="note"
                          />
                          <ErrorMessage name="note" component={TextError} />
                        </div>
                      </Grid>
                      <Grid container>
                        <div className="col-lg-12 my-2">
                          <div className="mb-2">
                            <h6 className="dark-light-text-color">
                              {t("Outcome Value")}
                            </h6>
                            <hr />
                          </div>
                          <div className="row d-flex">
                            <div className="col-lg-6">
                              <div>
                                <label
                                  htmlFor="Currency"
                                  className="text-uppercase"
                                >
                                  {t("Currency")}
                                </label>
                              </div>
                              <Select
                                name="currency"
                                isDisabled={canUserUpdateField}
                                className="select-style my-1 dropdown-open-top"
                                classNamePrefix="select"
                                styles={selectCurrencyStyles}
                                value={currencyOption.filter(
                                  (option) =>
                                    option.value === formik.values.currency
                                )}
                                options={currencyOption}
                                isClearable={true}
                                // menuIsOpen={true}
                                placeholder={t("Currency")}
                                onChange={(option) => {
                                  if (option) {
                                    formik.setFieldValue(
                                      "currency",
                                      option.value
                                    );
                                    formik.setFieldValue(
                                      "isCurrencySelected",
                                      true
                                    );
                                  } else {
                                    formik.setFieldValue("currency", "");
                                    formik.setFieldValue(
                                      "isCurrencySelected",
                                      false
                                    );
                                    formik.setFieldValue("amount", "");
                                  }
                                }}
                              />
                              <ErrorMessage
                                name="currency"
                                component={TextError}
                              />
                            </div>
                            <div className="col-lg-6">
                              <label
                                htmlFor="Amount"
                                className="text-uppercase"
                              >
                                {t("Amount")}
                              </label>
                              <Field
                                isDisabled={canUserUpdateField}
                                placeholder="Amount"
                                className="form-control w-100 input-amount my-1"
                                type="number"
                                name="amount"
                                disabled={formik.values.currency ? false : true}
                              />
                              <ErrorMessage
                                name="amount"
                                component={TextError}
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid container>
                        <div className="col-lg-12 d-flex justify-content-end align-items-center mt-2">
                          <button
                            type="submit"
                            className="btn btn-primary mr-2 d-flex align-items-center justify-content-center"
                            disabled={
                              canUserUpdateField
                                ? canUserUpdateField
                                : formik.isSubmitting
                            }
                          >
                            {formik.isSubmitting ? (
                              <>
                                {t("Save")}...
                                <div className="custom-loader linesLoader loader_inner_button">
                                  <Spinner color="#ffffff" size={"19px"} />
                                </div>
                              </>
                            ) : (
                              <>{t("Save")}</>
                            )}
                          </button>
                          <button
                            style={{ margin: "0px" }}
                            className="btn btn-secondary save-modal-btn text-uppercase"
                            onClick={closeModal}
                          >
                            {t("Cancel")}
                          </button>
                        </div>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </div>
            {outComeHistory.length > 0 && (
              <Grid container>
                <div className="col-lg-12 mt-2 mt-3 mb-2 mitigate-history-column">
                  <OutComeTrackingHistory
                    response={outComeHistory}
                    getAttributeLabel={getAttributeLabel}
                    languageCode={language}
                  />
                </div>
              </Grid>
            )}
          </>
        ) : (
          <div className="loader">
            <Spinner className="table-loader" />
          </div>
        )}
      </div>
    </ModalPopUp>
  );
};
export default OutcomeTracking;
