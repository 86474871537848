import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { Grid2 as Grid } from "@mui/material";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../../components/Common";
import { createLookUp } from "../../../Services/lookUpService";
import PreventLeaveRoute from "../../Utility/PreventLeaveRoute.js";
import { SelectField } from "../../Utility/selectField";

class NewLookups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMsg: "",
      alertStatus: false,
      variant: "",
      lookUpFieldNameOptionVal: "",
      dirty: false,
      disablesavebtn: false,
    };
  }

  componentDidMount = () => {};

  cancelUserClick = () => {
    this.props.updateNavBar("Lookups");
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  dirtyFlagChanged = (dirty) => {
    if (dirty != this.state.dirty) {
      this.setState({
        dirty: dirty,
      });
      this.props.dataFromParent.handleChildDirtyGFlag(dirty);
    }
  };

  handleAddLookUp = (fields, tenantId, resetForm) => {
    this.setState({
      disablesavebtn: true,
    });
    const param = [
      {
        description: fields.englishValue,
        attributeType: fields.name.toUpperCase(),
        languageId: 1,
        tenantId: tenantId,
        active: fields.status,
        default: false,
        object: fields.object,
      },
      {
        description: fields.SpanishValue,
        attributeType: fields.name.toUpperCase(),
        languageId: 2,
        tenantId: tenantId,
        active: fields.status,
        default: false,
        object: fields.object,
      },
    ];
    createLookUp(param, tenantId)
      .then((res) => {
        this.setState({
          alertMsg: `lookup created successfully`,
          variant: "success",
          alertStatus: true,
          projectDetail: res.data,
          lookUpFieldNameOptionVal: fields.name.toUpperCase(),
          dirty: false,
          disablesavebtn: false,
        });
        this.props.dataFromParent.handleChildDirtyGFlag(false);
        resetForm();
      })
      .catch((err) => {
        let errMsg = Object.assign({}, err).response.data.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          lookUpFieldNameOptionVal: fields.name.toUpperCase(),
          alertStatus: true,
          disablesavebtn: false,
        });
        this.props.dataFromParent.handleChildDirtyGFlag(false);
      });
  };

  render() {
    const { t } = this.props;
    const tenantId = this.props.dataFromParent.statedata.tenantId;

    const lookUpStatusOption = [
      { id: 1, value: true, label: t("Active"), status: true },
      { id: 2, value: false, label: t("Inactive"), status: false },
    ];

    const lookUpFieldNameOption =
      require("../../../config/lookUpFieldNameOption.json").map((el) => {
        return { ...el, label: t(el.label) };
      });

    const lookUpObjectOption = require("../../../config/lookUpObject.json").map(
      (el) => {
        return { ...el, label: t(el.label) };
      }
    );
    let fieldName = lookUpFieldNameOption[0].value;
    if (this.state.lookUpFieldNameOptionVal != "") {
      fieldName = this.state.lookUpFieldNameOptionVal;
    }

    return (
      <Formik
        enableReinitialize
        initialValues={{
          object: "PROJECT",
          name: fieldName,
          status: lookUpStatusOption[0].value,
          englishValue: "",
          SpanishValue: "",
        }}
        onSubmit={(fields, { resetForm, setFieldValue }) => {
          this.handleAddLookUp(fields, tenantId, resetForm);
        }}
        render={({
          errors,
          values,
          touched,
          handleReset,
          dirty,
          handleBlur,
        }) => (
          <Form onChange={this.dirtyFlagChanged(dirty)}>
            <PreventLeaveRoute
              reset={handleReset}
              when={dirty}
              // Navigate function
              navigate={(field, historyObj) => {
                if (field == "/main/admin/lookups") {
                  this.cancelUserClick();
                } else {
                  historyObj.push(field);
                }
              }}
              // Use as "message" prop of Prompt of React-Router
              shouldBlockNavigation={(location) => {
                if (location.pathname === "/") {
                  return false;
                }
                return !!(dirty || this.state.dirty);
              }}
            />
            <Grid container>
              <Grid size={12}>
                <AlertDismissible
                  msg={this.state.alertMsg}
                  variantType={this.state.variant}
                  show={this.state.alertStatus}
                  close={this.handleAlertClose}
                ></AlertDismissible>
              </Grid>
            </Grid>
            <Grid container className="add-tenant-row">
              <Grid className="add-tenant-col" size={4}>
                <div className="form-group">
                  <label className="required" htmlFor="object">
                    {t("OBJECT")}
                  </label>
                  <Field
                    name={"object"}
                    onBlur={handleBlur}
                    component={SelectField}
                    options={lookUpObjectOption}
                    values={values.object}
                  />
                </div>
                <div className="form-group">
                  <label className="required" htmlFor="status">
                    {t("FEILD STATUS")}
                  </label>
                  <Field
                    name={"status"}
                    onBlur={handleBlur}
                    component={SelectField}
                    options={lookUpStatusOption}
                    values={values.status}
                  />
                </div>
              </Grid>
              <Grid className="add-tenant-col" size={4}>
                <div className="form-group">
                  <label className="required" htmlFor="name">
                    {t("FEILD NAME")}
                  </label>
                  <Field
                    name={"name"}
                    onBlur={handleBlur}
                    component={SelectField}
                    options={lookUpFieldNameOption}
                    values={values.name}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container className="add-tenant-row">
              <Grid className="add-tenant-col" size={4}>
                <div className="form-group">
                  <label htmlFor="englishValue">{t("ENGLISH VALUE")}</label>
                  <Field
                    name="englishValue"
                    type="text"
                    autoComplete="off"
                    className={"form-control"}
                  />
                  {errors.englishValue && touched.englishValue && (
                    <div className="input-feedback add-tenant-validation">
                      {errors.englishValue}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid className="add-tenant-col" size={4}>
                <div className="form-group">
                  <label htmlFor="SpanishValue">{t("SPANISH VALUE")}</label>
                  <Field
                    name="SpanishValue"
                    type="text"
                    autoComplete="off"
                    className={"form-control"}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid size={12}>
                <div className="form-group float-right">
                  <Button
                    variant="primary"
                    type="submit"
                    className="rounded-0 mr-4 save-btn button_with_loader"
                    disabled={this.state.disablesavebtn}
                  >
                    {this.state.disablesavebtn ? (
                      <>
                        {t("Save")}...
                        <div className="custom-loader linesLoader loader_inner_button">
                          <Spinner color="#ffffff" size={"19px"} />
                        </div>
                      </>
                    ) : (
                      <span>{t("Save")}</span>
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    className="rounded-0 cancel-btn"
                    onClick={this.cancelUserClick}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  }
}

export default withTranslation()(NewLookups);
