import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, FieldArray, ErrorMessage } from "formik";
import { Grid2 as Grid } from "@mui/material";
import DeleteLanguageModal from "../DeleteLanguageModal";
import TextError from "../MultiLanguageConfiguration/TextError";
import ConfigurationOptions from "./ConfigurationOptions";
import DeleteLanguageRow from "./DeleteLanguageRow";

const AddAdditionalConfigurationFields = (props) => {
  const { t } = useTranslation();
  const { state, formik } = props;
  const { canUserUpdateField } = state;
  const { languageTypeOption } = ConfigurationOptions(state);
  const { values, setFieldValue } = formik;
  const [showDeleteLanguageModal, setShowDeleteLanguageModal] = useState(false);
  const [updatedMultiLanguages, setUpdatedMultiLanguages] = useState([]);
  const [updatedLanguages, setUpdatedLanguages] = useState([]);
  const [deletedLanguageIndex, setDeletedLanguageIndex] = useState([]);
  const [projectLanguagesData, setProjectLanguagesData] = useState([]);
  const [showDeleteLanguageRowModal, setShowDeleteLanguageRowModal] =
    useState(false);

  const getMainFieldOptions = (rows, index) => {
    const selectedOptions = rows.filter((row, rowIndex) => rowIndex !== index);
    const filteredOptions = state.keys?.filter(
      (mainFieldOption) =>
        !selectedOptions.find(
          (selectedOption) => mainFieldOption.value === selectedOption.key.value
        )
    );
    return filteredOptions;
  };

  const handleOnChange = (updatedSelect, selected, index) => {
    if (selected === null) {
      selected = [];
    }
    let deletedLanguage = [];
    if (updatedSelect !== null) {
      deletedLanguage = selected.filter((x) => {
        return !updatedSelect.includes(x);
      });
    } else {
      setFieldValue("projectMultiLanguageConfig", []);
      deletedLanguage = selected;
    }
    if (deletedLanguage.length > 0) {
      let toggle = false;
      const updatedConfig = values.projectMultiLanguageConfig.filter(
        (multiLanguageData) => {
          if (multiLanguageData.language.value === deletedLanguage[0].value) {
            toggle = true;
            return false;
          }
          return true;
        }
      );

      const updatedLanguagesData = values.projectLanguagesData.filter(
        (languagesData) => {
          return languagesData.value !== deletedLanguage[0].value;
        }
      );

      if (toggle) {
        setProjectLanguagesData(updatedLanguagesData);
        setUpdatedMultiLanguages(updatedConfig);
        setUpdatedLanguages(updatedSelect);
        setDeletedLanguageIndex(index);
        setShowDeleteLanguageModal(
          (showDeleteLanguageModal) => !showDeleteLanguageModal
        );
      } else {
        setFieldValue("projectLanguagesData", updatedLanguagesData);
        setFieldValue(
          `clientAPIUrl1.additional_fields.${index}.value`,
          updatedSelect
        );
      }
    } else {
      setFieldValue("projectLanguagesData", updatedSelect);
      setFieldValue(
        `clientAPIUrl1.additional_fields.${index}.value`,
        updatedSelect
      );
    }
  };

  const deleteLanguage = () => {
    setFieldValue("projectMultiLanguageConfig", updatedMultiLanguages);
    setFieldValue("projectLanguagesData", projectLanguagesData);
    setFieldValue(
      `clientAPIUrl1.additional_fields.${deletedLanguageIndex}.value`,
      updatedLanguages
    );
    setShowDeleteLanguageModal(
      (showDeleteLanguageModal) => !showDeleteLanguageModal
    );
  };

  const handleConfigurationKey = (slectedKey, index) => {
    const defaultLanguage = languageTypeOption.filter(
      (option) => option.label === "English"
    );
    if (slectedKey.value === "PROJECT_LANGUAGES") {
      setFieldValue(
        `clientAPIUrl1.additional_fields.${index}.value`,
        defaultLanguage
      );
      setFieldValue("projectLanguagesData", defaultLanguage);
    }
    setFieldValue(`clientAPIUrl1.additional_fields.${index}.key`, slectedKey);
  };

  const handleDeleteLanguageRow = (remove, index) => {
    if (values.projectMultiLanguageConfig.length > 0) {
      setShowDeleteLanguageRowModal(true);
    } else {
      setFieldValue("projectLanguagesData", []);
      remove(index);
    }
  };

  const handleIsDisabled = (row) => {
    return !!(
      row.key.value === "PROJECT_LANGUAGES" ||
      row.key.value === "SMS_COUNTRY_CODE" ||
      row.key.value === "SMS_TEST_PHONE_NUMBER"
    );
  };

  return (
    <Grid size={12}>
      <div className="mb-2 mt-2 additional_configuration_accordion">
        <Accordion allowZeroExpanded="true" allowMultipleExpanded="true">
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="row-header">
                  <div>
                    <label className="row-header-title">
                      {t("Add Additional Configuration Fields")}
                    </label>
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Grid container>
                <Grid size={12}>
                  <FieldArray
                    name="clientAPIUrl1.additional_fields"
                    render={({ push, remove }) => {
                      return (
                        <>
                          <div className="key_value_repeater">
                            {values.clientAPIUrl1.additional_fields.length >
                              0 &&
                              values.clientAPIUrl1.additional_fields.map(
                                (row, index) => {
                                  const mainFieldOptions = getMainFieldOptions(
                                    values.clientAPIUrl1.additional_fields,
                                    index
                                  );

                                  return (
                                    <div className="row" key={index}>
                                      <div className="col form-group inline_label_fields">
                                        <label
                                          className="required"
                                          htmlFor={`clientAPIUrl1.additional_fields.${index}.key`}
                                        >
                                          {t("Key")}
                                        </label>
                                        <CreatableSelect
                                          id={`clientAPIUrl1.additional_fields.${index}.key`}
                                          className="select-style"
                                          classNamePrefix="select"
                                          closeMenuOnSelect
                                          isDisabled={
                                            canUserUpdateField
                                              ? canUserUpdateField
                                              : handleIsDisabled(row)
                                          }
                                          options={mainFieldOptions}
                                          value={row.key}
                                          onChange={(value) =>
                                            handleConfigurationKey(value, index)
                                          }
                                        />
                                        <ErrorMessage
                                          name={`clientAPIUrl1.additional_fields.${index}.key`}
                                          component={TextError}
                                        />
                                      </div>
                                      <div className="col form-group inline_label_fields">
                                        <label
                                          htmlFor={`clientAPIUrl1.additional_fields.${index}.value`}
                                        >
                                          {t("Value")}
                                        </label>
                                        {row.key.value !=
                                        "PROJECT_LANGUAGES" ? (
                                          <>
                                            <Field
                                              disabled={canUserUpdateField}
                                              id={`clientAPIUrl1.additional_fields.${index}.value`}
                                              name={`clientAPIUrl1.additional_fields.${index}.value`}
                                              className={"form-control"}
                                              type="text"
                                              component="textarea"
                                              value={row.value}
                                              onChange={(event) => {
                                                let str = event.target.value;
                                                let newStr = str.replace(
                                                  /\s+/g,
                                                  ""
                                                );
                                                setFieldValue(
                                                  `clientAPIUrl1.additional_fields.${index}.value`,
                                                  newStr
                                                );
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <Select
                                              isDisabled={canUserUpdateField}
                                              id={`clientAPIUrl1.additional_fields.${index}.value`}
                                              isMulti
                                              name={`clientAPIUrl1.additional_fields.${index}.value`}
                                              options={languageTypeOption}
                                              className="basic-multi-select mb-0"
                                              classNamePrefix="select"
                                              value={row.value}
                                              onChange={(value) =>
                                                handleOnChange(
                                                  value,
                                                  row.value,
                                                  index
                                                )
                                              }
                                            />
                                          </>
                                        )}
                                      </div>

                                      <div className="delete_icon_key_value_repeater">
                                        {row.key.value !==
                                        "PROJECT_LANGUAGES" ? (
                                          <button
                                            type="button"
                                            disabled={canUserUpdateField}
                                            className="btn btn-secondary btn-sm remove_repeater"
                                            onClick={() => remove(index)}
                                          >
                                            <FontAwesomeIcon
                                              icon={faTrashAlt}
                                              className="trashicon"
                                            />
                                          </button>
                                        ) : (
                                          <button
                                            disabled={canUserUpdateField}
                                            type="button"
                                            className="btn btn-secondary btn-sm remove_repeater"
                                            onClick={() =>
                                              handleDeleteLanguageRow(
                                                remove,
                                                index
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faTrashAlt}
                                              className="trashicon"
                                            />
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            <button
                              type="button"
                              disabled={canUserUpdateField}
                              className="btn btn-primary add_additional_fields_btn"
                              onClick={() =>
                                push({
                                  key: "",
                                  value: "",
                                })
                              }
                            >
                              {t("Add Additional Fields")}
                            </button>
                          </div>
                        </>
                      );
                    }}
                  />
                </Grid>
                {showDeleteLanguageModal && (
                  <DeleteLanguageModal
                    isOpenModal={showDeleteLanguageModal}
                    t={t}
                    deleteLanguage={deleteLanguage}
                    closeModal={() =>
                      setShowDeleteLanguageModal(
                        (showDeleteLanguageModal) => !showDeleteLanguageModal
                      )
                    }
                  />
                )}
                {showDeleteLanguageRowModal && (
                  <DeleteLanguageRow
                    isOpenModal={showDeleteLanguageRowModal}
                    t={t}
                    closeModal={() =>
                      setShowDeleteLanguageRowModal(
                        (showDeleteLanguageRowModal) =>
                          !showDeleteLanguageRowModal
                      )
                    }
                  />
                )}
              </Grid>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </Grid>
  );
};

export default AddAdditionalConfigurationFields;
