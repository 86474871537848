import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { authActions } from "../../store/actions/authAction";
import PublicOffRoundedIcon from "@mui/icons-material/PublicOffRounded";
import "./authorizationError.css";
import Button from "../Common/Button";
export const AuthorizationError = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const errorMessage = t("AUTHORIZATION_ERROR_CONTENT");
  return (
    <div className="authorization-error-wrapper">
      <div className="error-icon text-center">
        <PublicOffRoundedIcon
          sx={{ fontSize: "81px", color: "rgba(255, 0, 0, 0.62)" }}
        />
      </div>
      <div
        className="text-center error-message-content"
        dangerouslySetInnerHTML={{ __html: errorMessage }}
      ></div>
      <div className="text-center back-login">
        <Button
          onClick={() => {
            dispatch(authActions.logOut("signOutFromBtn", logout));
            setTimeout(() => {
              logout();
            }, 0);
          }}
        >
          {t("BACK_TO_LOGIN")}
        </Button>
      </div>
      <div className="text-center error-message-content-bottom">
        <p>{t("AUTHORIZATION_ERROR_CONTENT_1")}</p>
      </div>
    </div>
  );
};
